var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { IconButton, Typography, makeStyles, DocumentIcon } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { Box } from "@toolkit/ui";
import { CloudDownload as DownloadIcon } from "@mui/icons-material";
import { useFileDownloader } from "@toolkit/core";
const useStyles = makeStyles()(() => ({
    root: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        padding: "8px",
        userSelect: "none",
        width: "100%",
        gap: 4,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        marginHorizontal: 8,
        overflow: "hidden",
        "& .MuiTypography-root": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
}));
export const ChatViewMediaDocument = ({ media }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { url, type } = media;
    const fileName = url.split("/").pop();
    const documentName = useMemo(() => {
        if (type) {
            if (/^image\//.test(type)) {
                return t("Photo");
            }
            else if (/^audio\//.test(type)) {
                return t("Audio");
            }
            else if (/^video\//.test(type)) {
                return t("Video");
            }
        }
        return t("Document");
    }, [type, t]);
    const { download } = useFileDownloader();
    const handleDownload = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield download(url);
    }), [download, type, url]);
    return (_jsxs(Box, { className: classes.root, children: [_jsx(DocumentIcon, { fontSize: 'large' }), _jsxs(Box, { className: classes.content, children: [_jsx(Typography, { variant: 'subtitle1', children: documentName }), _jsx(Typography, { variant: 'caption', color: 'textSecondary', children: fileName })] }), _jsx(Box, { display: 'flex', flexGrow: '1' }), _jsx(IconButton, { onClick: handleDownload, size: 'large', children: _jsx(DownloadIcon, { fontSize: 'medium' }) })] }));
};
