import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, Typography } from "@toolkit/ui";
import { makeStyles } from "@toolkit/ui";
export const useChatListEmptyStyles = makeStyles()(() => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
    },
}));
export const ChatListEmpty = ({ hasError, isLoading, onRetry }) => {
    const { t } = useTranslation();
    const { classes, theme } = useChatListEmptyStyles();
    return (_jsxs(Box, { className: classes.root, children: [isLoading && _jsx(CircularProgress, {}), !isLoading && (_jsxs(_Fragment, { children: [_jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.md, children: hasError ? t("An error occurred, please try again.") : t("No messages yet!") }), hasError && _jsx(Button, { onClick: onRetry, children: t("Retry") })] }))] }));
};
