import { useCallback, useEffect, useRef, useState } from "react";
import { useSizeCallback } from "./useSizeCallback";
import { debounce } from "@toolkit/core";
export function useCanvasDimension(mediaStream, videoRef) {
    const [dimension, setDimension] = useState({ width: 0, height: 0 });
    const debounceRef = useRef(debounce(setDimension, 300));
    const onCanvasResize = useCallback(({ width, height }) => {
        if (videoRef) {
            // eslint-disable-next-line no-useless-call
            debounceRef.current({ width, height });
        }
    }, [videoRef]);
    useSizeCallback(videoRef.current, onCanvasResize);
    useEffect(() => {
        if (videoRef.current) {
            const { width, height } = videoRef.current.getBoundingClientRect();
            setDimension({ width, height });
        }
    }, [videoRef]);
    useEffect(() => {
        const { width, height } = dimension;
        try {
            if (videoRef.current && width > 0 && height > 0) {
                videoRef.current.width = width;
                videoRef.current.height = height;
            }
        }
        catch (e) {
            if (videoRef.current instanceof HTMLCanvasElement) {
                mediaStream === null || mediaStream === void 0 ? void 0 : mediaStream.updateVideoCanvasDimension(videoRef.current, width, height);
            }
        }
    }, [mediaStream, dimension, videoRef]);
    return dimension;
}
