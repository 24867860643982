var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { Badge, Box, Button, CustomDialog, useMediaQuery } from "@toolkit/ui";
import { Fragment, useMemo } from "react";
import { MuiMicIcon, MuiMicOffIcon, MuiVideocamIcon, MuiVideocamOffIcon, MuiCallEndIcon, MuiChatIcon, MuiFullscreenIcon, MuiFullscreenExitIcon, MuiPeopleIcon, MuiScreenShareIcon, MuiMoreHorizIcon, MuiPhotoCameraIcon, } from "@toolkit/ui";
import { useCallMenuHookStyles } from "./MeetingMenu.styles";
import DoctorConsoleIcon from "./DoctorConsoleIcon";
export const MeetingMenu = ({ call, isDoctorConsolEnabled, isDoctorConsoleOpen, isChatOpen, isParticipantsOpen, isMobileMenuOpen, isFullscreen, isFullscreenSupported, isDrawerOpen, isTakePhotoAllowed, onToggleDoctorConsole, onToggleParticipants, onToggletMobileMenu, onToggleChat, onToggleFullscreen, onToggleTakePhoto, onToggleVideo, onToggleAudio, onToggleShare, onDropCall, }) => {
    const { t } = useTranslation();
    const { state } = call;
    const unreadMessagesCount = 0;
    const { videoMuted, screenMuted, audioMuted } = state;
    const { classes, theme } = useCallMenuHookStyles({ isDrawerOpen, isDoctorConsoleOpen });
    const isSm = useMediaQuery(theme.breakpoints.down(700));
    const isMd = useMediaQuery(theme.breakpoints.down(1000));
    const useBottomSheet = isSm || (isMd && isDoctorConsoleOpen && isDrawerOpen);
    const selectedColor = theme.palette.info.main;
    const menu = useMemo(() => ({
        doctorConsole: isDoctorConsolEnabled && (_jsxs(Button, { variant: 'text', onClick: onToggleDoctorConsole, className: classes.menuItem, children: [_jsx(DoctorConsoleIcon, { color: isDoctorConsoleOpen ? selectedColor : "" }), t("DR Console")] })),
        fullscreen: isFullscreenSupported && (_jsxs(Button, { variant: 'text', onClick: onToggleFullscreen, className: classes.menuItem, children: [!isFullscreen ? _jsx(MuiFullscreenIcon, {}) : _jsx(MuiFullscreenExitIcon, {}), t("Fullscreen")] })),
        audio: (_jsxs(Button, { variant: 'text', onClick: onToggleAudio, className: classes.menuItem, children: [!audioMuted ? _jsx(MuiMicIcon, {}) : _jsx(MuiMicOffIcon, {}), !audioMuted ? t("Mute") : t("Unmute")] })),
        video: (_jsxs(Button, { variant: 'text', onClick: onToggleVideo, className: classes.menuItem, children: [!videoMuted ? _jsx(MuiVideocamIcon, {}) : _jsx(MuiVideocamOffIcon, {}), !videoMuted ? t("Stop video") : t("Start video")] })),
        screen: (_jsxs(Button, { variant: 'text', onClick: onToggleShare, className: classes.menuItem, children: [!screenMuted ? _jsx(MuiScreenShareIcon, { sx: { color: "#5eda29" } }) : _jsx(MuiScreenShareIcon, {}), t("Share screen")] })),
        chat: (_jsxs(Button, { variant: 'text', onClick: onToggleChat, className: classes.menuItem, children: [_jsx(Badge, { badgeContent: unreadMessagesCount, color: 'error', children: _jsx(MuiChatIcon, { htmlColor: isChatOpen ? selectedColor : "inherit" }) }), t("Chat")] })),
        takephoto: isTakePhotoAllowed && (_jsxs(Button, { variant: 'text', onClick: onToggleTakePhoto, className: classes.menuItem, children: [_jsx(MuiPhotoCameraIcon, {}), t("Take photo")] })),
        participants: (_jsxs(Button, { variant: 'text', onClick: onToggleParticipants, className: classes.menuItem, children: [_jsx(Badge, { badgeContent: call.participants.length, children: _jsx(MuiPeopleIcon, { htmlColor: isParticipantsOpen ? selectedColor : "inherit" }) }), t("Participants")] })),
        dropcall: (_jsxs(Button, { variant: 'text', onClick: onDropCall, className: classes.menuItem, children: [_jsx(MuiCallEndIcon, { color: 'error' }), t("End")] })),
    }), [
        isDoctorConsolEnabled,
        onToggleDoctorConsole,
        classes.menuItem,
        isDoctorConsoleOpen,
        selectedColor,
        t,
        isFullscreenSupported,
        onToggleFullscreen,
        isFullscreen,
        onToggleAudio,
        audioMuted,
        onToggleVideo,
        videoMuted,
        onToggleShare,
        screenMuted,
        onToggleChat,
        isChatOpen,
        isTakePhotoAllowed,
        onToggleTakePhoto,
        onToggleParticipants,
        call.participants.length,
        isParticipantsOpen,
        onDropCall,
    ]);
    if (useBottomSheet) {
        const { dropcall: dropcallBtn, chat: chatBtn, video: videoBtn, audio: audioBtn } = menu, buttons = __rest(menu, ["dropcall", "chat", "video", "audio"]);
        return (_jsxs(_Fragment, { children: [_jsxs(Box, { className: classes.root, children: [audioBtn, videoBtn, chatBtn, dropcallBtn, _jsxs(Button, { variant: 'text', onClick: onToggletMobileMenu, className: classes.menuItem, children: [_jsx(MuiMoreHorizIcon, {}), t("More")] })] }), _jsx(CustomDialog, { open: isMobileMenuOpen, handleClose: onToggletMobileMenu, type: 'bottomSheet', children: _jsxs(Box, { className: classes.mobileMenu, children: [Object.keys(buttons).map(key => (_jsx(Fragment, { children: buttons[key] }, key))), _jsx(Button, { variant: 'text', onClick: onToggletMobileMenu, className: classes.menuItem, children: t("Cancel") })] }) })] }));
    }
    const { doctorConsole: doctorConsoleBtn, takephoto: takephotoBtn, fullscreen: fullscreenBtn } = menu, buttons = __rest(menu, ["doctorConsole", "takephoto", "fullscreen"]);
    return (_jsxs(Box, { className: classes.root, children: [_jsxs(Box, { className: classes.sideMenu, children: [doctorConsoleBtn, takephotoBtn, fullscreenBtn] }), Object.keys(buttons).map(key => (_jsx(Fragment, { children: buttons[key] }, key)))] }));
};
