import { useTranslation } from "@/i18n/i18n.config";
import { UserGender } from "@/schema/types";
import { getDefaultDoctorPhotoByGender } from "@/utils";
import { Avatar, AvatarProps } from "@toolkit/ui";
import { FC } from "react";
import { useStyle } from "./DoctorAvatar.styles";

interface DoctorAvatarProps extends Omit<AvatarProps, "src"> {
  gender?: UserGender;
  imageUrl?: string;
  doctorName?: string;
  className?: string;
}
export const DoctorAvatar: FC<DoctorAvatarProps> = props => {
  const { t } = useTranslation();
  const { gender, imageUrl, doctorName, className, ...reset } = props;
  const { classes, cx } = useStyle();
  const imgTitle = doctorName ? t("doctor-name", { name: doctorName }) : t("Doctor Photo");

  return (
    <Avatar
      className={cx(classes.root, !imageUrl && classes.default, className)}
      src={getDefaultDoctorPhotoByGender({ photo: imageUrl, gender })}
      alt={imgTitle}
      title={imgTitle}
      {...reset}
    />
  );
};
