import { AppointmentType } from "@/schema/types";
import { createContext, ReactNode, useState } from "react";
import { Outlet } from "react-router-dom";
import { IAppointmentConfirmationFormValues } from "../../forms/AppointmentConfirmation/AppointmentConfirmationFormSchema";
import { IDoctorInfo, IFormattedTimeSlot } from "../../types/types";
import { AppointmentBookingType } from "./AppointmentBookingTypes";

export const AppointmentBookingContext = createContext<AppointmentBookingType | undefined>(undefined);

export const AppointmentBookingProvider = ({ children }: { children: ReactNode }) => {
  const [doctorInfo, setDoctorInfo] = useState<IDoctorInfo | undefined>(undefined);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<IFormattedTimeSlot[]>([]);
  const [guestInfo, setGuestInfo] = useState<Omit<IAppointmentConfirmationFormValues, "showInsuranceSection"> | undefined>(undefined);
  const [visitType, setVisitType] = useState<AppointmentType | undefined>(undefined);
  const [otp, setOtp] = useState<string | undefined>(undefined);

  const onOtpChange = (otp: string | undefined) => {
    setOtp(otp);
  };

  const onDoctorInfoChange = (doctorInfo: IDoctorInfo) => {
    setDoctorInfo(doctorInfo);
  };

  const onTimeSlotSelectChange = (timeSlots: IFormattedTimeSlot[]) => {
    setSelectedTimeSlots(timeSlots);
  };

  const onGuestInfoChange = (guestInfo: Omit<IAppointmentConfirmationFormValues, "showInsuranceSection">) => {
    setGuestInfo(guestInfo);
  };

  const onVisitTypeChange = (visitType: AppointmentType) => {
    setVisitType(visitType);
  };

  return (
    <AppointmentBookingContext.Provider
      value={{
        otp,
        doctorInfo,
        selectedTimeSlots,
        guestInfo,
        visitType,
        onDoctorInfoChange,
        onTimeSlotSelectChange,
        onGuestInfoChange,
        onVisitTypeChange,
        onOtpChange,
      }}
    >
      <Outlet />
      {children}
    </AppointmentBookingContext.Provider>
  );
};
