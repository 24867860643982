import React from "react";
import { useAuth } from "@/providers/auth";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Typography, useTheme } from "@toolkit/ui";
import { RouteProps, useNavigate } from "react-router-dom";

const ProtectedRoute: React.FC<RouteProps> = ({ Component, element }) => {
  const { isAuthenticated, askToLogin } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation("consumer");
  const [isNavigating, setIsNavigating] = React.useState(false);
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  const handleLogin = () => {
    setIsNavigating(true);
    askToLogin();
  };

  return (
    <>
      {isAuthenticated ? (
        <>{Component ? <Component /> : <>{element}</>}</>
      ) : (
        <Box>
          {isNavigating ? (
            <Typography lineHeight={3} fontSize={theme?.mixins.fonts.fontSize.xl} fontWeight={theme?.mixins.fonts.fontWeight.semiBold}>
              {t("Redirecting you to the login page...")}
            </Typography>
          ) : (
            <>
              <Typography
                mt={15}
                mb={5}
                lineHeight={3}
                fontSize={theme?.mixins.fonts.fontSize.xl}
                fontWeight={theme?.mixins.fonts.fontWeight.semiBold}
              >
                {t("Please log in to access this content.")}
              </Typography>
              <Button onClick={handleLogin}>{t("Log In")}</Button>
              <Button variant='outlined' onClick={handleBack}>
                {t("Go Back")}
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ProtectedRoute;
