import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, Typography } from "@toolkit/ui";
import { CallVisitStatusTitle } from "./CallVisitStatusTitle";
import { useStyles } from "./CallVisitStatus.styles";
import { ChatAvatar } from "@health/chat";
import { useVisitRejoinCall } from "./useVisitRejoinCall";
export const CallVisitStatus = ({ call, visit, onClose }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { isCallRejoinable, handleRejoinCall, loading } = useVisitRejoinCall(call, visit);
    return (_jsx(Box, { className: classes.root, children: _jsxs(Box, { className: classes.content, sx: { width: 320 }, children: [_jsx(ChatAvatar, { value: call.title, size: 120 }), _jsxs(Box, { children: [_jsx(Typography, { className: classes.title, children: _jsx(CallVisitStatusTitle, { visit: visit }) }), _jsx(Typography, { children: call.title })] }), _jsxs(Box, { children: [isCallRejoinable && (_jsx(Button, { disabled: loading, onClick: handleRejoinCall, color: 'secondary', sx: { minWidth: 100 }, children: loading ? _jsx(CircularProgress, { size: 24 }) : t("Rejoin") })), onClose && (_jsx(Button, { disabled: loading, onClick: onClose, sx: { minWidth: 100 }, children: t("Close") }))] })] }) }));
};
