import { jsx as _jsx } from "react/jsx-runtime";
import { CallProvider as BaseCallProvider } from "./types";
import { useCallback, useMemo, useState } from "react";
import { CallContext } from "./CallContext";
import { useCall } from "./types";
const Provider = ({ children, accessToken, user: userInfo }) => {
    const [callInfo, setCallInfo] = useState();
    const user = useMemo(() => ({ userId: userInfo === null || userInfo === void 0 ? void 0 : userInfo.user_id, userName: userInfo === null || userInfo === void 0 ? void 0 : userInfo.name }), [userInfo === null || userInfo === void 0 ? void 0 : userInfo.user_id, userInfo === null || userInfo === void 0 ? void 0 : userInfo.name]);
    const call = useCall({ meeting: callInfo, user });
    const select = useCallback((v) => setCallInfo(v), []);
    const value = useMemo(() => ({ callInfo, userInfo, call, select, accessToken }), [call, callInfo, select, accessToken, userInfo]);
    return _jsx(CallContext.Provider, { value: value, children: children });
};
export const CallProvider = props => {
    return (_jsx(BaseCallProvider, { children: _jsx(Provider, Object.assign({}, props)) }));
};
