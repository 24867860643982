import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { isRTL } from "@toolkit/i18n";

type Direction = "up" | "down" | "left" | "right";

interface ExpandMoreIconProps extends SvgIconProps {
  direction?: Direction;
  disableAutoRotate?: boolean;
}

const rotationDegrees: Record<Direction, number> = {
  up: -90,
  down: 90,
  left: 180,
  right: 0,
};

export const ExpandMoreIcon: FC<ExpandMoreIconProps> = ({ direction = "right", disableAutoRotate, ...props }) => {
  const theme = useTheme();
  const isRtl = isRTL();
  const nextDirection = direction === "left" ? "right" : "left";
  const adjustedDirection = isRtl && !disableAutoRotate ? nextDirection : direction;

  const rotation = rotationDegrees[adjustedDirection];

  return (
    <SvgIcon
      width='7'
      height='12.829'
      sx={{ transform: `rotate(${rotation}deg)` }}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 7 12.829'
      {...props}
    >
      <path
        id='icons8-arrow_8_'
        data-name='icons8-arrow (8)'
        d='M9.573,3.99a.583.583,0,0,0-.406,1l5.417,5.417L9.167,15.824a.583.583,0,1,0,.824.824L15.82,10.82a.583.583,0,0,0,0-.824L9.991,4.167A.583.583,0,0,0,9.573,3.99Z'
        transform='translate(-8.99 -3.99)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
