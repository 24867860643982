import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton, MuiArrowBackIcon, Typography } from "@toolkit/ui";
import { useCallStyles } from "./Call.styles";
import { useTranslation } from "@toolkit/i18n";
import { ChatAvatar } from "@health/chat";
export const WaitingScreen = ({ call, onClose }) => {
    const { t } = useTranslation();
    const { classes, theme } = useCallStyles();
    return (_jsx(Box, { className: classes.root, children: _jsxs(Box, { className: classes.container, children: [onClose && (_jsx(Box, { className: classes.header, children: _jsx(IconButton, { color: 'inherit', onClick: onClose, children: _jsx(MuiArrowBackIcon, {}) }) })), _jsxs(Box, { className: classes.body, sx: { textAlign: "center", maxWidth: 320 }, children: [_jsx(Box, { py: 2, children: _jsx(ChatAvatar, { value: call.title, size: 120 }) }), _jsxs(Box, { children: [_jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.md, children: call.title }), _jsx(Typography, { variant: 'subtitle1', color: theme.palette.gray[400], children: !call.callId ? t("Invalid meeting ID.") : t("Please wait for the call to be started.") })] })] })] }) }));
};
