import { makeStyles } from "@toolkit/ui";
export const useRatingScreenStyles = makeStyles()(theme => ({
    root: {
        textAlign: "center",
    },
    header: {
        width: 40,
        height: 40,
        position: "relative",
        ["& svg"]: {
            transform: "rotate(-90deg)",
            color: theme.palette.primary.main,
        },
    },
    counter: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: ".9rem",
    },
    content: {
        padding: "1.5rem",
        boxShadow: theme.shadows[1],
        backgroundColor: "#292929",
        borderRadius: "0.5rem",
    },
    input: {
        "& .MuiRating-icon": {
            width: "2.5rem",
        },
    },
}));
