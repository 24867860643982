import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";

export interface HealthServicesIconProps extends SvgIconProps {
  variant?: "gradient" | "fill";
  disabled?: boolean;
}

export const HealthServicesIconWrapper: FC<PropsWithChildren<HealthServicesIconProps>> = ({ children, ...props }) => {
  const theme = useTheme();
  const variantFill = props.variant === "gradient" ? "url(#health-service-linear-gradient)" : props.fill;
  const disabledFill = props.disabled ? theme.palette.grey[900] : undefined;
  const fill = disabledFill || variantFill || "url(#health-service-linear-gradient)" || theme.palette.primary.main;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='61' height='61.774' viewBox='0 0 62 62.774' sx={{}} {...props}>
      <defs>
        <linearGradient
          xmlns='http://www.w3.org/2000/svg'
          id='health-service-linear-gradient'
          x1='0.917'
          y1='0.061'
          x2='0.121'
          y2='1'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#25287f' />
          <stop offset='1' stopColor='#882558' />
        </linearGradient>
        <clipPath id='clip-path'>
          <rect id='Rectangle_5916' data-name='Rectangle 5916' width='28.009' height='33.226' fill={theme.palette.common.white} />
        </clipPath>
      </defs>
      <g id='Group_14153' data-name='Group 14153' transform='translate(-11 -271)'>
        <g id='Group_10282' data-name='Group 10282' transform='translate(11 271)'>
          <path
            id='Path_12745'
            data-name='Path 12745'
            d='M39.2,61.147a48.412,48.412,0,0,1-21.17-1.2C10.984,57.82,3.706,53.73,1.186,47.67-1.272,41.6,1,33.6,4.2,26.775A54.168,54.168,0,0,1,16.874,9.161C22.375,3.989,29.49-.719,35.89.091,42.262.937,47.918,7.3,52.114,13.59a50.624,50.624,0,0,1,8.3,19.453c1.311,7.014,1.134,14.832-2.721,19.821-3.817,5.016-11.312,7.2-18.49,8.283'
            transform='translate(-0.149 0)'
            fill={fill}
            opacity='0.2'
          />
          <path
            id='Path_12746'
            data-name='Path 12746'
            d='M29.753,61.344A46.81,46.81,0,0,1,11.024,53C5.381,48.659.2,42.465,0,36.121c-.14-6.33,4.661-12.787,9.888-17.859a52.377,52.377,0,0,1,17.5-11.578c6.747-2.79,14.8-4.605,20.3-1.679,5.472,2.95,8.4,10.642,10.04,17.769a48.974,48.974,0,0,1,.832,20.435C57.349,50,54.508,57.008,49.312,60.2c-5.173,3.226-12.7,2.634-19.559,1.147'
            transform='translate(0.898 -0.818)'
            fill={fill}
          />
        </g>
      </g>
      {children}
    </SvgIcon>
  );
};
