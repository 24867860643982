import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { useChatListItemMenuHook } from "./ChatListItemMenu.hooks";
import { Typography, Menu, MenuItem, useTheme } from "@toolkit/ui";
import { useContextMenu } from "@toolkit/core";
export const ChatListItemMenu = ({ anchorRef, chat }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { contextMenu, handleClose } = useContextMenu(anchorRef);
    const { enabled, handleCopy } = useChatListItemMenuHook({ chat, onClose: handleClose });
    if (!enabled || !chat.message) {
        return null;
    }
    return (_jsx(Menu, { open: contextMenu !== null, onClose: handleClose, anchorReference: 'anchorPosition', anchorPosition: contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined, children: _jsx(MenuItem, { onClick: handleCopy, children: _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.sm, children: t("Copy Text") }) }) }));
};
