import { VisitStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";
import { GetVisitQuery } from "../../gql";

type CallPageTitleProps = {
  visit: GetVisitQuery["visit"];
  isLoading?: boolean;
  handleRefundClick?: () => void;
};

const useStyles = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginTop: theme.spacing(5),
  },
  text: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    marginTop: theme.spacing(0),
  },
  connecting: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginTop: theme.spacing(4),
    color: theme.palette.success.main,
  },
  redText: {
    color: theme.palette.error.main,
  },
  underline: {
    textDecoration: "underline",
  },
  refundButton: {
    margin: "0",
    padding: "0",
  },
}));

export const CallPageTitle: FC<CallPageTitleProps> = ({ visit, isLoading, handleRefundClick }) => {
  const { t } = useTranslation("consumer");
  const { classes, cx } = useStyles();
  const doctorName = visit?.doctor?.user?.fullName;

  if (isLoading && !visit?.id) {
    return <Typography className={classes.connecting}>{t("Connecting...")}</Typography>;
  }

  switch (visit?.status) {
    case VisitStatus.Pending:
    case VisitStatus.PaymentPending:
    case VisitStatus.PaymentSucceeded:
      return doctorName ? (
        <Typography className={classes.title}>{t("Calling {{doctorName}}", { doctorName })}</Typography>
      ) : (
        <>
          <Typography className={classes.title}>{t("Connecting")}</Typography>
          <Typography className={classes.text}>{t("Connecting you with an available doctor")}</Typography>
        </>
      );

    case VisitStatus.Canceled:
    case VisitStatus.EndedByOneOfTheParticipants:
    case VisitStatus.CanceledByConsumer:
    case VisitStatus.PaymentFailed:
    case VisitStatus.PaymentTimedOut:
      return (
        <>
          <Typography className={`${classes.title} ${classes.redText}`}>{t("Your call has been cancelled")}</Typography>
          <Typography className={classes.text}>
            {t("Cancellation reason is")}: {visit?.cancellationReason || "-"}
          </Typography>
          {visit.status !== VisitStatus.CanceledByConsumer && (
            <Typography className={classes.text}>{t("You can try to call again or reschedule for a later time")}</Typography>
          )}
        </>
      );

    case VisitStatus.Rejected:
      return (
        <>
          <Typography className={`${classes.title} ${classes.redText}`}>{t("Your call has been cancelled")}</Typography>
          <Typography className={classes.text}>{t("Try to reschedule for a later time")}</Typography>
          <Typography className={classes.text}>
            {t("Alternatively, click ")} <span className={classes.underline}>{t("Call Now")}</span>{" "}
            {t("below to initiate a call with the next available doctor.")}
          </Typography>
        </>
      );
    case VisitStatus.CallRejoinable:
      return (
        <>
          <Typography className={`${classes.title}`}>{t("Your call has been Ended")}</Typography>
        </>
      );
    case VisitStatus.DoctorAnswerTimedOut:
      return (
        <>
          <Typography className={`${classes.title} ${classes.redText}`}>{t("Your call has been cancelled")}</Typography>
          <Typography className={classes.text}>
            {t("We've issued a")}
            <Button variant='text' onClick={handleRefundClick} className={cx(classes.underline, classes.refundButton)}>
              {t("Refund")}
            </Button>
            {t("to your wallet")}
          </Typography>
          <Typography className={classes.text}>{t("You can try to call again or reschedule for a later time")}</Typography>
        </>
      );

    case VisitStatus.CallTimedOut:
      return (
        <>
          <Typography className={`${classes.title} ${classes.redText}`}>{t("Call Timeout")}</Typography>
          <Typography className={classes.text}>{t("The call connection has timed out. You can try to Rejoin the call.")}</Typography>
        </>
      );

    case VisitStatus.Incompleted:
    case VisitStatus.Completed:
      return <Typography className={`${classes.title} ${classes.connecting}`}>{t("Your call is completed")}</Typography>;

    case VisitStatus.Assigned:
      return <Typography className={classes.title}>{t("Waiting for {{doctorName}} to accept the call.", { doctorName })}</Typography>;

    case VisitStatus.Accepted:
    case VisitStatus.ChatInProgress:
      return (
        <Typography className={classes.title}>{t("{{doctorName}} accepted your call, please wait a moment.", { doctorName })}</Typography>
      );

    case VisitStatus.CallInProgress:
      return (
        <>
          <Typography className={classes.title}>{doctorName}</Typography>
          <Typography className={classes.text}>{t("is now available to take your call")}</Typography>
        </>
      );

    default:
      return <Typography className={classes.connecting}>{t("Connecting")}</Typography>;
  }
};
