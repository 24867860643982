import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { i18n } from "@toolkit/i18n";
import { useMemo } from "react";
import { Box, format, Typography } from "@toolkit/ui";
import { useStyles } from "./ChatView.styles";
import { ChatViewMedia } from "./ChatViewMedia";
export const ChatView = ({ isOutgoing, chat }) => {
    var _a, _b;
    const hasText = !!chat.message;
    const hasPhoto = !!((_a = chat.attachments) === null || _a === void 0 ? void 0 : _a.find(a => a.type.startsWith("image/")));
    const time = useMemo(() => format(chat.createdAt, "LT", i18n.language), [chat.createdAt]);
    const { classes } = useStyles({ hasPhoto, hasText, isOutgoing: !!isOutgoing });
    return (_jsxs(Box, { className: classes.root, children: [chat.attachments && ((_b = chat.attachments) === null || _b === void 0 ? void 0 : _b.length) > 0 && (_jsx(Box, { className: classes.media, children: _jsx(ChatViewMedia, { attachments: chat.attachments }) })), chat.message && (_jsx(Box, { className: classes.textContent, children: _jsx(Typography, { children: chat.message }) })), _jsx(Box, { className: classes.footer, children: _jsx(Typography, { className: classes.footerText, children: time }) })] }));
};
export default ChatView;
