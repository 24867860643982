/* eslint-disable no-debugger */
import { CalculatedPaymentStatusForSubscription } from "@/schema/types";
import { Trans, useTranslation } from "@toolkit/i18n";
import { Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useVisitQuery } from "../../gql";
import { getNotificationTextStyle } from "../../utils";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { paymentNotificationStatusMapper } from "./utils";

type VisitRefundNotificationProps = {
  id: string;
  creationDate: string;
  isSeen: boolean;
  status: CalculatedPaymentStatusForSubscription;
  refundAmount?: string;
};

const VisitRefundNotification: FC<VisitRefundNotificationProps> = props => {
  const { id, creationDate, isSeen, status, refundAmount } = props;
  const theme = useTheme();

  const { t } = useTranslation("consumer");

  const { color } = getNotificationTextStyle(isSeen, theme);

  const { data, loading } = useVisitQuery({
    variables: {
      visitId: id,
    },
    skip: !id,
  });

  const visit = data?.visit;
  const Icon = paymentNotificationStatusMapper(theme)[status!]?.icon;
  return (
    <NotificationCard
      title={paymentNotificationStatusMapper(theme)[status!]?.display || status?.replace("_", " ")}
      date={creationDate}
      icon={<Icon />}
      iconColor={paymentNotificationStatusMapper(theme)[status!]?.color}
      isLoading={loading}
      isSeen={isSeen}
    >
      {visit?.doctor && (
        <Typography color={color}>
          <Trans
            t={t}
            i18nKey='Your Visit with: <b>{{doctor}}</b>'
            values={{ doctor: visit?.doctor?.user?.fullName || "" }}
            components={{
              b: <Typography display='inline' fontWeight={theme.mixins.fonts.fontWeight.semiBold} />,
            }}
          />
        </Typography>
      )}
      {refundAmount && (
        <Typography color={color}>
          <Trans
            t={t}
            i18nKey='<b>Refund Amount</b>: {{refundAmount}}'
            values={{ refundAmount }}
            components={{
              b: <Typography display='inline' fontWeight={theme.mixins.fonts.fontWeight.semiBold} />,
            }}
          />
        </Typography>
      )}
    </NotificationCard>
  );
};

export default VisitRefundNotification;
