var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { VideoQuality } from "./types";
import { useCanvasDimension } from "./useCanvasDimension";
import { useZoom } from "./useZoom";
import { makeStyles } from "@toolkit/ui";
import { useUser } from "./useUser";
const useStyles = makeStyles()({
    root: {
        flex: 1,
        width: "100%",
        height: "100%",
    },
});
export const ZoomViewVideo = ({ participant }) => {
    const { classes } = useStyles();
    const { mediaStream, mediaState } = useZoom();
    const videoRef = useRef(null);
    const { width, height } = useCanvasDimension(mediaStream, videoRef);
    const { isCurrentUser } = participant;
    const { userId, bVideoOn } = useUser(participant);
    const isVideoDecodeReady = mediaState === null || mediaState === void 0 ? void 0 : mediaState.video.decode;
    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
        if (!isVideoDecodeReady || !userId || !mediaStream || !videoRef.current || !bVideoOn || !width || !height) {
            return;
        }
        let isRendered = false;
        let timeoutId = null;
        const renderVideo = (canvasElement) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield mediaStream.renderVideo(canvasElement, userId, width, height, 0, 0, VideoQuality.Video_720P);
                isRendered = true;
                timeoutId = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                    if (!isRendered) {
                        return;
                    }
                    try {
                        yield mediaStream.adjustRenderedVideoPosition(canvasElement, userId, width, height, 0, 0);
                    }
                    catch (error) {
                        console.error("Meeting: Unable to adjust rendered video position due to error", error);
                    }
                }), 0);
            }
            catch (error) {
                console.error("Meeting: Unable to render video due to error", error);
            }
        });
        const stopRenderVideo = (videoElement) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (!isRendered) {
                    return;
                }
                yield mediaStream.stopRenderVideo(videoElement, userId);
            }
            catch (error) {
                if (error.type === "IMPROPER_MEETING_STATE") {
                    return;
                }
                console.error("Meeting: Unable to stop render video due to error", error);
            }
        });
        const videoElement = videoRef.current;
        renderVideo(videoElement);
        return () => {
            timeoutId && clearTimeout(timeoutId);
            stopRenderVideo(videoElement);
        };
    }, [mediaStream, bVideoOn, userId, width, height, isVideoDecodeReady]);
    if (isCurrentUser && (mediaStream === null || mediaStream === void 0 ? void 0 : mediaStream.isRenderSelfViewWithVideoElement())) {
        // eslint-disable-next-line jsx-a11y/media-has-caption
        return _jsx("video", { className: classes.root, ref: videoRef });
    }
    return _jsx("canvas", { className: classes.root, ref: videoRef });
};
