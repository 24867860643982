import { makeStyles } from "@toolkit/ui";
export const useChatAvatarStyles = makeStyles()(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
    },
    text: {
        color: theme.palette.common.white,
    },
    image: {
        position: "absolute",
    },
}));
