import { useSiteSettingsGetQuery } from "@/gql";
import { useTranslation } from "@/i18n/i18n.config";
import { useHeaderContext } from "@/providers";
import { OperationType, PaymentOperationLog } from "@/schema/types";
import { formatCurrency } from "@/utils";
import { formatGraphQLError } from "@toolkit/apollo";
import { formatDate, formatTime, useAddToast } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useRefundPaymentFromWalletMutation, useWalletQuery } from "../../gql";
import { getTransactionDetails } from "../../utils/transaction-details.utils";

export const useTransactionDetails = (transactionId, theme) => {
  const { t, i18n } = useTranslation();
  const [isRefundSuccess, setIsRefundSuccess] = useState(false);

  const { failed, succeeded } = useAddToast();
  const local = i18n.language;
  const { setHeaderTitle } = useHeaderContext();
  const { data, loading: isTranscriptionLoading } = useWalletQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 10,
      filter: {
        ids: [transactionId!],
      },
    },
    skip: !transactionId,
  });
  const { data: siteSettingsData } = useSiteSettingsGetQuery();

  const paymentOperationLog = data?.me?.patient?.paymentOperationLogs?.edges?.[0]?.node as PaymentOperationLog;
  const { operationType, icon } = getTransactionDetails(
    paymentOperationLog?.operationType as OperationType,
    paymentOperationLog?.paymentAmount || 0,
    theme
  );
  const [refundPaymentFromWalletMutation, { loading }] = useRefundPaymentFromWalletMutation({
    onCompleted: ({ refundPaymentFromWallet }) => {
      if (refundPaymentFromWallet) {
        succeeded(t("Refund to the credit card was successful"));
        setIsRefundSuccess(true);
      } else {
        failed("Failed to refund to the credit card");
      }
    },
    onError: ({ graphQLErrors }) => {
      const formatted = formatGraphQLError(graphQLErrors);
      failed(t(formatted));
    },
  });

  useEffect(() => {
    setHeaderTitle(operationType);
    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, operationType]);
  const date = formatDate(paymentOperationLog?.createdDate, local);
  const time = formatTime(paymentOperationLog?.createdDate);
  const isAddToWallet = paymentOperationLog?.operationType === OperationType.AddToWallet;
  const formattedCurrency = formatCurrency(paymentOperationLog?.paymentAmount ?? 0, siteSettingsData?.siteSettings?.defaultCurrency || "");

  const transactionAmountText = isAddToWallet ? `+${formattedCurrency}` : `-${formattedCurrency}`;
  const onRefundToCreditCardPress = () => {
    refundPaymentFromWalletMutation({
      variables: {
        input: {
          amount: Number(paymentOperationLog?.paymentAmountCanBeRefundedFromWallet),
          paymentOperationLogId: String(paymentOperationLog?.id),
        },
      },
    });
  };
  return {
    isRefundSuccess,
    onRefundToCreditCardPress,
    transactionAmountText,
    date,
    time,
    icon,
    operationType,
    loading,
    paymentOperationLog,
    isTranscriptionLoading,
  };
};
