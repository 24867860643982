var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, makeStyles, MuiDeleteIcon, MuiMicIcon, Typography } from "@toolkit/ui";
import { useCallback, useEffect } from "react";
import { Box } from "@toolkit/ui";
import { useChatPlayer } from "../ChatProvider";
import { useAudioRecorder } from "./useAudioRecorder";
import { Send as SendIcon } from "@mui/icons-material";
const useStyles = makeStyles()(theme => ({
    root: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1,
        backgroundColor: theme.palette.stale[100],
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 10,
    },
}));
export const ChatInputRecordAudio = ({ id, onSubmit, draft, children }) => {
    const { recordEnabled, recordDurationText, isRecording, cancelRecording: handleCancel, startRecording: handleStart, stopRecording, } = useAudioRecorder();
    const { classes } = useStyles();
    useEffect(() => {
        handleCancel();
    }, [handleCancel, id]);
    const handleStop = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const record = yield stopRecording();
        if (record) {
            const { type } = record;
            const ext = type.split("/").pop();
            const file = new File([record], `audio-record.${ext}`, { type });
            onSubmit({ file, type });
        }
    }), [onSubmit, stopRecording]);
    const { pause } = useChatPlayer();
    useEffect(() => {
        isRecording && pause();
    }, [pause, isRecording]);
    const enabled = (!draft || (!draft.message && !draft.attachments)) && recordEnabled;
    if (!enabled) {
        return children;
    }
    if (isRecording) {
        return (_jsxs(Box, { className: classes.root, children: [_jsx(IconButton, { size: "large", onClick: handleCancel, children: _jsx(MuiDeleteIcon, {}) }), _jsx(Typography, { children: recordDurationText }), _jsx(IconButton, { size: "large", onClick: handleStop, children: _jsx(SendIcon, {}) })] }));
    }
    return (_jsx(IconButton, { size: "large", onClick: handleStart, children: _jsx(MuiMicIcon, {}) }));
};
