import { makeStyles } from "@toolkit/ui";
export const useJoinScreenStyles = makeStyles()(theme => ({
    root: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        gap: 20,
        width: 260,
    },
    mediaView: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderRadius: 8,
        height: 250,
        width: "100%",
        backgroundColor: "#262626",
        border: `1px solid #686868`,
        overflow: "hidden",
    },
    mediaControls: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        gap: 2,
    },
    mediaButton: {
        color: theme.palette.common.white,
        flexDirection: "column",
        fontSize: theme.mixins.fonts.fontSize.xs,
        gap: 0.5,
        width: 120,
    },
    joinButton: {
        minWidth: 180,
        backgroundColor: "#212121 !important",
        border: "1px solid #686868",
        fontWeight: "bold",
        borderRadius: 16,
        "&:hover, &.Mui-disabled": {
            opacity: 0.9,
        },
    },
}));
