var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Box, IconButton, CircularProgress, TextField, InputAdornment } from "@toolkit/ui";
import { useCallback, useRef } from "react";
import { ChatDraft } from "../ChatDraft";
import { useChatInput } from "./ChatInput.hooks";
import { ChatInputAttachFile } from "./ChatInputAttachFile";
import { ChatInputRecordAudio } from "./ChatInputRecordAudio";
import { Send as SendIcon } from "@mui/icons-material";
import { useStyles } from "./ChatInput.styles";
export const ChatInput = ({ id }) => {
    const { t } = useTranslation();
    const { enabled, draft, loading, submitDraft, submitAttachment: handleSubmitAttachment, updateDraftText: onChangeText, clearDraft: handleClear, } = useChatInput(id);
    const { classes } = useStyles();
    const inputRef = useRef(null);
    const handleChangeText = useCallback((e) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const text = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value;
        if ((draft === null || draft === void 0 ? void 0 : draft.message) || text.trim()) {
            onChangeText(text);
        }
    }), [draft === null || draft === void 0 ? void 0 : draft.message, onChangeText]);
    const handleSubmit = useCallback((value) => {
        submitDraft(value);
    }, [submitDraft]);
    const handleSubmitEditing = useCallback(() => handleSubmit(), [handleSubmit]);
    if (!enabled) {
        return null;
    }
    return (_jsxs(Box, { classes: classes.root, children: [_jsx(ChatDraft, { draft: draft, onClose: handleClear }), _jsx(Box, { className: classes.input, children: _jsx(TextField, { inputRef: inputRef, placeholder: t("Type your message..."), value: (draft === null || draft === void 0 ? void 0 : draft.message) || "", onChange: handleChangeText, disabled: loading, className: classes.textField, multiline: true, maxRows: 3, InputProps: {
                        startAdornment: (_jsx(InputAdornment, { position: 'start', children: _jsx(ChatInputAttachFile, { onSubmit: handleSubmitAttachment }) })),
                        endAdornment: (_jsx(InputAdornment, { position: 'end', children: _jsx(ChatInputRecordAudio, { id: id, draft: draft, onSubmit: handleSubmitAttachment, children: _jsx(IconButton, { disabled: loading, onClick: handleSubmitEditing, children: !loading ? _jsx(SendIcon, {}) : _jsx(CircularProgress, { size: 30 }) }) }) })),
                    } }) })] }));
};
