import { useEffect, useMemo, useState } from "react";
import { ConnectionState } from "./types";
export function useUsers({ client, connectionState }) {
    const [users, setUsers] = useState({});
    useEffect(() => {
        if (connectionState !== ConnectionState.Connected) {
            return;
        }
        const onUserUpdate = () => {
            const currentUserId = client.getSessionInfo().userId;
            const allUsers = client
                .getAllUser()
                .filter(u => !u.isInFailover)
                .map(user => {
                if (currentUserId === user.userId) {
                    return Object.assign(Object.assign({}, user), { isCurrentUser: true });
                }
                return user;
            });
            setUsers(allUsers.reduce((list, u) => (Object.assign(Object.assign({}, list), { [u.userId]: u })), {}));
        };
        onUserUpdate();
        client.on("user-added", onUserUpdate);
        client.on("user-removed", onUserUpdate);
        client.on("user-updated", onUserUpdate);
        return () => {
            client.off("user-added", onUserUpdate);
            client.off("user-removed", onUserUpdate);
            client.off("user-updated", onUserUpdate);
            setUsers({});
        };
    }, [client, connectionState]);
    const currentUser = useMemo(() => {
        const userId = Object.keys(users).find(u => users[u].isCurrentUser);
        return userId ? users[userId] : undefined;
    }, [users]);
    return { users, currentUser };
}
