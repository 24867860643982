import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerAppointmentRescheduleMutationVariables = Types.Exact<{
  consumerAppointmentRescheduleId: Types.Scalars['ID']['input'];
  input: Types.ConsumerAppointmentRescheduleInput;
}>;


export type ConsumerAppointmentRescheduleMutation = { __typename?: 'Mutation', consumerAppointmentReschedule?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string, status?: Types.AppointmentStatus | null, type?: Types.AppointmentType | null, created?: any | null } | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const ConsumerAppointmentRescheduleDocument = gql`
    mutation ConsumerAppointmentReschedule($consumerAppointmentRescheduleId: ID!, $input: ConsumerAppointmentRescheduleInput!) {
  consumerAppointmentReschedule(
    id: $consumerAppointmentRescheduleId
    input: $input
  ) {
    appointment {
      id
      status
      type
      created
    }
    appointmentErrors {
      code
      field
      message
    }
  }
}
    `;
export type ConsumerAppointmentRescheduleMutationFn = Apollo.MutationFunction<ConsumerAppointmentRescheduleMutation, ConsumerAppointmentRescheduleMutationVariables>;

/**
 * __useConsumerAppointmentRescheduleMutation__
 *
 * To run a mutation, you first call `useConsumerAppointmentRescheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerAppointmentRescheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerAppointmentRescheduleMutation, { data, loading, error }] = useConsumerAppointmentRescheduleMutation({
 *   variables: {
 *      consumerAppointmentRescheduleId: // value for 'consumerAppointmentRescheduleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumerAppointmentRescheduleMutation(baseOptions?: Apollo.MutationHookOptions<ConsumerAppointmentRescheduleMutation, ConsumerAppointmentRescheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumerAppointmentRescheduleMutation, ConsumerAppointmentRescheduleMutationVariables>(ConsumerAppointmentRescheduleDocument, options);
      }
export type ConsumerAppointmentRescheduleMutationHookResult = ReturnType<typeof useConsumerAppointmentRescheduleMutation>;
export type ConsumerAppointmentRescheduleMutationResult = Apollo.MutationResult<ConsumerAppointmentRescheduleMutation>;
export type ConsumerAppointmentRescheduleMutationOptions = Apollo.BaseMutationOptions<ConsumerAppointmentRescheduleMutation, ConsumerAppointmentRescheduleMutationVariables>;