import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeComingSoonAppointmentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeComingSoonAppointmentQuery = { __typename?: 'Query', appointments?: { __typename?: 'AppointmentCountableConnection', edges: Array<{ __typename?: 'AppointmentCountableEdge', node: { __typename?: 'Appointment', id: string, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, careInitialType?: Types.CareType | null, confirmedStartTime?: any | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', fullName?: string | null } | null } | null, vendor?: { __typename?: 'Vendor', name: string } | null } }> } | null };


export const MeComingSoonAppointmentDocument = gql`
    query meComingSoonAppointment {
  appointments(first: 1, filter: {upcoming: true}) {
    edges {
      node {
        id
        type
        status
        doctor {
          id
          user {
            fullName
          }
        }
        vendor {
          name
        }
        careInitialType
        confirmedStartTime
      }
    }
  }
}
    `;

/**
 * __useMeComingSoonAppointmentQuery__
 *
 * To run a query within a React component, call `useMeComingSoonAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeComingSoonAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeComingSoonAppointmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeComingSoonAppointmentQuery(baseOptions?: Apollo.QueryHookOptions<MeComingSoonAppointmentQuery, MeComingSoonAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeComingSoonAppointmentQuery, MeComingSoonAppointmentQueryVariables>(MeComingSoonAppointmentDocument, options);
      }
export function useMeComingSoonAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeComingSoonAppointmentQuery, MeComingSoonAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeComingSoonAppointmentQuery, MeComingSoonAppointmentQueryVariables>(MeComingSoonAppointmentDocument, options);
        }
export function useMeComingSoonAppointmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeComingSoonAppointmentQuery, MeComingSoonAppointmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeComingSoonAppointmentQuery, MeComingSoonAppointmentQueryVariables>(MeComingSoonAppointmentDocument, options);
        }
export type MeComingSoonAppointmentQueryHookResult = ReturnType<typeof useMeComingSoonAppointmentQuery>;
export type MeComingSoonAppointmentLazyQueryHookResult = ReturnType<typeof useMeComingSoonAppointmentLazyQuery>;
export type MeComingSoonAppointmentSuspenseQueryHookResult = ReturnType<typeof useMeComingSoonAppointmentSuspenseQuery>;
export type MeComingSoonAppointmentQueryResult = Apollo.QueryResult<MeComingSoonAppointmentQuery, MeComingSoonAppointmentQueryVariables>;