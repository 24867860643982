import { useEffect, useState } from "react";
import { ConnectionState } from "./types";
export function useActiveShare({ client, mediaStream, connectionState, }) {
    const [activeShare, setActiveShare] = useState({});
    useEffect(() => {
        if (!mediaStream || connectionState !== ConnectionState.Connected) {
            return;
        }
        const onActiveShareChange = (payload) => {
            setActiveShare(payload);
        };
        const onShareContentChange = ({ userId }) => {
            setActiveShare(p => (Object.assign(Object.assign({}, p), { userId })));
        };
        const activeShareUserId = mediaStream.getActiveShareUserId();
        if (activeShareUserId) {
            onActiveShareChange({ state: "Active", userId: activeShareUserId });
        }
        client.on("active-share-change", onActiveShareChange);
        client.on("share-content-change", onShareContentChange);
        return () => {
            client.off("active-share-change", onActiveShareChange);
            client.off("share-content-change", onShareContentChange);
        };
    }, [client, mediaStream, connectionState]);
    return activeShare;
}
