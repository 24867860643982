import { jsx as _jsx } from "react/jsx-runtime";
import { useDraggable } from "@dnd-kit/core";
import { Box } from "@toolkit/ui";
export const MeetingSelfViewDraggable = ({ id, top, left, children }) => {
    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({ id });
    const style = transform
        ? {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        }
        : undefined;
    return (_jsx(Box, { ref: setNodeRef, sx: Object.assign(Object.assign(Object.assign({}, style), { position: "absolute", top,
            left, zIndex: 10, cursor: "grab" }), (isDragging && {
            pointerEvents: "none",
            cursor: "grabbing",
        })), children: _jsx(Box, Object.assign({}, listeners, attributes, { sx: Object.assign({ cursor: "grab" }, (isDragging && {
                pointerEvents: "none",
                cursor: "grabbing",
            })), children: children })) }));
};
