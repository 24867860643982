import { EmptyDoctorsIcon, EmptyMessageLayout } from "@/components";
import { routes as doctorRoutes } from "@/domains/doctor";
import { useTranslation } from "@/i18n/i18n.config";
import { AppointmentType } from "@/schema/types";
import { Box, CustomInfiniteScroll, Typography } from "@toolkit/ui";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DoctorsListFiltersForm } from "../../../doctor/components/DoctorsListFilters/DoctorsListFiltersForm";
import { AppointmentDoctorCard } from "../../components/AppointmentDoctorCard/AppointmentDoctorCard";
import AppointmentDoctorCardSkeleton from "../../components/AppointmentDoctorCard/DoctorCardSkeleton";
import { useAppointmentBookingContext } from "../../context/AppointmentBookingContext/useAppointmentBookingContext";
import { routes } from "../../routes";
import { IDoctorInfo } from "../../types/types";
import { getDoctorInfo } from "../../utils";
import { useStyles } from "./AppointmentListContainer.styles";
import { useGetAvailableDoctorsList } from "./useGetAvailableDoctorsList";

export const AppointmentsListContainer: FC = () => {
  const { doctors, fetchMore, loading, pageInfo, onFilterValuesChange, refetchDoctors } = useGetAvailableDoctorsList();
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

  const navigate = useNavigate();
  const { onDoctorInfoChange, onVisitTypeChange } = useAppointmentBookingContext();
  const handleOnActionCLick = useCallback((doctorInfo: IDoctorInfo) => {
    return (visitType: AppointmentType) => {
      onVisitTypeChange(visitType);
      onDoctorInfoChange(doctorInfo);
      navigate(routes.schedule.getRouteWithParams({ visitType, doctorId: doctorInfo?.doctorId || "" }));
    };
  }, []);
  const handleDoctorCardClick = useCallback((doctorId?: string) => {
    return () => {
      navigate(doctorRoutes.profile.getRouteWithParams({ doctorId: doctorId || "" }));
    };
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver(entries => {
        setContainerHeight(entries[0].contentRect.height);
      });

      observer.observe(containerRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Box className={classes.container}>
      <Typography variant='h6' className={classes.title}>
        {t("Book an Appointment")}
      </Typography>
      <Box className={classes.filterContainer}>
        <DoctorsListFiltersForm
          excludeFilters={{
            callPrice: true,
          }}
          onSubmit={onFilterValuesChange}
        />
      </Box>
      <Box ref={containerRef} className={cx(classes.content, !loading && !doctors?.length && classes.contentForEmptyPage)}>
        {loading && !doctors?.length ? (
          Array.from({ length: 3 }).map((_, index) => <AppointmentDoctorCardSkeleton key={index} classes={{ card: classes.listCard }} />)
        ) : (
          <>
            {!doctors?.length ? (
              <Box className={classes.emptyPageContainer}>
                <EmptyMessageLayout
                  icon={<EmptyDoctorsIcon />}
                  message={t("No doctors found")}
                  subMessage={t("Please try adjusting your search/filter to find what you are looking for.")}
                />
              </Box>
            ) : (
              <CustomInfiniteScroll
                onRefresh={() => {
                  refetchDoctors();
                }}
                height={containerHeight ? containerHeight - 10 : undefined}
                dataLength={Number(doctors?.length) || 0}
                onFetchMore={fetchMore}
                hasMore={Boolean(pageInfo?.hasNextPage)}
                loader={<AppointmentDoctorCardSkeleton classes={{ card: classes.listCard }} />}
              >
                {doctors?.map(doctor => (
                  <Box key={doctor?.id} marginInline={"10px"}>
                    <AppointmentDoctorCard
                      {...getDoctorInfo(doctor)}
                      isVirtualVisitDisabled={!doctor?.appointmentTypes?.some(val => val! === "ONLINE")}
                      isAtHomeDisabled={!doctor?.appointmentTypes?.some(val => val! === "AT_HOME")}
                      isOnSiteDisabled={!doctor?.appointmentTypes?.some(val => val! === "ONSITE")}
                      hasActions={true}
                      handleOnActionCLick={handleOnActionCLick(getDoctorInfo(doctor))}
                      onClick={handleDoctorCardClick(doctor?.id)}
                      classes={{
                        card: classes.card,
                      }}
                    />
                  </Box>
                ))}
              </CustomInfiniteScroll>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
