import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ChatViewMediaAudio } from "./ChatViewMediaAudio";
import { ChatViewMediaDocument } from "./ChatViewMediaDocument";
import { ChatViewMediaPhoto } from "./ChatViewMediaPhoto";
import { ChatViewMediaVideo } from "./ChatViewMediaVideo";
export const ChatViewMedia = ({ attachments }) => {
    return (_jsx(_Fragment, { children: attachments.map(attachment => {
            const { type, url: key } = attachment;
            if (type.startsWith("audio/")) {
                return _jsx(ChatViewMediaAudio, { media: attachment }, key);
            }
            if (type.startsWith("video/")) {
                return _jsx(ChatViewMediaVideo, { media: attachment }, key);
            }
            if (type.startsWith("image/")) {
                return _jsx(ChatViewMediaPhoto, { media: attachment }, key);
            }
            return _jsx(ChatViewMediaDocument, { media: attachment }, key);
        }) }));
};
