import { AppointmentSortingField, AppointmentStatus, H_OrderDirection } from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import moment from "moment";
import { AppointmentHistoryTabsEnum } from "../../types/types";

export const appointmentsHistoryTabs = [
  {
    label: i18n.t("Requested", { ns: "consumer" }),
    routeParameterValue: AppointmentHistoryTabsEnum.requested,
    filter: {
      status: [
        AppointmentStatus.Request,
        AppointmentStatus.RescheduledByConsumer,
        AppointmentStatus.RescheduledByDoctor,
        AppointmentStatus.PendingApproval,
      ],
    },
    sortBy: {
      direction: H_OrderDirection.Desc,
      field: AppointmentSortingField.Created,
    },
    message: i18n.t("There are no requested appointments at the moment.", { ns: "consumer" }),
    subMessage: i18n.t("You can check back later or schedule a new appointment.", { ns: "consumer" }),
  },
  {
    label: i18n.t("Upcoming", { ns: "consumer" }),
    routeParameterValue: AppointmentHistoryTabsEnum.upcoming,
    filter: {
      upcoming: true,
      confirmedStartTime: {
        gte: moment().format("YYYY-MM-DD"),
      },
      status: [AppointmentStatus.Approved, AppointmentStatus.PaymentSucceeded, AppointmentStatus.ComingSoon],
    },
    sortBy: {
      direction: H_OrderDirection.Desc,
      field: AppointmentSortingField.Created,
    },
    message: i18n.t("There are no upcoming appointments right now.", { ns: "consumer" }),
    subMessage: i18n.t("No upcoming appointments found. Feel free to schedule one!", { ns: "consumer" }),
  },
  {
    label: i18n.t("Completed", { ns: "consumer" }),
    routeParameterValue: AppointmentHistoryTabsEnum.completed,
    filter: {
      status: [AppointmentStatus.FinishedSuccessfully],
    },
    sortBy: {
      direction: H_OrderDirection.Desc,
      field: AppointmentSortingField.LastModifiedDate,
    },
    message: i18n.t("You have no completed appointments.", { ns: "consumer" }),
    subMessage: i18n.t("Once you have appointments, they will appear here.", { ns: "consumer" }),
  },
] as const;
