import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from "react";
import { ListItemButton, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, MuiMicIcon, MuiMicOffIcon, MuiScreenShareIcon, MuiVideocamIcon, MuiVideocamOffIcon, ListItem, } from "@toolkit/ui";
import { useParticipantListItemStyles } from "./ParticipantListItem.styles";
import { ChatAvatar } from "@health/chat";
import { useTranslation } from "@toolkit/i18n";
export const ParticipantListItem = memo(({ participant, color, onClick, hideActions }) => {
    const { t } = useTranslation();
    const { classes } = useParticipantListItemStyles({ color });
    const handleOnClick = useCallback(() => {
        onClick === null || onClick === void 0 ? void 0 : onClick(participant);
    }, [onClick, participant]);
    const Item = onClick ? ListItemButton : ListItem;
    return (_jsxs(Item, { onClick: handleOnClick, className: classes.root, children: [_jsx(ListItemAvatar, { children: _jsx(ChatAvatar, { value: participant.info, size: 36 }) }), _jsx(ListItemText, { children: _jsx(Typography, { className: classes.title, children: participant.isCurrentUser ? t("You") : participant.displayName }) }), !hideActions && (_jsxs(ListItemSecondaryAction, { className: classes.action, children: [!participant.state.audioMuted ? _jsx(MuiMicIcon, { className: classes.icon }) : _jsx(MuiMicOffIcon, { className: classes.icon }), !participant.state.videoMuted ? _jsx(MuiVideocamIcon, { className: classes.icon }) : _jsx(MuiVideocamOffIcon, { className: classes.icon }), !participant.state.screenMuted && _jsx(MuiScreenShareIcon, { className: classes.icon })] }))] }, participant.id));
});
