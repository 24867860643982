var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from "react";
export default function useFullscreen() {
    const [isFullscreen, setFullscreen] = useState(false);
    const onToggleFullscreen = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            if (isFullscreen) {
                yield document.exitFullscreen();
            }
            else {
                yield ((_a = document.body) === null || _a === void 0 ? void 0 : _a.requestFullscreen());
            }
        }
        catch (e) {
            console.error(e);
        }
    }), [isFullscreen]);
    useEffect(() => {
        const onFullscreenchange = () => {
            setFullscreen(!!document.fullscreenElement);
        };
        document.addEventListener("fullscreenchange", onFullscreenchange);
        return () => {
            document.removeEventListener("fullscreenchange", onFullscreenchange);
        };
    }, [isFullscreen, setFullscreen]);
    return {
        isFullscreen,
        isFullscreenSupported: document.fullscreenEnabled,
        onToggleFullscreen,
    };
}
