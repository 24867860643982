import { makeStyles } from "@toolkit/ui";
export const useParticipantListItemStyles = makeStyles()((theme, { color }) => ({
    root: {
        height: "unset",
    },
    title: {
        color: color !== null && color !== void 0 ? color : theme.palette.common.white,
    },
    action: {
        gap: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: {
        color: color !== null && color !== void 0 ? color : theme.palette.common.white,
        fontSize: theme.mixins.fonts.fontSize.xl,
    },
}));
