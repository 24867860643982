import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { NotificationFragmentFragmentDoc } from './notificationItem';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.UserNotificationFilterInput>;
  sortBy?: Types.InputMaybe<Types.UserNotificationSortingInput>;
}>;


export type NotificationsQuery = { __typename?: 'Query', me?: { __typename?: 'User', unReadMessagesCount?: number | null, notifications?: { __typename?: 'UserNotificationConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'UserNotificationEdge', node?: { __typename?: 'UserNotification', id?: string | null, seen?: boolean | null, type?: Types.NotificationType | null, data?: string | null, updateDate?: any | null, creationDate?: any | null, medicalMessage?: { __typename?: 'MedicalMessage', headerImage?: string | null, messages?: Array<{ __typename?: 'MedicalMessageVariant', id: string, presentedTitleArabic?: string | null, presentedTitleEnglish?: string | null, bodyArabic?: string | null, bodyEnglish?: string | null, buttonTextArabic?: string | null, buttonTextEnglish?: string | null, contentURLArabic?: string | null, contentURLEnglish?: string | null, extraData?: string | null } | null> | null } | null, errors?: Array<{ __typename?: 'SubscriptionGraphqlError', code?: Types.SubscriptionErrorCodes | null, field?: string | null, message?: string | null } | null> | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } | null } | null } | null };


export const NotificationsDocument = gql`
    query Notifications($first: Int, $after: String, $filter: UserNotificationFilterInput, $sortBy: UserNotificationSortingInput) {
  me {
    unReadMessagesCount
    notifications(first: $first, after: $after, filter: $filter, sortBy: $sortBy) {
      edges {
        node {
          ...NotificationFragment
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export function useNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;