import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerAppointmentCreateMutationVariables = Types.Exact<{
  input: Types.ConsumerAppointmentInput;
}>;


export type ConsumerAppointmentCreateMutation = { __typename?: 'Mutation', consumerAppointmentCreate?: { __typename?: 'ConsumerAppointmentCreate', appointment?: { __typename?: 'Appointment', id: string, requestedTimeSlots?: Array<string | null> | null } | null } | null };


export const ConsumerAppointmentCreateDocument = gql`
    mutation ConsumerAppointmentCreate($input: ConsumerAppointmentInput!) {
  consumerAppointmentCreate(input: $input) {
    appointment {
      id
      requestedTimeSlots
    }
  }
}
    `;
export type ConsumerAppointmentCreateMutationFn = Apollo.MutationFunction<ConsumerAppointmentCreateMutation, ConsumerAppointmentCreateMutationVariables>;

/**
 * __useConsumerAppointmentCreateMutation__
 *
 * To run a mutation, you first call `useConsumerAppointmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerAppointmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerAppointmentCreateMutation, { data, loading, error }] = useConsumerAppointmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumerAppointmentCreateMutation(baseOptions?: Apollo.MutationHookOptions<ConsumerAppointmentCreateMutation, ConsumerAppointmentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumerAppointmentCreateMutation, ConsumerAppointmentCreateMutationVariables>(ConsumerAppointmentCreateDocument, options);
      }
export type ConsumerAppointmentCreateMutationHookResult = ReturnType<typeof useConsumerAppointmentCreateMutation>;
export type ConsumerAppointmentCreateMutationResult = Apollo.MutationResult<ConsumerAppointmentCreateMutation>;
export type ConsumerAppointmentCreateMutationOptions = Apollo.BaseMutationOptions<ConsumerAppointmentCreateMutation, ConsumerAppointmentCreateMutationVariables>;