import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import { useMeetingDuration } from "./useMeetingDuration";
export const MeetingDuration = memo(({ call }) => {
    var _a;
    const startedAt = (_a = call.callInfo) === null || _a === void 0 ? void 0 : _a.createdAt;
    const { duration } = useMeetingDuration({ startedAt });
    if (!startedAt) {
        return null;
    }
    return _jsx(_Fragment, { children: duration });
});
