var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-statements */
import { useCallback, useState } from "react";
import { ConnectionState } from "./types";
import { useZoom } from "./useZoom";
import { formatExecutedFailure, getUserMedia, startAudio } from "./utils";
export const useMuteAudio = () => {
    var _a;
    const { mediaStream, currentUser, connectionState } = useZoom();
    const [localStream, setLocalStream] = useState();
    const { userId, muted: audioMuted } = currentUser || { muted: !localStream || ((_a = localStream.getAudioTracks()[0]) === null || _a === void 0 ? void 0 : _a.muted) };
    const muteAudio = useCallback((value_1, ...args_1) => __awaiter(void 0, [value_1, ...args_1], void 0, function* (value, options = {}) {
        var _a, _b;
        try {
            const muted = typeof value === "function" ? value(audioMuted === undefined ? true : !!audioMuted) : value;
            if (connectionState !== ConnectionState.Connected) {
                if (muted) {
                    localStream === null || localStream === void 0 ? void 0 : localStream.getTracks().forEach(t => t.stop());
                    setLocalStream(undefined);
                    return { success: true };
                }
                else {
                    const result = yield getUserMedia({ audio: true });
                    setLocalStream(result.success ? result.stream : undefined);
                    return result;
                }
            }
            if (!userId) {
                return { type: "USER_NOT_CONNECTED" };
            }
            if (!mediaStream) {
                return { type: "CLIENT_NOT_READY" };
            }
            if (!muted) {
                if (audioMuted === undefined) {
                    const audioResult = yield startAudio(mediaStream, { mute: muted });
                    if (!audioResult.success) {
                        yield startAudio(mediaStream, { speakerOnly: true });
                        console.error("Meeting: Unable mute audio due to the error", audioResult);
                        return audioResult;
                    }
                    yield mediaStream.startAudio({ mute: muted });
                }
                else {
                    yield mediaStream.unmuteAudio();
                }
            }
            else {
                yield mediaStream.muteAudio();
            }
            const microphoneId = (_b = (_a = options === null || options === void 0 ? void 0 : options.constraints) === null || _a === void 0 ? void 0 : _a.audio) === null || _b === void 0 ? void 0 : _b.deviceId;
            if (microphoneId) {
                yield mediaStream.switchMicrophone(microphoneId);
            }
            return { success: true };
        }
        catch (error) {
            console.error("Meeting: Unable mute audio due to the error", error);
            return formatExecutedFailure(error);
        }
    }), [audioMuted, connectionState, localStream, mediaStream, userId]);
    return { muteAudio, audioMuted: audioMuted !== false, audioStream: localStream };
};
