import { EmptyDoctorsIcon, EmptyMessageLayout } from "@/components";
import { CareTeamDoctorCardSkeleton } from "@/domains/care-teams/components/CareTeamDoctorCardSkeleton/CareTeamDoctorCardSkeleton";
import useContainerHeight from "@/hooks/useContainerHeight";
import { routesPaths } from "@/routing/routesPaths";
import { DoctorFilterInput } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomInfiniteScroll } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { VirtualVisitDoctorCard } from "../VirtualVisitDoctorCard/VirtualVisitDoctorCard";
import { useVirtualVisitAvailableDoctorsContainer } from "./useVirtualVisitAvailableDoctorsContainer";
import { useVirtualVisitAvailableDoctorsListStyle } from "./useVirtualVisitAvailableDoctorsListStyle";

type VirtualVisitAvailableDoctorsListProps = {
  filters: DoctorFilterInput;
};

export const VirtualVisitAvailableDoctorsList: FC<VirtualVisitAvailableDoctorsListProps> = props => {
  const { filters } = props;
  const { doctors, isLoading, fetchMoreData, hasMore, handleRefresh } = useVirtualVisitAvailableDoctorsContainer(filters);
  const { classes } = useVirtualVisitAvailableDoctorsListStyle();
  const { containerRef, containerHeight } = useContainerHeight();
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");
  const handleNavigateToAppointment = () => {
    navigate(routesPaths.appointments);
  };

  return (
    <>
      {isLoading &&
        !doctors.length &&
        Array(3)
          .fill(1)
          .map((_, index) => (
            <Box marginInline={1} key={index}>
              <CareTeamDoctorCardSkeleton hasActions={true} />
            </Box>
          ))}

      <Box ref={containerRef} className={classes.content}>
        <CustomInfiniteScroll
          dataLength={Number(doctors?.length) || 1}
          onFetchMore={fetchMoreData}
          hasMore={hasMore}
          loader={<CareTeamDoctorCardSkeleton hasActions={true} />}
          height={(containerHeight || 10) - 15}
          onRefresh={handleRefresh}
        >
          {
            <>
              {!isLoading && !doctors.length && (
                <Box className={classes.emptyPageContainer}>
                  <EmptyMessageLayout
                    icon={<EmptyDoctorsIcon />}
                    message={t("There are no doctors available at the moment.")}
                    subMessage={t(
                      "No doctors found online right now. But we're here to help! You can schedule an appointment for a later time."
                    )}
                    action={
                      <Button onClick={handleNavigateToAppointment} className={classes.scheduleButton}>
                        {t("Schedule an Appointment")}
                      </Button>
                    }
                  />
                </Box>
              )}

              {doctors?.length > 0 && doctors?.map(doctor => <VirtualVisitDoctorCard key={doctor?.id} doctor={doctor} />)}
            </>
          }
        </CustomInfiniteScroll>
      </Box>
    </>
  );
};
