var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { CallError } from "../Call/CallError";
import { useCallInvitationToken } from "./useCallInvitationToken";
import { Box, Button, TextField, Typography, useAddToast } from "@toolkit/ui";
import { CallState, useCall } from "../Call/types";
import { useTranslation } from "@toolkit/i18n";
import { useGuestCallViewStyles } from "./GuestCallView.styles";
import { CallContent } from "../Call/CallContent";
export const GuestCallView = ({ token }) => {
    var _a;
    const { t } = useTranslation();
    const { failed } = useAddToast();
    const [submitted, setSubmitted] = useState(false);
    const [name, setName] = useState("");
    const { error, loading, retry: handleRetry, data, getCallInvitationTokenInfo } = useCallInvitationToken({ token });
    const handleChangeName = useCallback((e) => setName(e.target.value || ""), []);
    const { classes } = useGuestCallViewStyles();
    const call = useCall({});
    const handleJoin = useCallback((e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setSubmitted(true);
        const result = yield getCallInvitationTokenInfo({ token });
        if (!result) {
            failed(t("Call is not started yet. Please try again later."));
        }
    }), [name, token, getCallInvitationTokenInfo, failed, t]);
    const handleReset = useCallback(() => {
        setSubmitted(false);
        handleRetry();
    }, [handleRetry]);
    if (error || loading || !data) {
        return _jsx(CallError, { isLoading: loading, hasError: !!error, onClick: handleRetry });
    }
    const isCallStarted = !data && call && ((_a = call.state) === null || _a === void 0 ? void 0 : _a.callState) !== CallState.NoCall;
    if (!isCallStarted || !name || !submitted) {
        return (_jsx("form", { className: classes.root, onSubmit: handleJoin, children: _jsxs(Box, { className: classes.content, sx: { width: 320 }, children: [_jsxs(Box, { children: [_jsx(Typography, { className: classes.title, children: t("Join Meeting") }), _jsx(Typography, { children: data.topic })] }), _jsx(TextField, { required: true, fullWidth: true, value: name, label: t("Enter your name"), onChange: handleChangeName }), _jsx(Button, { type: 'submit', fullWidth: true, children: t("Join") })] }) }));
    }
    return _jsx(CallContent, { call: call, onClose: handleReset });
};
