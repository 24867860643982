import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefundPaymentFromWalletMutationVariables = Types.Exact<{
  input: Types.RefundPaymentFromWalletInput;
}>;


export type RefundPaymentFromWalletMutation = { __typename?: 'Mutation', refundPaymentFromWallet?: { __typename?: 'PaymentResponse', id?: string | null, amount?: number | null } | null };


export const RefundPaymentFromWalletDocument = gql`
    mutation RefundPaymentFromWallet($input: RefundPaymentFromWalletInput!) {
  refundPaymentFromWallet(input: $input) {
    id
    amount
  }
}
    `;
export type RefundPaymentFromWalletMutationFn = Apollo.MutationFunction<RefundPaymentFromWalletMutation, RefundPaymentFromWalletMutationVariables>;

/**
 * __useRefundPaymentFromWalletMutation__
 *
 * To run a mutation, you first call `useRefundPaymentFromWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundPaymentFromWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundPaymentFromWalletMutation, { data, loading, error }] = useRefundPaymentFromWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundPaymentFromWalletMutation(baseOptions?: Apollo.MutationHookOptions<RefundPaymentFromWalletMutation, RefundPaymentFromWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundPaymentFromWalletMutation, RefundPaymentFromWalletMutationVariables>(RefundPaymentFromWalletDocument, options);
      }
export type RefundPaymentFromWalletMutationHookResult = ReturnType<typeof useRefundPaymentFromWalletMutation>;
export type RefundPaymentFromWalletMutationResult = Apollo.MutationResult<RefundPaymentFromWalletMutation>;
export type RefundPaymentFromWalletMutationOptions = Apollo.BaseMutationOptions<RefundPaymentFromWalletMutation, RefundPaymentFromWalletMutationVariables>;