import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, CustomIcon, Divider, IconButton, List, MuiDrawer, Typography, useMediaQuery } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useMeetingSidebarStyles } from "./MeetingSidebar.styles";
import { ParticipantListItem } from "./ParticipantListItem";
import { MeetingChatView } from "./MeetingChatView";
export const MeetingSidebar = ({ call, isChatOpen, isParticipantsOpen, isDrawerOpen, hasInvitationLink, isInvitationEnabled, onCloseDrawer, onToggleChat, onToggleParticipants, onToggleGuestInvite, handleShareInvitation, }) => {
    const { t } = useTranslation();
    const { classes, theme } = useMeetingSidebarStyles();
    const media = useMediaQuery(theme.breakpoints.down("md"));
    const drawerWidth = isDrawerOpen ? 320 : "unset";
    return (_jsxs(MuiDrawer, { variant: media ? "temporary" : "permanent", keepMounted: true, sx: {
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
            },
        }, open: isDrawerOpen, anchor: 'right', onClose: onCloseDrawer, className: classes.root, children: [isParticipantsOpen && (_jsxs(Box, { className: classes.item, children: [_jsxs(Box, { className: classes.itemHeader, children: [_jsx(Typography, { textAlign: 'center', color: theme.palette.common.white, fontSize: theme.mixins.fonts.fontSize.lg, children: t("Participants") }), _jsx(IconButton, { color: 'inherit', onClick: onToggleParticipants, children: _jsx(CustomIcon, { color: theme.palette.common.white, icon: 'xAlert' }) })] }), _jsxs(Box, { sx: { overflow: "auto" }, children: [isInvitationEnabled && (_jsxs(Box, { sx: { p: 1, display: "flex", justifyContent: "space-between" }, children: [_jsx(Button, { onClick: onToggleGuestInvite, children: t("Guest Invite") }), hasInvitationLink && (_jsx(Button, { variant: 'contained', onClick: handleShareInvitation, children: t("Share Invite") }))] })), _jsx(List, { children: call.participants.map(p => (_jsx(ParticipantListItem, { participant: p }, p.id))) })] })] })), isChatOpen && (_jsxs(_Fragment, { children: [isParticipantsOpen && _jsx(Divider, {}), _jsxs(Box, { className: classes.item, children: [_jsxs(Box, { className: classes.itemHeader, children: [_jsx(Typography, { textAlign: 'center', color: theme.palette.common.white, fontSize: theme.mixins.fonts.fontSize.lg, children: t("Chat") }), _jsx(IconButton, { color: 'inherit', onClick: onToggleChat, children: _jsx(CustomIcon, { color: theme.palette.common.white, icon: 'xAlert' }) })] }), _jsx(MeetingChatView, { call: call })] })] }))] }));
};
