import { VisitStatus } from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { AppToastProvider } from "@toolkit/ui";

export const openMeetingPlatformLink = async (visitId?: string) => {
  if (visitId) {
    document.location.href = `${location.origin}/visit-call?visitId=${visitId}`;
  } else {
    AppToastProvider.addFailedToast(i18n.t("Failed to rejoin the call. Please retry."));
  }
};

export const ReJoinableVisitStatuses = [
  VisitStatus.CallTimedOut,
  VisitStatus.Canceled,
  VisitStatus.ChatInProgress,
  VisitStatus.Incompleted,
  VisitStatus.EndedByOneOfTheParticipants,
];
