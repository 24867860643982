var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from "react";
import { useFlipCamera } from "./useFlipCamera";
import { useZoom } from "./useZoom";
import { CallState, useCallState } from "./useCallState";
import { useCallParticipants } from "./useCallParticipants";
import { useMuteSpeaker } from "./useMuteSpeaker";
import { useTranslation } from "@toolkit/i18n";
export function useCall({ meeting, user }) {
    const { t } = useTranslation();
    const { userId, userName } = user || {};
    const { id: callId, topic, jwtToken } = meeting || {};
    const { flipCamera } = useFlipCamera();
    const { joinSession, leaveSession } = useZoom();
    const { muteSpeaker, speakerMuted } = useMuteSpeaker();
    const { currentParticipant, participants, muteAudio, muteVideo, muteScreen } = useCallParticipants({
        userId: userId,
        displayName: userName,
        meeting,
    });
    const { displayName } = currentParticipant;
    const { audioMuted, videoMuted } = currentParticipant.state;
    const { callState, updateCallStatus, resetCallState } = useCallState(meeting);
    const joinCall = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!callId) {
            return;
        }
        if (!topic || !jwtToken || !displayName) {
            return;
        }
        const result = yield (joinSession === null || joinSession === void 0 ? void 0 : joinSession({
            topic: topic,
            token: jwtToken,
            userName: displayName,
            sessionPassword: "",
            audioOptions: { mute: audioMuted },
            videoOptions: { localVideoOn: !videoMuted },
        }));
        if (result.success) {
            updateCallStatus(CallState.InCall);
        }
        return result;
    }), [callId, topic, jwtToken, displayName, joinSession, audioMuted, videoMuted, updateCallStatus]);
    const dropCall = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        updateCallStatus(CallState.Dropped);
        return leaveSession === null || leaveSession === void 0 ? void 0 : leaveSession();
    }), [leaveSession, updateCallStatus]);
    return useMemo(() => {
        var _a, _b;
        return {
            callId,
            callInfo: meeting,
            callInvitationToken: "",
            visitId: (_a = meeting === null || meeting === void 0 ? void 0 : meeting.chatGroup) === null || _a === void 0 ? void 0 : _a.visitId,
            title: ((_b = meeting === null || meeting === void 0 ? void 0 : meeting.chatGroup) === null || _b === void 0 ? void 0 : _b.name) || t("Meeting"),
            participants,
            currentParticipant,
            state: Object.assign(Object.assign({}, currentParticipant.state), { callState,
                speakerMuted }),
            flipCamera,
            muteAudio,
            muteVideo,
            muteScreen,
            muteSpeaker,
            joinCall,
            dropCall,
            updateCallStatus,
            resetCallState,
        };
    }, [
        callId,
        meeting,
        t,
        participants,
        currentParticipant,
        callState,
        speakerMuted,
        flipCamera,
        muteAudio,
        muteVideo,
        muteScreen,
        muteSpeaker,
        joinCall,
        dropCall,
        updateCallStatus,
        resetCallState,
    ]);
}
