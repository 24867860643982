import { useState, useEffect } from "react";

const useCountDown = (initialCount = 10) => {
  const [count, setCount] = useState<number>(initialCount);
  const isDone: boolean = count === 0;

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!isDone) {
      timer = setInterval(() => {
        setCount(prevCount => {
          const newCount = prevCount - 1;
          if (newCount <= 0) {
            return 0;
          } else {
            return newCount;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isDone]);

  const startCountDown = () => {
    setCount(initialCount);
  };

  const resetCountDown = () => {
    setCount(0);
  };

  const remainingSeconds: string = String(count % 60).padStart(2, "0");
  const remainingMinutes: string = String(Math.floor(count / 60)).padStart(2, "0");

  return { isDone, count, remainingSeconds, remainingMinutes, startCountDown, resetCountDown };
};

export default useCountDown;
