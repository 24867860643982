import { jsx as _jsx } from "react/jsx-runtime";
import { JoinScreen } from "./JoinScreen";
import { MeetingScreen } from "./MeetingScreen";
import { RatingScreen } from "./RatingScreen";
import { TimeoutScreen } from "./TimeoutScreen";
import { WaitingScreen } from "./WaitingScreen";
import { CallState } from "./types";
export const CallContent = ({ renderDoctorConsole, invitationLink, call, visit, onClose, onGoBack }) => {
    const { callState } = call.state;
    if (callState === CallState.NoCall) {
        return _jsx(WaitingScreen, { call: call, onClose: onClose });
    }
    if (callState === CallState.Started) {
        return _jsx(JoinScreen, { call: call, onClose: onClose });
    }
    if (callState === CallState.Timeout) {
        return _jsx(TimeoutScreen, { call: call, onClose: onClose });
    }
    if (callState === CallState.Dropped || callState === CallState.Ended) {
        return _jsx(RatingScreen, { call: call, visit: visit, onClose: onClose });
    }
    return _jsx(MeetingScreen, { call: call, renderDoctorConsole: renderDoctorConsole, invitationLink: invitationLink, onGoBack: onGoBack });
};
