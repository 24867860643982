import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerAppointmentCreateForAnonymousMutationVariables = Types.Exact<{
  input: Types.ConsumerAppointmentInputForAnonymous;
}>;


export type ConsumerAppointmentCreateForAnonymousMutation = { __typename?: 'Mutation', consumerAppointmentCreateForAnonymous?: { __typename?: 'ConsumerAppointmentCreate', appointment?: { __typename?: 'Appointment', id: string, requestedTimeSlots?: Array<string | null> | null } | null } | null };


export const ConsumerAppointmentCreateForAnonymousDocument = gql`
    mutation ConsumerAppointmentCreateForAnonymous($input: ConsumerAppointmentInputForAnonymous!) {
  consumerAppointmentCreateForAnonymous(input: $input) {
    appointment {
      id
      requestedTimeSlots
    }
  }
}
    `;
export type ConsumerAppointmentCreateForAnonymousMutationFn = Apollo.MutationFunction<ConsumerAppointmentCreateForAnonymousMutation, ConsumerAppointmentCreateForAnonymousMutationVariables>;

/**
 * __useConsumerAppointmentCreateForAnonymousMutation__
 *
 * To run a mutation, you first call `useConsumerAppointmentCreateForAnonymousMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerAppointmentCreateForAnonymousMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerAppointmentCreateForAnonymousMutation, { data, loading, error }] = useConsumerAppointmentCreateForAnonymousMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsumerAppointmentCreateForAnonymousMutation(baseOptions?: Apollo.MutationHookOptions<ConsumerAppointmentCreateForAnonymousMutation, ConsumerAppointmentCreateForAnonymousMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumerAppointmentCreateForAnonymousMutation, ConsumerAppointmentCreateForAnonymousMutationVariables>(ConsumerAppointmentCreateForAnonymousDocument, options);
      }
export type ConsumerAppointmentCreateForAnonymousMutationHookResult = ReturnType<typeof useConsumerAppointmentCreateForAnonymousMutation>;
export type ConsumerAppointmentCreateForAnonymousMutationResult = Apollo.MutationResult<ConsumerAppointmentCreateForAnonymousMutation>;
export type ConsumerAppointmentCreateForAnonymousMutationOptions = Apollo.BaseMutationOptions<ConsumerAppointmentCreateForAnonymousMutation, ConsumerAppointmentCreateForAnonymousMutationVariables>;