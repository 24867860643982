import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/queries/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AvailableDoctorsForVirtualCallQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.DoctorFilterInput>;
  sortBy?: Types.InputMaybe<Types.DoctorOrder>;
}>;


export type AvailableDoctorsForVirtualCallQuery = { __typename?: 'Query', doctors?: { __typename?: 'DoctorCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'DoctorCountableEdge', node: { __typename?: 'Doctor', id: string, averageRating: number, isLanguagesPublic: boolean, isYearsOfExperiencePublic: boolean, yearsOfExperience?: number | null, onlineVisitPrice?: number | null, ratingsSum: number, user?: { __typename?: 'User', fullName?: string | null, photo?: string | null, gender?: Types.UserGender | null } | null, vendor: { __typename?: 'Vendor', name: string, logo?: string | null, nameAr?: string | null }, languages?: Array<{ __typename?: 'Language', display: string, displayAr?: string | null } | null> | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', display?: string | null, arabicDisplay?: string | null } | null> | null } }> } | null };


export const AvailableDoctorsForVirtualCallDocument = gql`
    query availableDoctorsForVirtualCall($first: Int = 10, $after: String, $last: Int, $before: String, $filter: DoctorFilterInput, $sortBy: DoctorOrder) {
  doctors(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        averageRating
        user {
          fullName
          photo
          gender
        }
        isLanguagesPublic
        isYearsOfExperiencePublic
        yearsOfExperience
        vendor {
          name
          logo
          nameAr
        }
        onlineVisitPrice
        ratingsSum
        languages {
          display
          displayAr
        }
        specializations {
          display
          arabicDisplay
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useAvailableDoctorsForVirtualCallQuery__
 *
 * To run a query within a React component, call `useAvailableDoctorsForVirtualCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDoctorsForVirtualCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDoctorsForVirtualCallQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAvailableDoctorsForVirtualCallQuery(baseOptions?: Apollo.QueryHookOptions<AvailableDoctorsForVirtualCallQuery, AvailableDoctorsForVirtualCallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableDoctorsForVirtualCallQuery, AvailableDoctorsForVirtualCallQueryVariables>(AvailableDoctorsForVirtualCallDocument, options);
      }
export function useAvailableDoctorsForVirtualCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableDoctorsForVirtualCallQuery, AvailableDoctorsForVirtualCallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableDoctorsForVirtualCallQuery, AvailableDoctorsForVirtualCallQueryVariables>(AvailableDoctorsForVirtualCallDocument, options);
        }
export function useAvailableDoctorsForVirtualCallSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AvailableDoctorsForVirtualCallQuery, AvailableDoctorsForVirtualCallQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableDoctorsForVirtualCallQuery, AvailableDoctorsForVirtualCallQueryVariables>(AvailableDoctorsForVirtualCallDocument, options);
        }
export type AvailableDoctorsForVirtualCallQueryHookResult = ReturnType<typeof useAvailableDoctorsForVirtualCallQuery>;
export type AvailableDoctorsForVirtualCallLazyQueryHookResult = ReturnType<typeof useAvailableDoctorsForVirtualCallLazyQuery>;
export type AvailableDoctorsForVirtualCallSuspenseQueryHookResult = ReturnType<typeof useAvailableDoctorsForVirtualCallSuspenseQuery>;
export type AvailableDoctorsForVirtualCallQueryResult = Apollo.QueryResult<AvailableDoctorsForVirtualCallQuery, AvailableDoctorsForVirtualCallQueryVariables>;