import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const VisitCallEndDocument = gql `
    mutation VisitCallEnd($visitId: ID!) {
  visitCallEnd(visitId: $visitId) {
    success
    visitErrors {
      message
      field
      code
    }
  }
}
    `;
/**
 * __useVisitCallEndMutation__
 *
 * To run a mutation, you first call `useVisitCallEndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitCallEndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitCallEndMutation, { data, loading, error }] = useVisitCallEndMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitCallEndMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(VisitCallEndDocument, options);
}
