import { PageLayout } from "@/components";
import { PageLayoutProps } from "@/components/PageLayout/type";
import { useCallPageLayoutStyle } from "./CallPageLayoutStyle";

export const CallPageLayout = (props: PageLayoutProps) => {
  const { containerBreakpoint, withBlob = false, children } = props;

  const { classes } = useCallPageLayoutStyle();
  return (
    <PageLayout
      displayBlob={withBlob}
      containerBreakpoint={containerBreakpoint}
      containerBackground='paper'
      classes={{ container: classes.content }}
    >
      {children}
    </PageLayout>
  );
};
