/* eslint-disable max-lines */
import { useEffect, useMemo, useRef, useState } from "react";
import { pickLocalizedValue } from "@toolkit/i18n";

interface UseVirtualScrollingProps {
  cards: JSX.Element[];
  cardWidth: number;
  gap: number;
  visibleCardCount: number;
  onReachEnd?: () => void;
  onReachStart?: () => void;
  onActiveCardChange?: (index: number) => void;
}

interface UseVirtualScrollingReturn {
  ref: React.RefObject<HTMLDivElement>;
  filteredCards: JSX.Element[];
  firstCardWidth: number;
  lastCardWidth: number;
  handleScroll: () => void;
  handleScrollButton: (direction: "next" | "prev") => void;
}

export const useVirtualScrolling = ({
  cards,
  cardWidth,
  gap,
  visibleCardCount,
  onReachEnd,
  onReachStart,
  onActiveCardChange,
}: UseVirtualScrollingProps): UseVirtualScrollingReturn => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const isUserAction = useRef(true);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isFirstScrollDone = useRef(false);
  const totalCardWidth = cardWidth + gap;
  const scrollDirection = pickLocalizedValue(1, -1);
  const disableButton = useRef(false);
  const currentCard = useMemo(() => Math.round((scrollPosition * scrollDirection) / totalCardWidth), [scrollPosition, totalCardWidth]);

  const lastPosition = useMemo(() => currentCard * totalCardWidth * scrollDirection, [currentCard, totalCardWidth]);

  useEffect(() => {
    if (currentCard === 0 && onReachStart && isFirstScrollDone.current) {
      onReachStart();
    } else if (currentCard === cards.length - 1 && onReachEnd && isFirstScrollDone.current) {
      onReachEnd();
    }
    if (onActiveCardChange) {
      onActiveCardChange(currentCard);
    }
  }, [currentCard]);

  const handleScroll = () => {
    if (isUserAction.current && ref.current) {
      const newScrollPosition = ref.current.scrollLeft;
      const delta = newScrollPosition * scrollDirection - lastPosition * scrollDirection;
      const direction = delta > 0 ? "next" : "prev";
      const absDelta = Math.abs(delta);

      if (absDelta < totalCardWidth / 2) return;

      isUserAction.current = false;

      const newPosition = lastPosition + (direction === "next" ? totalCardWidth : -totalCardWidth) * scrollDirection;

      ref.current.scrollTo({
        left: newPosition,
        behavior: "smooth",
      });

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        isUserAction.current = true;
      }, 300);

      ref.current.classList.add("hidden-overflow");

      setTimeout(() => {
        ref.current?.classList.remove("hidden-overflow");
      }, 100);
    }

    ref.current && setScrollPosition(ref.current?.scrollLeft);
  };

  useEffect(() => {
    const handleScrollEnd = () => {
      if (ref.current) {
        const scrollLeft = ref.current.scrollLeft;
        const currentCardStart = Math.round(scrollLeft / totalCardWidth) * totalCardWidth;

        const direction = scrollLeft * scrollDirection > currentCardStart * scrollDirection ? "next" : "prev";

        if (direction === "prev") {
          ref.current.scrollLeft = currentCardStart;
        } else {
          const delta = Math.abs(scrollLeft) - Math.abs(currentCardStart);
          const containerWidth = ref.current.clientWidth;

          if (Math.abs(delta) >= totalCardWidth - containerWidth) {
            ref.current.scrollLeft = currentCardStart + (totalCardWidth - containerWidth) * scrollDirection;
          }
        }
      }
    };

    const currentRef = ref.current;
    currentRef?.addEventListener("scrollend", handleScrollEnd);

    return () => {
      currentRef?.removeEventListener("scrollend", handleScrollEnd);
    };
  }, [totalCardWidth]);

  const firstCardWidth = useMemo(() => {
    const startIndex = currentCard >= 2 ? currentCard - 2 : 0;
    return startIndex * totalCardWidth - gap;
  }, [currentCard, totalCardWidth, gap]);

  const lastCardWidth = useMemo(() => {
    const remainingCards = cards.length - (currentCard + visibleCardCount);
    return remainingCards > 0 ? remainingCards * totalCardWidth : 0;
  }, [currentCard, cards.length, visibleCardCount, totalCardWidth]);

  const filteredCards = useMemo(() => {
    const startIndex = currentCard >= 2 ? currentCard - 2 : 0;
    const endIndex = currentCard + visibleCardCount;
    return cards.slice(startIndex, endIndex);
  }, [cards, currentCard, visibleCardCount]);

  const handleScrollButton = (scrollType: "next" | "prev") => {
    if (disableButton.current) return;
    if (ref.current && isFirstScrollDone.current && !disableButton.current) {
      const newPosition = lastPosition + (scrollType === "next" ? totalCardWidth : -totalCardWidth) * scrollDirection;
      if (currentCard === 1 || currentCard === cards.length - 2) {
        disableButton.current = true;
        setTimeout(() => {
          disableButton.current = false;
        }, 600);
      }
      isUserAction.current = false;
      ref.current.scrollTo({
        left: newPosition,
        behavior: disableButton.current ? "instant" : "smooth",
      });

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        isUserAction.current = true;
      }, 600);
    }
  };

  useEffect(() => {
    if (ref.current) {
      isUserAction.current = false;
      isFirstScrollDone.current = false;
      const initialPosition = Math.floor(cards.length / 2) * totalCardWidth * scrollDirection;
      ref.current.scrollLeft = initialPosition;

      debounceTimeout.current = setTimeout(() => {
        isUserAction.current = true;
      }, 600);
      setTimeout(() => {
        isFirstScrollDone.current = true;
      }, 400);
    }
  }, [cards.length, totalCardWidth, scrollDirection]);

  return {
    ref,
    filteredCards,
    firstCardWidth,
    lastCardWidth,
    handleScrollButton,
    handleScroll,
  };
};
