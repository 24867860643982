import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentQueryVariables = Types.Exact<{
  appointmentId: Types.Scalars['ID']['input'];
}>;


export type AppointmentQuery = { __typename?: 'Query', appointment?: { __typename?: 'Appointment', id: string, status?: Types.AppointmentStatus | null, type?: Types.AppointmentType | null, cancellationReason?: string | null, confirmedStartTime?: any | null, requestedTimeSlots?: Array<string | null> | null, confirmedEndTime?: any | null, created?: any | null, suggestedTimeSlots?: Array<string | null> | null, paymentStatus?: Types.PaymentStatus | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', type?: Types.AppointmentPaymentType | null, totalAmount?: number | null, priceAmountAfterVat?: number | null, vatPercentage?: number | null, currency?: string | null } | null, visit?: { __typename?: 'Visit', id: string, callId?: any | null, chatId?: any | null } | null, consumer?: { __typename?: 'Patient', firstName?: string | null, lastName?: string | null } | null, providerGuidedCareHealthProgramTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', guidedCareHealthProgram: { __typename?: 'GuidedCareHealthProgram', name?: string | null } } | null, doctor?: { __typename?: 'Doctor', id: string, averageRating: number, appointmentSlotTimePeriod: number, yearsOfExperience?: number | null, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, onlineVisitPrice?: number | null, atHomeVisitPrice?: number | null, onsiteVisitPrice?: number | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, photo?: string | null, gender?: Types.UserGender | null } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null, isIntegrated: boolean }, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, code: string, display?: string | null } | null> | null, languages?: Array<{ __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null> | null } | null } | null };


export const AppointmentDocument = gql`
    query Appointment($appointmentId: ID!) {
  appointment(id: $appointmentId) {
    id
    status
    type
    cancellationReason
    confirmedStartTime
    requestedTimeSlots
    confirmedEndTime
    created
    suggestedTimeSlots
    paymentInfo {
      type
      totalAmount
      priceAmountAfterVat
      vatPercentage
      currency
    }
    visit {
      id
      callId
      chatId
    }
    consumer {
      firstName
      lastName
    }
    paymentStatus
    providerGuidedCareHealthProgramTeam {
      guidedCareHealthProgram {
        name
      }
    }
    doctor {
      id
      user {
        id
        firstName
        lastName
        photo
        gender
      }
      averageRating
      appointmentSlotTimePeriod
      yearsOfExperience
      appointmentTypes
      vendor {
        id
        name
        nameAr
        logo
        isIntegrated
      }
      onlineVisitPrice
      atHomeVisitPrice
      onsiteVisitPrice
      specializations {
        id
        code
        display
      }
      languages {
        code
        display
        displayAr
        id
      }
    }
  }
}
    `;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentQuery(baseOptions: Apollo.QueryHookOptions<AppointmentQuery, AppointmentQueryVariables> & ({ variables: AppointmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
      }
export function useAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export function useAppointmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentSuspenseQueryHookResult = ReturnType<typeof useAppointmentSuspenseQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<AppointmentQuery, AppointmentQueryVariables>;