import { jsx as _jsx } from "react/jsx-runtime";
import { ChatViewMediaPhoto } from "../ChatView";
export const ChatDraftViewMedia = ({ attachments }) => {
    if (attachments.length === 1) {
        const [attachment] = attachments;
        const { type } = attachment;
        if (type.startsWith("image/")) {
            return _jsx(ChatViewMediaPhoto, { media: attachment });
        }
    }
    return null;
};
