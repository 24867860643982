import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@toolkit/ui";
import { CircularProgress } from "@toolkit/ui";
import { useChatViewMediaPhoto } from "./ChatViewMediaPhoto.hooks";
import { useStyles } from "./ChatViewMediaPhoto.styles";
import { ChatViewMediaPhotoZoom } from "./ChatViewMediaPhotoZoom";
import { BrokenImage } from "@mui/icons-material";
export const ChatViewMediaPhoto = ({ media, isZoomDisabled }) => {
    const { src, width, height, loading, error, onLoad, onError } = useChatViewMediaPhoto(media);
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const isZoomable = !loading && !error && !isZoomDisabled;
    const handleToggleOpen = useCallback(() => setOpen(o => !o), []);
    const onClick = isZoomable ? handleToggleOpen : undefined;
    return (_jsxs(_Fragment, { children: [open && isZoomable && _jsx(ChatViewMediaPhotoZoom, { media: media, onClose: handleToggleOpen }), _jsxs(Box, { className: classes.root, sx: { cursor: isZoomable ? "pointer" : "default" }, onClick: onClick, children: [loading && (_jsx(Box, { className: classes.loader, children: _jsx(CircularProgress, { color: 'primary' }) })), error && (_jsx(Box, { sx: { width, height }, children: _jsx(BrokenImage, { className: classes.placeholder, fontSize: 'large', color: 'disabled' }) })), _jsx("img", { 
                        // eslint-disable-next-line react/forbid-dom-props
                        style: { visibility: loading || error ? "hidden" : "visible" }, onLoad: onLoad, onError: onError, draggable: 'false', src: src, width: width, height: height, alt: '' })] })] }));
};
