import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerAppointmentConfirmMutationVariables = Types.Exact<{
  input: Types.ConsumerAppointmentConfirmInput;
  consumerAppointmentConfirmId: Types.Scalars['ID']['input'];
}>;


export type ConsumerAppointmentConfirmMutation = { __typename?: 'Mutation', consumerAppointmentConfirm?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string, status?: Types.AppointmentStatus | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', type?: Types.AppointmentPaymentType | null } | null } | null, appointmentErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, message?: string | null, field?: string | null }> | null } | null };


export const ConsumerAppointmentConfirmDocument = gql`
    mutation ConsumerAppointmentConfirm($input: ConsumerAppointmentConfirmInput!, $consumerAppointmentConfirmId: ID!) {
  consumerAppointmentConfirm(input: $input, id: $consumerAppointmentConfirmId) {
    appointment {
      id
      status
      confirmedStartTime
      confirmedEndTime
      paymentInfo {
        type
      }
    }
    appointmentErrors {
      code
      message
      field
    }
  }
}
    `;
export type ConsumerAppointmentConfirmMutationFn = Apollo.MutationFunction<ConsumerAppointmentConfirmMutation, ConsumerAppointmentConfirmMutationVariables>;

/**
 * __useConsumerAppointmentConfirmMutation__
 *
 * To run a mutation, you first call `useConsumerAppointmentConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumerAppointmentConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumerAppointmentConfirmMutation, { data, loading, error }] = useConsumerAppointmentConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *      consumerAppointmentConfirmId: // value for 'consumerAppointmentConfirmId'
 *   },
 * });
 */
export function useConsumerAppointmentConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ConsumerAppointmentConfirmMutation, ConsumerAppointmentConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsumerAppointmentConfirmMutation, ConsumerAppointmentConfirmMutationVariables>(ConsumerAppointmentConfirmDocument, options);
      }
export type ConsumerAppointmentConfirmMutationHookResult = ReturnType<typeof useConsumerAppointmentConfirmMutation>;
export type ConsumerAppointmentConfirmMutationResult = Apollo.MutationResult<ConsumerAppointmentConfirmMutation>;
export type ConsumerAppointmentConfirmMutationOptions = Apollo.BaseMutationOptions<ConsumerAppointmentConfirmMutation, ConsumerAppointmentConfirmMutationVariables>;