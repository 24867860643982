var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { CustomDialog, DialogContent, FormActions, useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { FormProvider, useForm } from "react-hook-form";
import { GuestInviteForm } from "./GuestInviteForm";
export const GuestInvite = ({ onClose }) => {
    const { t } = useTranslation();
    const { succeeded, failed } = useAddToast();
    // @TODO
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sendConferenceCallInvitation = useCallback((value) => __awaiter(void 0, void 0, void 0, function* () {
        return false;
    }), []);
    const loading = false;
    const methods = useForm({
        mode: "onChange",
    });
    const onSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        if (values.contacts.length < 1) {
            return;
        }
        const result = yield sendConferenceCallInvitation({
            emails: values.contacts.map(c => c.email).filter(c => !!c),
            phoneNumbers: values.contacts.map(c => c.phoneNumber).filter(c => !!c),
        });
        if (result) {
            onClose();
            succeeded(t("Invitation Sent!"));
        }
        else {
            failed(t("Failed to send invitation, please retry."));
        }
    }), [failed, onClose, sendConferenceCallInvitation, succeeded, t]);
    return (_jsx(CustomDialog, { open: true, onClose: onClose, type: 'base', DialogTitleProps: {
            onClose,
            title: t("Add Guests"),
        }, children: _jsx(DialogContent, { children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("form", { onSubmit: methods.handleSubmit(onSubmit), children: [_jsx(GuestInviteForm, {}), _jsx(FormActions, { hasCancel: true, isLoading: loading, hasFormButton: true, newButtonDisabled: false, formButtonTitle: t("Send"), onNavigation: onClose })] }) })) }) }));
};
