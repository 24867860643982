import { InPersonIcon, VideoCallIcon } from "@/components";
import { DoctorServiceButton } from "@/domains/doctor";
import { AppointmentTypeEnum } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { AtHomeIcon, Box, makeStyles } from "@toolkit/ui";

type AppointmentActionsBoxProps = {
  handleOnActionCLick: (visitType: AppointmentTypeEnum) => void;
  isAppointmentTypeDisabled: (visitType: AppointmentTypeEnum) => boolean;
  classes?: Partial<ReturnType<typeof useStyle>>["classes"];
};

const useStyle = makeStyles()(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
}));

export const AppointmentActionsBox: React.FC<AppointmentActionsBoxProps> = props => {
  const { handleOnActionCLick, isAppointmentTypeDisabled, classes: _classes } = props;

  const handleOnButtonCLick = (visitType: AppointmentTypeEnum) => {
    return (e: React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      handleOnActionCLick?.(visitType);
    };
  };
  const { classes, cx } = useStyle();
  const { t } = useTranslation("consumer");

  return (
    <Box className={cx(classes.root, _classes?.root)}>
      <DoctorServiceButton
        icon={<InPersonIcon />}
        disabled={isAppointmentTypeDisabled(AppointmentTypeEnum?.Onsite)}
        label={t("In-person")}
        onClick={handleOnButtonCLick(AppointmentTypeEnum?.Onsite)}
      />
      <DoctorServiceButton
        icon={<VideoCallIcon />}
        disabled={isAppointmentTypeDisabled(AppointmentTypeEnum?.Online)}
        label={t("Virtual")}
        onClick={handleOnButtonCLick(AppointmentTypeEnum?.Online)}
      />
      <DoctorServiceButton
        icon={<AtHomeIcon />}
        disabled={isAppointmentTypeDisabled(AppointmentTypeEnum?.AtHome)}
        label={t("At-Home")}
        onClick={handleOnButtonCLick(AppointmentTypeEnum.AtHome)}
      />
    </Box>
  );
};
