var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from "react";
import { useZoom } from "./useZoom";
import { formatExecutedFailure, startShareScreen } from "./utils";
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useMuteScreen = () => {
    const { mediaStream, currentUser } = useZoom();
    const { userId, sharerOn } = currentUser || {};
    const muteScreen = useCallback(
    // eslint-disable-next-line max-statements
    (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (!userId) {
            return { type: "USER_NOT_CONNECTED" };
        }
        if (!mediaStream) {
            return { type: "CLIENT_NOT_READY" };
        }
        if (mediaStream.isShareLocked()) {
            return { type: "SHARE_LOCKED" };
        }
        try {
            const muted = typeof value === "function" ? value(!sharerOn) : value;
            if (muted && sharerOn) {
                const result = yield mediaStream.stopShareScreen();
                if (result === "") {
                    return { success: true };
                }
                return result;
            }
            else if (!muted && !sharerOn) {
                const result = yield startShareScreen(mediaStream);
                if (result === "") {
                    return { success: true };
                }
                return result;
            }
            return { success: true };
        }
        catch (error) {
            console.error("Meeting: Unable mute screen due to the error", error);
            return formatExecutedFailure(error);
        }
    }), [userId, mediaStream, sharerOn]);
    const supportDisplayMediaAPI = "mediaDevices" in navigator && typeof navigator.mediaDevices.getDisplayMedia === "function";
    return { muteScreen: supportDisplayMediaAPI ? muteScreen : undefined, screenMuted: sharerOn !== true };
};
