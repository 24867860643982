import { jsx as _jsx } from "react/jsx-runtime";
import moment from "moment";
import { Box, format, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { i18n } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";
const useStyles = makeStyles()(() => ({
    root: { display: "flex", justifyContent: "center" },
    text: {
        paddingVertical: 18,
        textAlign: "center",
        backgroundColor: "#c0bfbc",
        borderRadius: "0.7rem",
        color: "#fff",
        padding: "0.2rem 0.45rem",
        fontSize: "0.8rem",
        wordBreak: "break-word",
    },
}));
export const ChatViewDate = ({ date }) => {
    const time = useMemo(() => format(moment(date).toISOString(), "ll", i18n.language), [date]);
    const { classes } = useStyles();
    return (_jsx(Box, { className: classes.root, children: _jsx(Typography, { className: classes.text, children: time }) }));
};
