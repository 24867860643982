import { ApolloProvider } from "@apollo/client";
import { useIsInEmbedView } from "@health/domains";
import { SadaProvider } from "@health/sada";
import { NotificationsServiceProvider } from "@health/sse";
import { I18nextProvider } from "@toolkit/i18n";
import { ToolkitUiProvider } from "@toolkit/ui";
import { FC, PropsWithChildren, useEffect } from "react";
import { AuthProvider as OdicAuthProvider, useAuth } from "react-oidc-context";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import SideMenuDrawer from "./components/SideMenuDrawer/SideMenuDrawer";
import { client } from "./configs/apollo";
import { oidcUserManager } from "./configs/oidc";
import { getEnvVariable } from "./env";
import i18n from "./i18n/i18n.config";
import { HeaderProvider } from "./providers";
import { AuthProvider } from "./providers/auth";
import { RootRoutes } from "./routing/RootRoutes";
import { VisitCallGuestBaseRoute } from "./domains/VisitCall/routes";
import { CallProvider } from "@health/meeting";
import { ChatProvider } from "@health/chat";

function App() {
  useEffect(() => {
    document.body.dir = i18n.dir(localStorage.getItem("locale") || "en");
  }, []);
  return (
    <BrowserRouter>
      <OdicAuthProvider {...oidcUserManager.settings}>
        <AuthProvider>
          <AppRootProviders>
            <HeaderProvider>
              <>
                <RootRoutes />
                <SideMenuDrawer />
              </>
            </HeaderProvider>
          </AppRootProviders>
        </AuthProvider>
      </OdicAuthProvider>
    </BrowserRouter>
  );
}
const EmbedRootProviders: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const token = user?.access_token || "";
  const isInEmbedView = useIsInEmbedView();
  const { pathname } = useLocation();
  if (isInEmbedView || pathname.startsWith(VisitCallGuestBaseRoute)) {
    return children;
  }

  return (
    <ChatProvider accessToken={token} senderId={user?.profile?.user_id as number}>
      <CallProvider accessToken={token} user={user?.profile}>
        <SadaProvider token={token || ""}>{children}</SadaProvider>
      </CallProvider>
    </ChatProvider>
  );
};

const AppRootProviders: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  return (
    <NotificationsServiceProvider eventSourceUrl={getEnvVariable("SUBSCRIPTION_URL")} token={user?.access_token || ""}>
      <EmbedRootProviders>
        <ApolloProvider client={client}>
          <I18nextProvider i18n={i18n}>
            <ToolkitUiProvider>{children}</ToolkitUiProvider>
          </I18nextProvider>
        </ApolloProvider>
      </EmbedRootProviders>
    </NotificationsServiceProvider>
  );
};

export default App;
