var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useCanvasDimension } from "./useCanvasDimension";
import { useZoom } from "./useZoom";
import { makeStyles } from "@toolkit/ui";
import { useUser } from "./useUser";
const useStyles = makeStyles()({
    root: {
        flex: 1,
        width: "100%",
        height: "100%",
    },
});
export const ZoomViewScreen = ({ participant }) => {
    const { classes } = useStyles();
    const { mediaStream, activeShare } = useZoom();
    const canvasRef = useRef(null);
    const { width, height } = useCanvasDimension(mediaStream, canvasRef);
    const [isRendered, setIsRendered] = useState(false);
    const { userId, sharerOn } = useUser(participant);
    const screenMuted = !sharerOn || (activeShare && (activeShare.userId !== userId || activeShare.state !== "Active"));
    useEffect(() => {
        if (!userId || !mediaStream || !canvasRef.current || screenMuted) {
            return;
        }
        const canvasElement = canvasRef.current;
        const renderScreen = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield mediaStream.startShareView(canvasElement, userId);
                setIsRendered(true);
            }
            catch (error) {
                if (error.type === "IMPROPER_MEETING_STATE") {
                    return;
                }
                console.error("Meeting: Unable to start share view due to the error", error);
            }
        });
        const stopRenderScreen = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield mediaStream.stopShareView();
            }
            catch (error) {
                if (error.type === "IMPROPER_MEETING_STATE") {
                    return;
                }
                console.error("Meeting: Unable to stop share view due to the error", error);
            }
        });
        setIsRendered(false);
        renderScreen();
        return () => {
            setIsRendered(false);
            stopRenderScreen();
        };
    }, [mediaStream, screenMuted, userId]);
    useEffect(() => {
        if (!mediaStream || !isRendered) {
            return;
        }
        const timeoutId = setTimeout(() => mediaStream.updateSharingCanvasDimension(width, height), 0);
        return () => clearTimeout(timeoutId);
    }, [width, height, mediaStream, isRendered]);
    return _jsx("canvas", { className: classes.root, ref: canvasRef });
};
