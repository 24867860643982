import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallStyles } from "./Call.styles";
import { Box, IconButton, MuiArrowBackIcon, Typography, MuiFlipCameraIosIcon, MuiVolumeUpIcon, MuiVolumeOffIcon } from "@toolkit/ui";
import { MeetingDuration } from "./MeetingDuration";
import { MeetingMenu } from "./MeetingMenu";
import { useCallMenuHook } from "./useCallMenuHook";
import { MeetingSidebar } from "./MeetingSidebar";
import { MeetingView } from "./MeetingView";
import { ParticipantTakePhoto } from "./ParticipantTakePhoto";
import { GuestInvite } from "./GuestInvite";
export const MeetingScreen = ({ call, renderDoctorConsole, invitationLink, onGoBack }) => {
    const menu = useCallMenuHook({ call, invitationLink, isDoctorConsolEnabled: !!renderDoctorConsole });
    const { classes } = useCallStyles();
    const { isDoctorConsoleOpen, isTakePhotoOpen, isGuestInviteOpen, onToggleSpeaker, onToggleTakePhoto, onToggleGuestInvite } = menu;
    const { state, muteSpeaker, flipCamera: handleFlipCamera } = call;
    const { speakerMuted } = state;
    return (_jsxs(Box, { className: classes.root, children: [isDoctorConsoleOpen && renderDoctorConsole && renderDoctorConsole(), _jsxs(Box, { className: classes.container, children: [_jsxs(Box, { className: classes.header, children: [_jsx(Box, { children: onGoBack && (_jsx(IconButton, { color: 'inherit', onClick: onGoBack, children: _jsx(MuiArrowBackIcon, {}) })) }), _jsxs(Box, { sx: { textAlign: "center" }, children: [_jsx(Typography, { className: classes.headerTitle, children: call.title }), _jsx(Typography, { sx: { fontSize: 12 }, children: _jsx(MeetingDuration, { call: call }) })] }), _jsxs(Box, { children: [muteSpeaker && (_jsx(IconButton, { color: 'inherit', onClick: onToggleSpeaker, children: !speakerMuted ? _jsx(MuiVolumeUpIcon, {}) : _jsx(MuiVolumeOffIcon, {}) })), handleFlipCamera && (_jsx(IconButton, { color: 'inherit', onClick: handleFlipCamera, children: _jsx(MuiFlipCameraIosIcon, {}) }))] })] }), _jsx(Box, { className: classes.body, children: _jsx(MeetingView, { call: call }) }), _jsx(MeetingMenu, Object.assign({ call: call }, menu))] }), _jsx(MeetingSidebar, Object.assign({ call: call }, menu)), isTakePhotoOpen && _jsx(ParticipantTakePhoto, { call: call, onClose: onToggleTakePhoto }), isGuestInviteOpen && _jsx(GuestInvite, { call: call, onClose: onToggleGuestInvite })] }));
};
