var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from "@toolkit/i18n";
import { useCallback, useState } from "react";
import { useChatDraft, useChats, useChatSender } from "../ChatProvider";
import { useSendChatMessageMutation } from "../../gql/mutations";
import { useAddToast } from "@toolkit/ui";
import { getMediaLink, useFileUpload } from "@toolkit/core";
export const useChatInput = (chatGroupId) => {
    const { failed } = useAddToast();
    const { t } = useTranslation();
    const { draft, updateDraft, clearDraft } = useChatDraft(chatGroupId);
    const { accessToken } = useChatSender();
    const [uploading, setUploading] = useState(false);
    const [sendChatMessageMutation, { loading }] = useSendChatMessageMutation();
    const { fetchUploadFile } = useFileUpload({ token: accessToken });
    const { addNewChat } = useChats();
    const submitAttachment = useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ file, type }) {
        try {
            setUploading(true);
            const fileName = yield fetchUploadFile({ files: [file] });
            yield submitDraft({
                attachments: [
                    {
                        url: getMediaLink(fileName),
                        type: type,
                        name: fileName,
                    },
                ],
            });
        }
        catch (error) {
            console.error(error);
            failed(t("An error has occurred, please try again later."));
        }
        finally {
            setUploading(false);
        }
    }), []);
    const updateDraftText = useCallback((message) => {
        updateDraft(Object.assign(Object.assign({}, (draft || {})), { message }));
    }, [draft, updateDraft]);
    const submitDraft = useCallback(
    // eslint-disable-next-line max-statements
    (value) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            value && updateDraft(value);
            if ((!value && !draft) || !chatGroupId) {
                return;
            }
            const input = Object.assign(Object.assign({}, (value || draft)), { chatGroupId });
            const response = yield sendChatMessageMutation({ variables: { input } });
            if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.sendChatMessage) {
                clearDraft();
                addNewChat((_b = response.data) === null || _b === void 0 ? void 0 : _b.sendChatMessage);
            }
        }
        catch (e) {
            console.error(e);
            failed(t("An error has occurred, please try again later."));
        }
    }), [addNewChat, chatGroupId, clearDraft, draft, failed, sendChatMessageMutation, t, fetchUploadFile]);
    return {
        enabled: !!chatGroupId,
        loading: loading || uploading,
        draft,
        submitAttachment,
        submitDraft,
        updateDraft,
        updateDraftText,
        clearDraft,
    };
};
