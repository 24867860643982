var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAddSnapshotToMeetingMutation } from "../../gql/mutations";
import { getMediaLink, useFileUpload } from "@toolkit/core";
import { CallContext } from "./CallContext";
export const useParticipantTakePhoto = ({ call, participant }) => {
    const [snapshotFile, setSnapshotFile] = useState(null);
    const [addSnapshotToMeetingMutation] = useAddSnapshotToMeetingMutation();
    const snapshotUrl = useMemo(() => (snapshotFile ? URL.createObjectURL(snapshotFile) : null), [snapshotFile]);
    const { accessToken } = useContext(CallContext);
    const { fetchUploadFile } = useFileUpload({ token: accessToken });
    const participantId = participant === null || participant === void 0 ? void 0 : participant.id;
    const userIdentity = participant === null || participant === void 0 ? void 0 : participant.userIdentity;
    const takeSnapshot = useCallback(() => {
        var _a, _b;
        if (!participantId) {
            return null;
        }
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) {
            return null;
        }
        const viewId = `participant_view_${participant.id}`;
        const video = document.querySelector(`#${viewId} video, #${viewId} canvas`);
        if (!video) {
            return null;
        }
        const source = {
            video,
            width: (_a = video.clientWidth) !== null && _a !== void 0 ? _a : video.width,
            height: (_b = video.clientHeight) !== null && _b !== void 0 ? _b : video.height,
        };
        canvas.width = Math.max(source.width, source.width < source.height ? 480 : 640);
        canvas.height = (canvas.width * source.height) / source.width;
        ctx.drawImage(source.video, 0, 0, canvas.width, canvas.height);
        return new Promise(r => {
            canvas.toBlob(blob => {
                setSnapshotFile(blob);
                r(blob);
            }, "image/png");
        });
    }, [participantId]);
    const saveSnapshot = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            if (!snapshotFile || !call.callId) {
                return;
            }
            const file = new File([snapshotFile], `snapshot_${call.callId}_${userIdentity}.png`, { type: "image/png" });
            const fileName = yield fetchUploadFile({ files: [file] });
            const response = yield addSnapshotToMeetingMutation({
                variables: {
                    input: {
                        meetingId: call.callId,
                        snapshot: getMediaLink(fileName),
                    },
                },
            });
            return (_a = response.data) === null || _a === void 0 ? void 0 : _a.addSnapshotToMeeting;
        }
        catch (e) {
            console.error(e);
        }
    }), [addSnapshotToMeetingMutation, call.callId, userIdentity, snapshotFile]);
    useEffect(() => {
        if (!participantId) {
            return;
        }
        takeSnapshot();
    }, [takeSnapshot, participantId]);
    return { snapshot: snapshotUrl, takeSnapshot, saveSnapshot };
};
