import { DoctorCardLayout } from "@/components";
import { DoctorAvatar } from "@/components/DoctorAvatar/DoctorAvatar";
import { getNotificationAppointmentStatus } from "@/domains/notifications/components/AppointmentNotification/utils";
import { PaymentStatus } from "@/schema/types";
import { displayStringOfArray } from "@/utils";
import { appointmentTypeOptionsMap, requestedByOptionsMap } from "@health/enum-options";
import { ExtractNodeType } from "@toolkit/apollo";
import { formatUserNameFull } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, Card, Typography } from "@toolkit/ui";
import { FC } from "react";
import { AppointmentsHistoryQuery } from "../../gql";
import { AppointmentHistoryTabsEnum } from "../../types/types";
import { safeJsonParseWithDefault } from "../../utils";
import { formatAppointmentTimeRange } from "../../utils/confirm-doctor-time.utils";
import { AppointmentHistoryCardActionsSection } from "../AppointmentHistoryCardActionsSection/AppointmentHistoryCardActionsSection";
import { useAppointmentHistoryCardStyle } from "./AppointmentHistoryCardStyle";

type AppointmentHistoryCardProp = {
  appointment: ExtractNodeType<AppointmentsHistoryQuery>;
  variant: AppointmentHistoryTabsEnum;
};

export const AppointmentHistoryCard: FC<AppointmentHistoryCardProp> = props => {
  const { appointment, variant } = props;
  const { t } = useTranslation("consumer");
  const doctorUser = appointment?.doctor?.user;
  const doctorName = formatUserNameFull(doctorUser);
  const { classes, theme } = useAppointmentHistoryCardStyle();
  const vendor = appointment?.vendor;
  const notificationStatusMapper = getNotificationAppointmentStatus(theme, true);
  const requestedTimeSlots = appointment?.requestedTimeSlots?.length
    ? safeJsonParseWithDefault(appointment?.requestedTimeSlots[0] || "", {
        startTime: "",
        endTime: "",
      })
    : undefined;

  const specialization = appointment?.doctor?.specializations
    ?.map(sp => pickLocalizedValue<string>(sp?.display!, sp?.arabicDisplay!))
    ?.filter(e => typeof e === "string");

  return (
    <Card className={classes.root}>
      <DoctorCardLayout
        classes={{
          card: classes.card,
          textColumn: classes.infoContainer,
          avatarsContainer: classes.avatarsContainer,
        }}
        infoNode={
          <>
            <Typography className={classes.doctorName}>{doctorName}</Typography>
            {appointment?.status && (
              <Typography className={classes.appointmentStatus} color={notificationStatusMapper[appointment?.status]?.color}>
                {notificationStatusMapper[appointment?.status]?.label}
              </Typography>
            )}
            <Typography className={classes.appointmentDateRange}>
              {variant === AppointmentHistoryTabsEnum.requested
                ? requestedTimeSlots && formatAppointmentTimeRange(requestedTimeSlots?.startTime, requestedTimeSlots?.endTime)
                : formatAppointmentTimeRange(appointment?.confirmedStartTime, appointment?.confirmedEndTime)}
            </Typography>
            <Typography className={classes.appointmentDateRange}>
              {appointment?.type &&
                t("{{appointmentType}} Appointment", { appointmentType: appointmentTypeOptionsMap[appointment?.type]?.label })}
            </Typography>
            <Typography className={classes.vendorName}>
              {pickLocalizedValue(appointment?.vendor?.name, appointment?.vendor?.nameAr)}
            </Typography>
            {appointment?.requestedBy && (
              <Typography alignSelf={"flex-start"}>
                {t("Requested By")}: {requestedByOptionsMap[appointment?.requestedBy]?.label}
              </Typography>
            )}
            {appointment?.paymentStatus === PaymentStatus.PaymentSucceeded && (
              <Typography className={classes.paymentStatus}>{"Paid"}</Typography>
            )}
            <Typography className={classes.doctorSpecializations}>{specialization && displayStringOfArray(specialization)}</Typography>
          </>
        }
        doctorAvatarNode={
          <DoctorAvatar gender={doctorUser?.gender || undefined} doctorName={doctorName} imageUrl={doctorUser?.photo || undefined} />
        }
        downAvatarNode={vendor?.logo ? <Avatar className={classes.vendorAvatar} title={vendor?.name} src={vendor?.logo} /> : null}
      />
      <AppointmentHistoryCardActionsSection appointment={appointment} />
    </Card>
  );
};
