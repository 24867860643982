import { makeStyles } from "@toolkit/ui";
export const useStyles = makeStyles()({
    root: {
        position: "relative",
        minWidth: 280,
    },
    input: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 70,
        padding: "8px 12px",
    },
    textField: {
        fontSize: 16,
        flex: 1,
        maxHeight: 100,
        overflow: "hidden",
        position: "relative",
        ".MuiInputBase-multiline": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        ".MuiInputBase-inputMultiline": {
            paddingBottom: 0,
        },
        ".MuiInputAdornment-positionStart": {
            marginTop: "0 !important",
        },
    },
});
