var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useZoom } from "./useZoom";
import { ZoomView } from "./ZoomView";
export const ZoomPublisherView = (_a) => {
    var { call } = _a, props = __rest(_a, ["call"]);
    const { videoMuted } = call.state;
    const participant = call.currentParticipant;
    const { mediaStream } = useZoom();
    const renderSelfView = !videoMuted;
    if (!renderSelfView || !participant || !mediaStream) {
        return null;
    }
    return _jsx(ZoomView, Object.assign({ participant: participant }, props));
};
