import { useCommonStyles } from "@/domains/appointment/containers/CommonContainer.styles";
import { NotificationType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button } from "@toolkit/ui";
import { NotificationsTabs } from "../../components";
import { BaseNotificationsList } from "../../components/BaseNotificationsList/BaseNotificationsList";
import { useNotificationsListContainerStyle } from "./NotificationsListContainerStyle";
import { useMakeAllNotificationsRead } from "./useMarkAllNotifications";

export const NotificationListContainer: React.FC = () => {
  const { classes } = useCommonStyles();
  const { classes: listClasses } = useNotificationsListContainerStyle();
  const { t } = useTranslation("consumer");
  const { isSubmitting, handleMarkAllNotificationsAsSeen } = useMakeAllNotificationsRead();

  return (
    <Box className={classes.pageContainer}>
      <Box className={listClasses.header}>
        <NotificationsTabs />
      </Box>
      <Box className={classes.pageContent} display='contents'>
        <BaseNotificationsList
          filter={{
            excludeTypes: [
              NotificationType.HealthProgramVisitStatusChanged,
              NotificationType.OrderStatusUpdate,
              NotificationType.MedicalMessage,
            ],
          }}
        />
      </Box>
      <Box className={classes.footer}>
        <Button onClick={handleMarkAllNotificationsAsSeen} variant='text' disabled={isSubmitting} className={listClasses.button}>
          {t("Mark all as read")}
        </Button>
      </Box>
    </Box>
  );
};
