import { EmptyDoctorsIcon, EmptyMessageLayout } from "@/components";
import useContainerHeight from "@/hooks/useContainerHeight";
import { routesPaths } from "@/routing/routesPaths";
import { AppointmentFilterInput, AppointmentSortingInput } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomInfiniteScroll } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AppointmentHistoryTabsEnum } from "../../types/types";
import { getDoctorInfo } from "../../utils";
import AppointmentDoctorCardSkeleton from "../AppointmentDoctorCard/DoctorCardSkeleton";
import { AppointmentHistoryCard } from "../AppointmentHistoryCard/AppointmentHistoryCard";
import { useAppointmentsHistoryListStyle } from "./AppointmentsHistoryListStyle";
import { useGetAppointmentHistoryListData } from "./useGetAppointmentHistoryListData";

type AppointmentsHistoryListProps = {
  filters: AppointmentFilterInput;
  sortBy: AppointmentSortingInput;
  message: string;
  subMessage: string;
  variant: AppointmentHistoryTabsEnum;
};

export const AppointmentsHistoryList: FC<AppointmentsHistoryListProps> = props => {
  const { filters, sortBy, message, subMessage, variant } = props;
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();

  const { classes } = useAppointmentsHistoryListStyle();

  const { containerRef, containerHeight } = useContainerHeight();

  const { appointments, isLoading, fetchMoreData, hasMore, handleRefresh } = useGetAppointmentHistoryListData({ filters, sortBy });

  const handleNavigateToAppointment = () => {
    navigate(routesPaths.appointments);
  };
  return (
    <>
      {isLoading &&
        !appointments.length &&
        Array(3)
          .fill(1)
          .map((_, index) => (
            <Box marginInline={1} key={index}>
              <AppointmentDoctorCardSkeleton />
            </Box>
          ))}

      <Box ref={containerRef} className={classes.content}>
        <CustomInfiniteScroll
          dataLength={Number(appointments?.length) || 1}
          onFetchMore={fetchMoreData}
          hasMore={hasMore}
          loader={<AppointmentDoctorCardSkeleton classes={{ card: classes.emptyCard }} />}
          height={(containerHeight || 10) - 15}
          onRefresh={handleRefresh}
        >
          {
            <>
              {!isLoading && !appointments.length && (
                <Box className={classes.emptyPageContainer}>
                  <EmptyMessageLayout
                    icon={<EmptyDoctorsIcon />}
                    message={message}
                    subMessage={subMessage}
                    action={
                      <Button onClick={handleNavigateToAppointment} className={classes.scheduleButton}>
                        {t("Schedule an Appointment")}
                      </Button>
                    }
                  />
                </Box>
              )}

              {appointments?.length > 0 &&
                appointments?.map((appointment, index) => {
                  const doctor = appointment?.doctor ? getDoctorInfo(appointment.doctor) : null;
                  if (!doctor) return null;
                  return <AppointmentHistoryCard variant={variant} key={appointment?.id + "-" + index} appointment={appointment} />;
                })}
            </>
          }
        </CustomInfiniteScroll>
      </Box>
    </>
  );
};
