import { EmptyMessageLayout, EmptyWalletIcon, WalletIcon } from "@/components";
import { useTranslation } from "@/i18n/i18n.config";
import { PaymentOperationLog } from "@/schema/types";
import { Box, Grid, Typography } from "@toolkit/ui";
import { TransactionCard } from "../TransactionCard/TransactionCard";
import { TransactionList } from "../TransactionList/TransactionList";
import { useStyles } from "./MyWalletContainerStyle";
import { useGetMyWallet } from "./useGetMyWallet";

export const MyWalletContainer: React.FC = () => {
  const { t } = useTranslation();
  const { classes, cx, theme } = useStyles();
  const {
    balance,
    defaultCurrency,
    onFetchMoreData,
    loading,
    paymentOperationLogs,
    handleRefresh,
    hasMore,
    containerRef,
    containerHeight,
  } = useGetMyWallet();
  return (
    <Box className={classes.container}>
      <Typography variant='h6' className={classes.title}>
        {t("My Wallet")}
      </Typography>

      <Box className={classes.root}>
        <Grid height='100%' className={"card__container"} container gap={2} alignItems='center' justifyContent='center'>
          <Grid item className={cx(classes.iconWrapper, "card__startIcon")}>
            <WalletIcon fill={theme.palette.common.white} />
          </Grid>
          <Grid item className={classes.textWrapper}>
            <Typography className={cx(classes.typographyTitle, "card__title")}>{t("Balance")}</Typography>
            <Typography className={cx(classes.typographySubtitle, "card__subtitle")}>{`${defaultCurrency} ${balance}`}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Typography className={cx(classes.overview, classes.text)}>{t("Transactions Overview")}</Typography>

      <Box ref={containerRef} className={classes.box} flex={1}>
        <TransactionList
          keyExtractor={(item: PaymentOperationLog) => item.id}
          paymentOperationLogs={paymentOperationLogs as PaymentOperationLog[]}
          containerHeight={containerHeight}
          hasMoreData={hasMore}
          handleFetchMoreData={onFetchMoreData}
          onRefresh={handleRefresh}
          loading={loading}
          emptyListComponent={
            <Box className={classes.emptyPageContainer}>
              <EmptyMessageLayout icon={<EmptyWalletIcon />} message={t("No transactions found")} />
            </Box>
          }
          renderItem={item => {
            return <TransactionCard paymentOperationLog={item as PaymentOperationLog} />;
          }}
        />
      </Box>
    </Box>
  );
};
