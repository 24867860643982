import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";
import { isRTL } from "@toolkit/i18n";

interface LongArrowIconProps extends SvgIconProps {
  direction?: "up" | "down" | "left" | "right";
  disableAutoRotate?: boolean;
}

const getRotation = (direction: LongArrowIconProps["direction"]) => {
  const isRtl = isRTL();

  switch (direction) {
    case "up":
      return "rotate(-90deg)";
    case "down":
      return "rotate(90deg)";
    case "right":
      return isRtl ? "rotate(0deg)" : "rotate(180deg)";
    case "left":
    default:
      return isRtl ? "rotate(180deg)" : "rotate(0deg)";
  }
};

export const LongArrowIcon: React.FC<LongArrowIconProps> = ({ direction = "left", ...props }) => {
  const theme = useTheme();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width='18.372'
      height='14.169'
      viewBox='0 0 18.372 14.169'
      sx={{ transform: getRotation(direction) }}
      {...props}
    >
      <path
        id='icons8-right'
        d='M11.06,6.781l1.1,1.1L6.954,13.1H22.372v1.537H6.954l5.211,5.211-1.1,1.1L4.528,14.418,4,13.866l.528-.552Z'
        transform='translate(-4 -6.781)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
