import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CirclePlusIcon, CustomTable, FormPhoneInput, FormTextField, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCallback } from "react";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
export const GuestInviteForm = () => {
    const { t } = useTranslation();
    const { getValues } = useFormContext();
    const { append, remove } = useFieldArray({ name: "contacts" });
    const methods = useForm({
        context: null,
        resolver: zodResolver(z
            .object({
            phoneNumber: z.string().refine(isValidPhoneNumber),
            email: z.string().email(),
        })
            .partial()),
        defaultValues: {
            phoneNumber: "",
            email: "",
        },
    });
    const { reset, handleSubmit, formState } = methods;
    const handleAddContact = useCallback(() => {
        const { phoneNumber, email } = methods.getValues();
        if (phoneNumber && !formState.errors.phoneNumber && isValidPhoneNumber(phoneNumber)) {
            append({ phoneNumber });
            reset();
        }
        if (email && !formState.errors["email"]) {
            append({ email });
            reset();
        }
    }, [append, formState.errors, methods, reset]);
    const handleRemoveContact = useCallback((_, index) => {
        remove(index);
    }, [remove]);
    return (_jsxs(FormProvider, Object.assign({}, methods, { children: [_jsxs(Grid, { container: true, spacing: 2, mb: 2, children: [_jsx(Grid, { item: true, sm: 5, xs: 12, children: _jsx(FormPhoneInput, { fullWidth: true, label: t("Phone number"), name: 'phoneNumber' }) }), _jsx(Grid, { item: true, sm: 5, xs: 12, children: _jsx(FormTextField, { fullWidth: true, label: t("Email"), name: 'email' }) }), _jsx(Grid, { item: true, sm: 2, xs: 12, children: _jsxs(Button, { fullWidth: true, onClick: handleSubmit(handleAddContact, handleAddContact), children: [_jsx(CirclePlusIcon, { fontSize: 'small', sx: { marginInlineEnd: 1 } }), " ", t("Add")] }) })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx(CustomTable, { data: getValues("contacts"), minHeight: 300, isDeleteVisible: true, hasFooter: false, onDeleteRow: handleRemoveContact, columns: [
                        {
                            key: "contact",
                            header: t("Guest List"),
                            accessor: (s) => s.phoneNumber || s.email,
                        },
                    ] }) })] })));
};
