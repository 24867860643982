var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MediaRecorder, register } from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";
import { useTranslation } from "@toolkit/i18n";
export function useAudioRecorder() {
    const { t } = useTranslation();
    const [recordDuration, setRecordDuration] = useState(0);
    const [isRecording, setRecording] = useState(false);
    const [recorder, setRecorder] = useState();
    const chunks = useRef([]);
    const isConnected = useRef(false);
    const recorderRef = useRef(recorder);
    // eslint-disable-next-line max-statements
    const startRecording = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const audioStream = yield navigator.mediaDevices.getUserMedia({ audio: true });
        if (!audioStream) {
            return;
        }
        try {
            if (!isConnected.current) {
                yield register(yield connect());
                isConnected.current = true;
            }
        }
        catch (error) {
            console.error(error);
        }
        let mimeType = undefined;
        if (MediaRecorder.isTypeSupported("audio/wav")) {
            mimeType = "audio/wav";
        }
        else if (MediaRecorder.isTypeSupported("audio/mp4")) {
            mimeType = "audio/mp4";
        }
        else if (MediaRecorder.isTypeSupported("audio/webm")) {
            mimeType = "audio/webm";
        }
        const mediaRecorder = new MediaRecorder(audioStream, mimeType ? { mimeType } : undefined);
        chunks.current = [];
        mediaRecorder.ondataavailable = ev => chunks.current.push(ev.data);
        mediaRecorder.onstart = () => setRecording(true);
        mediaRecorder.onstop = () => {
            chunks.current = [];
            audioStream.getTracks().map(track => track.stop());
            setRecording(false);
        };
        mediaRecorder.start();
        setRecorder(mediaRecorder);
        recorderRef.current = mediaRecorder;
    }), [recorder, t]);
    const stopRecording = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => {
            try {
                if (!recorder) {
                    resolve(undefined);
                    return;
                }
                const { onstop } = recorder;
                recorder.onstop = (e) => {
                    const data = chunks.current;
                    onstop === null || onstop === void 0 ? void 0 : onstop(e);
                    resolve(data.length ? new Blob(data, { type: data[0].type }) : undefined);
                };
                recorder.stop();
            }
            finally {
                setRecording(false);
                setRecordDuration(0);
            }
        });
    }), [recorder, recordDuration]);
    const cancelRecording = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            (_a = recorderRef.current) === null || _a === void 0 ? void 0 : _a.stop();
        }
        finally {
            setRecording(false);
            setRecordDuration(0);
        }
    }), []);
    useEffect(() => {
        if (!recorder) {
            return;
        }
        return () => {
            if (recorder.state === "recording") {
                recorder.stop();
            }
        };
    }, []);
    useEffect(() => {
        if (isRecording) {
            const interval = setInterval(() => setRecordDuration(r => r + 1), 1000);
            return () => clearInterval(interval);
        }
        setRecordDuration(0);
    }, [isRecording]);
    const recordDurationText = useMemo(() => {
        const minutes = Math.floor(recordDuration / 60);
        const seconds = Math.floor(recordDuration % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }, [recordDuration]);
    return {
        recordEnabled: true,
        isRecording,
        recordDuration,
        recordDurationText,
        startRecording,
        stopRecording,
        cancelRecording,
    };
}
