import { useEffect, useRef, useState } from "react";
const useCountDown = (duration, onEnd) => {
    const onEndRef = useRef(onEnd);
    useEffect(() => {
        onEndRef.current = onEnd;
    }, [onEnd]);
    const [value, setValue] = useState(null);
    useEffect(() => {
        if (duration === null) {
            return;
        }
        setValue(duration);
        const intervalId = setInterval(() => setValue(d => (d && d > 0 ? d - 1 : d)), 1000);
        return () => clearInterval(intervalId);
    }, [duration]);
    useEffect(() => {
        var _a;
        if (value === 0) {
            (_a = onEndRef.current) === null || _a === void 0 ? void 0 : _a.call(onEndRef);
        }
    }, [value]);
    return { countDown: value === null ? null : value.toFixed(0) };
};
export default useCountDown;
