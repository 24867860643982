import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, CircularProgress, CustomIcon, IconButton, Typography, MuiMicIcon, MuiMicOffIcon, MuiVideocamIcon, MuiVideocamOffIcon, } from "@toolkit/ui";
import { useCallStyles } from "./Call.styles";
import { useTranslation } from "@toolkit/i18n";
import { useJoinScreenStyles } from "./JoinScreen.styles";
import { useCallHook } from "./useCallHook";
import { useMemo } from "react";
export const JoinScreen = ({ call, onClose }) => {
    var _a;
    const { t } = useTranslation();
    const { classes: callClasses, theme } = useCallStyles();
    const { classes } = useJoinScreenStyles();
    const { audioMuted, videoMuted } = call.state;
    const displayName = (_a = call.currentParticipant) === null || _a === void 0 ? void 0 : _a.displayName;
    const { onToggleAudio, onToggleVideo, handleJoin, isJoining } = useCallHook({ call });
    const avatar = useMemo(() => (displayName || "#")
        .split(" ")
        .map(v => v[0])
        .slice(0, 2)
        .join("")
        .toUpperCase(), [displayName]);
    return (_jsx(Box, { className: callClasses.root, children: _jsxs(Box, { className: callClasses.container, children: [_jsxs(Box, { className: callClasses.header, children: [_jsx(Box, {}), _jsx(Typography, { className: callClasses.headerTitle, children: call.title }), onClose && (_jsx(IconButton, { size: 'small', onClick: onClose, children: _jsx(CustomIcon, { color: theme.palette.common.white, icon: 'xAlert' }) }))] }), _jsxs(Box, { className: classes.root, children: [_jsx(Box, { mt: 1, className: classes.mediaView, children: _jsx(Typography, { fontSize: 40, children: avatar }) }), _jsxs(Box, { className: classes.mediaControls, children: [_jsxs(Button, { variant: 'text', onClick: onToggleAudio, className: classes.mediaButton, children: [!audioMuted ? _jsx(MuiMicIcon, {}) : _jsx(MuiMicOffIcon, {}), !audioMuted ? t("Mute") : t("Unmute")] }), _jsxs(Button, { variant: 'text', onClick: onToggleVideo, className: classes.mediaButton, children: [!videoMuted ? _jsx(MuiVideocamIcon, {}) : _jsx(MuiVideocamOffIcon, {}), !videoMuted ? t("Stop video") : t("Start video")] })] }), _jsxs(Box, { mt: 3, children: [_jsx(Button, { fullWidth: true, className: classes.joinButton, disabled: !call.callId || isJoining, onClick: handleJoin, children: isJoining ? _jsx(CircularProgress, { size: 20, sx: { color: theme.palette.common.white } }) : t("Join") }), _jsx(Typography, { mt: 2, children: t("By clicking Join now I agree to the informed consent and terms of use") })] })] })] }) }));
};
