import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { useEffect, useRef } from "react";
import { useChatPlayer } from "../ChatProvider";
import { Box, CircularProgress, IconButton, makeStyles, MuiPauseCircleIcon, MuiPlayCircleIcon, Typography, useWaveSurferAudio, } from "@toolkit/ui";
const useStyles = makeStyles()(() => ({
    root: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        padding: "8px",
        userSelect: "none",
        width: "100%",
        gap: 8,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        marginHorizontal: 8,
        overflow: "hidden",
        "& .MuiTypography-root": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
}));
export const ChatViewMediaAudio = ({ media }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { url } = media;
    const { update: handleOnPlay } = useChatPlayer();
    const { waveformRef, duration, currentTime, loading, playing, handlePause: pause, handlePlayPause, formatTime, } = useWaveSurferAudio({ url });
    const playerRef = useRef({ pause });
    playerRef.current.pause = pause;
    useEffect(() => {
        if (playing) {
            handleOnPlay(playerRef.current);
        }
    }, [playing, handleOnPlay]);
    return (_jsxs(Box, { className: classes.root, children: [_jsxs(IconButton, { disabled: loading, onClick: handlePlayPause, sx: { p: 0 }, children: [loading && _jsx(CircularProgress, { size: 36 }), !loading && (_jsx(_Fragment, { children: playing ? (_jsx(MuiPauseCircleIcon, { sx: { fontSize: 42 }, color: "primary" })) : (_jsx(MuiPlayCircleIcon, { sx: { fontSize: 42 }, color: "primary" })) }))] }), _jsxs(Box, { className: classes.content, children: [_jsx(Typography, { variant: 'subtitle1', children: t("Voice message") }), _jsxs(Typography, { variant: 'caption', color: 'textSecondary', children: [formatTime(currentTime || 0), duration ? ` / ${formatTime(duration)}` : ""] })] }), _jsx(Box, { ref: waveformRef, sx: { flex: 1 } })] }));
};
