import { DoctorAvatar } from "@/components/DoctorAvatar/DoctorAvatar";
import { UserGender } from "@/schema/types";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { useCallDoctorAvatarStyle } from "./CallDoctorAvatarStyle";

type CallDoctorAvatarProps = {
  src: string;
  doctorName?: string;
  gender?: UserGender | null;
};

export const CallDoctorAvatar: FC<CallDoctorAvatarProps> = props => {
  const { src, doctorName, gender } = props;
  const { classes } = useCallDoctorAvatarStyle();
  return (
    <Box className={classes.root}>
      <Box className={classes.pulse}></Box>
      <DoctorAvatar className={classes.avatar} doctorName={doctorName} gender={gender!} imageUrl={src!} />
    </Box>
  );
};
