import { PhoneReceiverIcon, ReasonsBaseModal, ReasonsForm } from "@/components";
import { routesPaths } from "@/routing/routesPaths";
import { AffectedType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, IconButton, PulseConnection, Typography, useTheme } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useVisitStartingNowCard } from "./useVisitStartingNowCard";
import { useStyle } from "./VisitStartingNowCardStyle";

export const VisitStartingNowCard: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyle();
  const theme = useTheme();
  const location = useLocation();
  const blockedPagesRoutes = [routesPaths.visitCall];
  const isBlockedPage = blockedPagesRoutes.includes(location.pathname) || location.pathname.includes(routesPaths.visitCall);
  const { t } = useTranslation("consumer");

  const {
    activeCall,
    isCallEndSubmitting,
    handleDecline,
    open,
    handleClose,
    reasonsModalRf,
    handleCancelVisit,
    isLoadingCancel,
    handleConfirmCancel,
    handleCardClick,
  } = useVisitStartingNowCard({ isBlockedPage });

  return (
    <Box className={classes.root}>
      {!isBlockedPage && activeCall?.id && (
        <Box className={classes.callBoxRoot} onClick={handleCardClick}>
          <Box className={classes.callBox}>
            <IconButton className={classes.iconButton}>
              <PulseConnection width='55px' height='55px' />
            </IconButton>
            <Box className={classes.textBox} color={theme.palette.success[1100]}>
              <Typography className={classes.mainText}>
                {activeCall.appointment?.id ? t("New Call Request") : t("Visit starting now")}
              </Typography>
              <Typography className={classes.subText}>{t("Tap to join")}</Typography>
            </Box>
            <Box className={classes.rightIconBox}>
              <IconButton disabled={isCallEndSubmitting} onClick={handleDecline} className={classes.phoneIconButton}>
                <PhoneReceiverIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
      {children}
      <Outlet />
      <ReasonsBaseModal
        isConfirmDisabled={isLoadingCancel}
        handleConfirm={handleConfirmCancel}
        open={open}
        onClose={handleClose}
        title={t("Cancellation Reasons")}
      >
        <ReasonsForm affectedType={AffectedType.Visit} type='cancellation' ref={reasonsModalRf} onSubmit={handleCancelVisit} skip={!open} />
      </ReasonsBaseModal>
    </Box>
  );
};
