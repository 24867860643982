import { useCallback, useContext, useEffect } from "react";
import { ChatContext } from "./ChatProvider";
export const useChatDraft = (chatGroupId) => {
    const { draft, updateDraft } = useContext(ChatContext);
    const clearDraft = useCallback(() => updateDraft(undefined), [updateDraft]);
    useEffect(() => {
        clearDraft();
    }, [chatGroupId, clearDraft]);
    return { draft, updateDraft, clearDraft };
};
