import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamDisEnrollMutationVariables = Types.Exact<{
  input: Types.GuidedCarePatientTeamEnrollmentInput;
}>;


export type GuidedCareTeamDisEnrollMutation = { __typename?: 'Mutation', disEnrollPatientToTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCRUD', providerGuidedCareHealthProgramTeamErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTeamDisEnrollDocument = gql`
    mutation GuidedCareTeamDisEnroll($input: GuidedCarePatientTeamEnrollmentInput!) {
  disEnrollPatientToTeam(input: $input) {
    providerGuidedCareHealthProgramTeamErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTeamDisEnrollMutationFn = Apollo.MutationFunction<GuidedCareTeamDisEnrollMutation, GuidedCareTeamDisEnrollMutationVariables>;

/**
 * __useGuidedCareTeamDisEnrollMutation__
 *
 * To run a mutation, you first call `useGuidedCareTeamDisEnrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamDisEnrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTeamDisEnrollMutation, { data, loading, error }] = useGuidedCareTeamDisEnrollMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareTeamDisEnrollMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTeamDisEnrollMutation, GuidedCareTeamDisEnrollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTeamDisEnrollMutation, GuidedCareTeamDisEnrollMutationVariables>(GuidedCareTeamDisEnrollDocument, options);
      }
export type GuidedCareTeamDisEnrollMutationHookResult = ReturnType<typeof useGuidedCareTeamDisEnrollMutation>;
export type GuidedCareTeamDisEnrollMutationResult = Apollo.MutationResult<GuidedCareTeamDisEnrollMutation>;
export type GuidedCareTeamDisEnrollMutationOptions = Apollo.BaseMutationOptions<GuidedCareTeamDisEnrollMutation, GuidedCareTeamDisEnrollMutationVariables>;