import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorAvailableTimeSlotsQueryVariables = Types.Exact<{
  doctorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  end?: Types.InputMaybe<Types.Scalars['Instant']['input']>;
  start?: Types.InputMaybe<Types.Scalars['Instant']['input']>;
}>;


export type DoctorAvailableTimeSlotsQuery = { __typename?: 'Query', availableTimeSlots?: Array<{ __typename?: 'TimeSlot', endTime?: any | null, startTime?: any | null } | null> | null };


export const DoctorAvailableTimeSlotsDocument = gql`
    query DoctorAvailableTimeSlots($doctorId: ID, $end: Instant, $start: Instant) {
  availableTimeSlots(doctorId: $doctorId, end: $end, start: $start) {
    endTime
    startTime
  }
}
    `;

/**
 * __useDoctorAvailableTimeSlotsQuery__
 *
 * To run a query within a React component, call `useDoctorAvailableTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorAvailableTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorAvailableTimeSlotsQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useDoctorAvailableTimeSlotsQuery(baseOptions?: Apollo.QueryHookOptions<DoctorAvailableTimeSlotsQuery, DoctorAvailableTimeSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorAvailableTimeSlotsQuery, DoctorAvailableTimeSlotsQueryVariables>(DoctorAvailableTimeSlotsDocument, options);
      }
export function useDoctorAvailableTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorAvailableTimeSlotsQuery, DoctorAvailableTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorAvailableTimeSlotsQuery, DoctorAvailableTimeSlotsQueryVariables>(DoctorAvailableTimeSlotsDocument, options);
        }
export function useDoctorAvailableTimeSlotsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DoctorAvailableTimeSlotsQuery, DoctorAvailableTimeSlotsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DoctorAvailableTimeSlotsQuery, DoctorAvailableTimeSlotsQueryVariables>(DoctorAvailableTimeSlotsDocument, options);
        }
export type DoctorAvailableTimeSlotsQueryHookResult = ReturnType<typeof useDoctorAvailableTimeSlotsQuery>;
export type DoctorAvailableTimeSlotsLazyQueryHookResult = ReturnType<typeof useDoctorAvailableTimeSlotsLazyQuery>;
export type DoctorAvailableTimeSlotsSuspenseQueryHookResult = ReturnType<typeof useDoctorAvailableTimeSlotsSuspenseQuery>;
export type DoctorAvailableTimeSlotsQueryResult = Apollo.QueryResult<DoctorAvailableTimeSlotsQuery, DoctorAvailableTimeSlotsQueryVariables>;