import { useSelectCall } from "./CallContext";
import { useMeetingQuery } from "../../gql/queries";
import { VisitStatus } from "../../schema/types";
import { useEffect, useState } from "react";
import { CallState } from "../ZoomVideo";
export const useMeetingInfo = (visit) => {
    const callId = visit === null || visit === void 0 ? void 0 : visit.callId;
    const { data, loading, error, refetch } = useMeetingQuery({
        skip: !callId,
        variables: callId ? { meetingId: callId } : undefined,
    });
    const call = useSelectCall(data === null || data === void 0 ? void 0 : data.meeting);
    const { dropCall, updateCallStatus } = call || {};
    const callState = call === null || call === void 0 ? void 0 : call.state.callState;
    const [joined, setJoined] = useState(callState === CallState.InCall);
    const visitStatus = visit === null || visit === void 0 ? void 0 : visit.status;
    useEffect(() => {
        switch (callState) {
            case CallState.InCall: {
                setJoined(true);
                break;
            }
            case CallState.Started:
            case CallState.NoCall: {
                setJoined(false);
                break;
            }
        }
    }, [callState]);
    useEffect(() => {
        if (callState === CallState.InCall && visitStatus !== VisitStatus.CallInProgress) {
            dropCall === null || dropCall === void 0 ? void 0 : dropCall();
        }
        if (callState === CallState.Dropped && visitStatus !== VisitStatus.CallInProgress) {
            updateCallStatus === null || updateCallStatus === void 0 ? void 0 : updateCallStatus(CallState.Ended);
        }
    }, [callState, visitStatus, dropCall, updateCallStatus]);
    return { call, joined, loading, error, handleRetry: refetch };
};
