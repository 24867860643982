/* eslint-disable @typescript-eslint/no-explicit-any */
import { NotificationType } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useAddToast } from "@toolkit/ui";
import React, { createContext, FC, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMarkNotificationAsSeenMutation } from "./gql";
import { routes } from "./routes";

type ISeenNotificationContext = {
  seenId: string;
  notificationId: string;
  notificationType: NotificationType | undefined;
  loadingSeen: boolean | undefined;
  handleMarkNotificationAsSeen: () => void;
  handleViewNotification: (data?: any) => void;
};

export const SeenNotificationContext = createContext<ISeenNotificationContext>({
  seenId: "",
  notificationId: "",
  notificationType: undefined,
  loadingSeen: undefined,
  handleMarkNotificationAsSeen: () => {},
  handleViewNotification: () => {},
});

export const SeenNotificationProvider: FC<{ children: ReactNode; id: string; type: NotificationType; isSeen?: boolean }> = ({
  children,
  id,
  type,
  isSeen,
}) => {
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const [seenId, setSeenId] = React.useState<string>("");

  const [fetchMarkNotificationAsSeen, { loading: loadingSeen }] = useMarkNotificationAsSeenMutation({
    onCompleted: request => {
      const errors = request?.markNotificationAsSeen?.errors;
      if (errors?.length) {
        failed(errors?.map(error => `${error?.field || ""},\r\n ${error?.message || ""}\r\n`).join(", ") || "");
      }
    },
    onError: ({ graphQLErrors }) => {
      const error = graphQLErrors?.filter(error => error?.message);
      const message = graphQLErrors ? formatMessageErrors(error) : "";
      failed(message);
    },
  });

  const handleMarkNotificationAsSeen = () => {
    if (!isSeen) {
      setSeenId(id);
      fetchMarkNotificationAsSeen({
        variables: {
          markNotificationAsSeenId: id,
        },
      });
    }
  };

  const handleViewNotification = (data?: any) => {
    if (!isSeen) {
      setSeenId(id);
      fetchMarkNotificationAsSeen({
        variables: {
          markNotificationAsSeenId: id,
        },
      });
    }

    if (type === NotificationType.HealthProgramAppointmentStatusChanged) {
      navigate(`${routes.appointmentNotification.getRouteWithParams({ appointmentId: data?.id })}`);
    }
  };

  return (
    <SeenNotificationContext.Provider
      value={{
        seenId,
        notificationType: type,
        notificationId: id,
        loadingSeen,
        handleMarkNotificationAsSeen,
        handleViewNotification,
      }}
    >
      {children}
    </SeenNotificationContext.Provider>
  );
};

export const useSeenNotificationContext = () => {
  return useContext(SeenNotificationContext);
};
