import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CallView } from "./types";
import { Box, MuiMicIcon, MuiMicOffIcon, MuiScreenShareIcon, MuiVideocamIcon, MuiVideocamOffIcon, Typography } from "@toolkit/ui";
import { ChatAvatar } from "@health/chat";
import { useStyles } from "./MeetingParticipantView.styles";
import { useTranslation } from "@toolkit/i18n";
export const MeetingParticipantView = ({ participant }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { videoMuted, screenMuted, audioMuted } = participant.state;
    const showVideo = (!videoMuted || !screenMuted) && !participant.isCurrentUser;
    return (_jsxs(Box, { id: `participant_view_${participant.id}`, className: classes.root, children: [showVideo && _jsx(CallView, { participant: participant, sharing: !screenMuted }), !showVideo && _jsx(ChatAvatar, { value: participant.info, size: 140 }), _jsxs(Box, { className: classes.info, children: [!audioMuted ? _jsx(MuiMicIcon, { fontSize: 'inherit' }) : _jsx(MuiMicOffIcon, { fontSize: 'inherit' }), !videoMuted ? _jsx(MuiVideocamIcon, { fontSize: 'inherit' }) : _jsx(MuiVideocamOffIcon, { fontSize: 'inherit' }), !screenMuted && _jsx(MuiScreenShareIcon, { fontSize: 'inherit' }), _jsx(Typography, { variant: 'body1', className: classes.name, children: participant.isCurrentUser ? t("You") : participant.displayName })] })] }));
};
