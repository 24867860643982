var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useState } from "react";
import { Box, Button, CircularProgress, CustomDialog, DialogContent, List, ListSubheader, Typography, useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { ParticipantListItem } from "./ParticipantListItem";
import { useParticipantTakePhotoStyles } from "./ParticipantTakePhoto.styles";
import { useParticipantTakePhoto } from "./ParticipantTakePhoto.hooks";
export const ParticipantTakePhoto = memo(({ call, onClose }) => {
    const { t } = useTranslation();
    const participants = call.participants.filter(p => !p.isCurrentUser && !p.state.videoMuted);
    const { classes, theme } = useParticipantTakePhotoStyles();
    const { succeeded, failed } = useAddToast();
    const [participant, setParticipant] = useState(participants.length === 1 ? participants[0] : undefined);
    const { snapshot, takeSnapshot: handleTakeSnapshot, saveSnapshot } = useParticipantTakePhoto({ call, participant });
    const [isSaving, setSaving] = useState(false);
    const handleSelect = useCallback((p) => setParticipant(p), []);
    // const [description, setDescription] = useState("");
    // const handleChangeDescription = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value || ""), []);
    const handleSave = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setSaving(true);
            const result = yield saveSnapshot();
            if (result) {
                succeeded(t("Photo is captured and stored successfully."));
                onClose();
            }
            else {
                failed(t("Failed to save photo, please try again."));
            }
        }
        finally {
            setSaving(false);
        }
    }), [failed, onClose, saveSnapshot, succeeded, t]);
    if (!participant) {
        return (_jsx(CustomDialog, { open: true, handleClose: onClose, type: 'bottomSheet', children: _jsx(List, { subheader: _jsx(ListSubheader, { children: t("Select participant:") }), children: participants.map(p => (_jsx(ParticipantListItem, { participant: p, onClick: handleSelect, color: theme.palette.common.black, hideActions: true }, p.id))) }) }));
    }
    return (_jsx(CustomDialog, { open: true, onClose: onClose, type: 'base', DialogTitleProps: {
            onClose,
            title: t("Take Photo"),
        }, DialogActionsProps: {
            children: (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: handleSave, disabled: !snapshot || isSaving, children: isSaving ? _jsx(CircularProgress, { size: 16, sx: { color: theme.palette.common.white } }) : t("Save") }), _jsx(Button, { disabled: isSaving, onClick: handleTakeSnapshot, variant: 'outlined', children: t("Retake") })] })),
        }, children: _jsx(DialogContent, { children: _jsxs(Box, { children: [_jsx(Typography, { mb: "8px", children: t("Image") }), snapshot ? _jsx("img", { className: classes.image, alt: '', src: snapshot }) : _jsx(CircularProgress, {})] }) }) }));
});
