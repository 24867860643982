import { FC } from "react";
import { Typography, useTheme } from "@toolkit/ui"; // Replace with an appropriate UI component for text
import { useTranslation } from "@toolkit/i18n";
import { getNotificationTextStyle } from "../../utils";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { NotificationBellIcon } from "@/components";

type NotificationDefaultCardProps = {
  type: string;
  creationDate?: string;
  isSeen?: boolean;
  isLoading?: boolean;
};

export const NotificationDefaultCard: FC<NotificationDefaultCardProps> = props => {
  const { type, creationDate, isSeen, isLoading } = props;
  const theme = useTheme();

  const { t } = useTranslation("consumer");

  const { color } = getNotificationTextStyle(isSeen!, theme);

  const content = type?.replace(/-/g, " ");

  return (
    <NotificationCard
      title={t(content)}
      date={creationDate}
      icon={<NotificationBellIcon />}
      iconColor={theme.palette.success.main}
      isSeen={isSeen}
      isLoading={isLoading}
    >
      <Typography
        align='left'
        sx={{
          color,
          wordBreak: "break-word",
          whiteSpace: "normal",
        }}
      >
        {type}
      </Typography>
    </NotificationCard>
  );
};
