import { FC } from "react";
import { GuestCall } from "@health/meeting";

type VisitCallGuestProps = {
  token: string;
};

export const VisitCallGuest: FC<VisitCallGuestProps> = ({ token }) => {
  return <GuestCall token={token} />;
};
