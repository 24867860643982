var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from "react";
import { MobileVideoFacingMode } from "./types";
import { useZoom } from "./useZoom";
import { formatExecutedFailure } from "./utils";
export const useFlipCamera = () => {
    const { mediaStream } = useZoom();
    const [facingMode, setFacingMode] = useState(MobileVideoFacingMode.User);
    const flipCamera = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!mediaStream) {
            return { type: "CLIENT_NOT_READY" };
        }
        try {
            const mode = facingMode === MobileVideoFacingMode.Environment ? MobileVideoFacingMode.User : MobileVideoFacingMode.Environment;
            mediaStream.switchCamera(mode);
            setFacingMode(mode);
            return { success: true };
        }
        catch (error) {
            console.error("Meeting: Unable to switch camera due to the error", error);
            return formatExecutedFailure(error);
        }
    }), [mediaStream, facingMode]);
    const enabled = mediaStream && mediaStream.isCapturingVideo() && mediaStream.getCameraList().length > 1;
    return { flipCamera: enabled ? flipCamera : undefined };
};
