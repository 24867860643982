import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const ApplicationBrandIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='75.591' height='75.447' viewBox='0 0 79.591 80.447' {...props}>
      <defs>
        <linearGradient id='application-brand-linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#25287f' />
          <stop offset='1' stopColor='#882558' />
        </linearGradient>
      </defs>
      <g id='Group_10612' data-name='Group 10612' transform='translate(-188 -855.484)'>
        <path
          id='Path_12745'
          data-name='Path 12745'
          d='M51.007,79.629c-9.36,1.322-18.308,1.2-27.568-1.561C14.259,75.3,4.781,69.972,1.5,62.079c-3.2-7.9-.238-18.326,3.92-27.21A70.542,70.542,0,0,1,21.929,11.93C29.093,5.194,38.359-.936,46.693.118,54.991,1.22,62.357,9.5,67.822,17.7A65.926,65.926,0,0,1,78.629,43.031c1.708,9.134,1.476,19.315-3.543,25.812-4.971,6.533-14.731,9.382-24.079,10.786'
          transform='translate(188.004 855.485)'
          fill='#25287f'
          opacity='0.2'
        />
        <path
          id='Path_12746'
          data-name='Path 12746'
          d='M38.747,78.825c-8.917-2.015-16.965-5.2-24.391-10.866C7.007,62.305.265,54.239,0,45.978c-.182-8.243,6.07-16.652,12.877-23.257A68.208,68.208,0,0,1,35.675,7.643c8.787-3.634,19.267-6,26.441-2.186C69.242,9.3,73.06,19.315,75.191,28.6a63.778,63.778,0,0,1,1.083,26.612c-1.589,8.845-5.29,17.969-12.056,22.123-6.736,4.2-16.537,3.43-25.471,1.494'
          transform='translate(188 852.627)'
          fill='url(#application-brand-linear-gradient)'
        />
        <path
          id='Path_12753'
          data-name='Path 12753'
          d='M45.119,31.269A13.166,13.166,0,1,0,58.285,44.435,13.166,13.166,0,0,0,45.119,31.269m0,22.389a9.222,9.222,0,1,1,9.222-9.222,9.222,9.222,0,0,1-9.222,9.222'
          transform='translate(188.94 853.443)'
          fill='#fff'
        />
        <path
          id='Path_12754'
          data-name='Path 12754'
          d='M26.9,30.442a2.8,2.8,0,0,1,2.9,2.759,2.741,2.741,0,0,1-2.9,2.759A2.741,2.741,0,0,1,24,33.2a2.781,2.781,0,0,1,2.9-2.759m0,26.046h0a2.406,2.406,0,0,1-2.461-2.345V40.28a2.464,2.464,0,0,1,4.923,0V54.143A2.406,2.406,0,0,1,26.9,56.488'
          transform='translate(188.706 853.418)'
          fill='#fff'
        />
        <path
          id='Path_12755'
          data-name='Path 12755'
          d='M45.077,34.762A9.633,9.633,0,1,0,54.71,44.4a9.634,9.634,0,0,0-9.633-9.633m-.061,17.349a7.778,7.778,0,1,1,7.778-7.778,7.778,7.778,0,0,1-7.778,7.778'
          transform='translate(189.042 853.546)'
          fill='#fff'
        />
      </g>
    </SvgIcon>
  );
};
