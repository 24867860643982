var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectionState } from "./types";
import { useMediaState } from "./useMediaState";
import { formatExecutedFailure, initializeZoomClient, startAudio as startAudioStream, startVideoStream } from "./utils";
export const useConnectionState = (client, debug) => {
    const { mediaState, resetMediaState } = useMediaState(client);
    const [connectionState, setConnectionState] = useState(ConnectionState.Closed);
    const [isConnecting, setConnecting] = useState(false);
    const ref = useRef({ leaveSession: () => { }, connectionState });
    const resetConnectionState = useCallback(() => {
        var _a, _b;
        if (ref.current.connectionState === ConnectionState.Connected) {
            (_b = (_a = ref.current).leaveSession) === null || _b === void 0 ? void 0 : _b.call(_a);
        }
        ref.current.connectionState = ConnectionState.Closed;
        setConnectionState(ConnectionState.Closed);
        setConnecting(false);
    }, []);
    const mediaStream = useMemo(() => {
        if (connectionState === ConnectionState.Connected) {
            const stream = client.getMediaStream();
            window["zmClient"] = client;
            window["mediaStream"] = stream;
            return stream;
        }
        return null;
    }, [client, connectionState]);
    const startAudio = useCallback((config) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const stream = client.getMediaStream();
        const audioResult = yield startAudioStream(stream, { mute: !!((_a = config.audioOptions) === null || _a === void 0 ? void 0 : _a.mute) });
        if (!audioResult.success) {
            if (debug) {
                console.warn("Meeting: Unable to start audio, fallback to speakerOnly.", audioResult);
            }
            const speakerResult = yield startAudioStream(stream, { speakerOnly: true });
            if (!speakerResult.success && debug) {
                console.error("Meeting: Failed to start speakerOnly. Reason:", speakerResult);
            }
        }
    }), [client, debug]);
    const startVideo = useCallback((config) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const stream = client.getMediaStream();
        if ((_a = config.videoOptions) === null || _a === void 0 ? void 0 : _a.localVideoOn) {
            const videoResult = yield startVideoStream(stream);
            if (!videoResult.success && debug) {
                console.error("Meeting: Unable to start video due to the Error:", videoResult);
            }
        }
    }), [client, debug]);
    const leaveSession = useCallback((...args_1) => __awaiter(void 0, [...args_1], void 0, function* (endSession = false) {
        try {
            debug && console.log("Meeting: Leave session request", { endSession });
            yield client.leave(endSession);
            return { success: true };
        }
        catch (error) {
            if (debug) {
                console.error("Meeting: Unable to leave session due to the error", error);
            }
            return { type: "ERROR", reason: error };
        }
        finally {
            resetConnectionState();
        }
    }), [client, debug, resetConnectionState]);
    ref.current = { leaveSession, connectionState };
    const joinSession = useCallback(
    // eslint-disable-next-line max-statements
    (config) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setConnecting(true);
            debug && console.log("Meeting: Join session request", config);
            const sessionInfo = client.getSessionInfo();
            if (sessionInfo.isInMeeting) {
                if (sessionInfo.password === config.sessionPassword && sessionInfo.topic === config.token) {
                    debug && console.log("Meeting: Skip Join session, user already in meeting.");
                    setConnectionState(ConnectionState.Connected);
                    return { success: true };
                }
                else {
                    debug && console.log("Meeting: User already in meeting, leave session before rejoin.");
                    yield ref.current.leaveSession();
                }
            }
            const iniResult = yield initializeZoomClient(client);
            if (!iniResult.success) {
                return iniResult;
            }
            yield client.join(config.topic, config.token, config.userName, config.sessionPassword, config.sessionIdleTimeoutMins);
            setConnectionState(ConnectionState.Connected);
            startAudio(config);
            startVideo(config);
            return { success: true };
        }
        catch (error) {
            if (debug) {
                console.error("Meeting: Unable to join session due to the error", error);
            }
            return formatExecutedFailure(error);
        }
        finally {
            setConnecting(false);
        }
    }), [debug, client, startAudio, startVideo]);
    useEffect(() => {
        const { isInMeeting } = client.getSessionInfo();
        if (isInMeeting) {
            setConnectionState(ConnectionState.Connected);
        }
        const onConnectionChange = (payload) => {
            setConnectionState(payload.state);
            if (payload.state === ConnectionState.Closed) {
                resetMediaState();
            }
        };
        client.on("connection-change", onConnectionChange);
        return () => {
            client.off("connection-change", onConnectionChange);
        };
    }, [client, resetMediaState]);
    useEffect(() => {
        return () => {
            resetConnectionState();
        };
    }, [resetConnectionState]);
    return {
        connectionState,
        isConnecting,
        joinSession,
        leaveSession,
        mediaState,
        mediaStream,
        resetConnectionState,
    };
};
