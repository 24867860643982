var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from "react";
import { useZoom } from "./useZoom";
import { formatExecutedFailure } from "./utils";
export function useMuteSpeaker() {
    const [speakerMuted, setSpeakerMuted] = useState(false);
    const { mediaStream } = useZoom();
    const muteSpeaker = useCallback((value) => __awaiter(this, void 0, void 0, function* () {
        if (!mediaStream) {
            return { type: "USER_NOT_CONNECTED" };
        }
        const muted = typeof value === "function" ? value(speakerMuted) : value;
        try {
            if (muted) {
                yield mediaStream.muteAllUserAudioLocally();
            }
            else {
                yield mediaStream.unmuteAllUserAudioLocally();
            }
            setSpeakerMuted(muted);
            return { success: true };
        }
        catch (error) {
            console.error("Meeting: Unable to toggle speaker due to the error", error);
            return formatExecutedFailure(error);
        }
    }), [mediaStream, speakerMuted]);
    return { speakerMuted, muteSpeaker: !mediaStream ? undefined : muteSpeaker };
}
