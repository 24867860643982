import { DoctorFilterInput } from "@/schema/types";
import { useState } from "react";
import { IDoctorsListFiltersFormValues } from "../../../doctor/components/DoctorsListFilters/DoctorsListFiltersFormSchema";
import { convertDoctorsFilterFormValuesToBackEndValues } from "../../../doctor/components/DoctorsListFilters/utils";
import { useActiveDoctorsQuery } from "../../gql/queries";

export const useGetAvailableDoctorsList = () => {
  const staticFilters = {
    isActive: true,
  };

  const [filter, setFilter] = useState<DoctorFilterInput>(staticFilters);

  const { data, loading, fetchMore, refetch } = useActiveDoctorsQuery({
    variables: {
      first: 10,
      filter: filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const doctors = data?.doctors?.edges?.map(edge => edge?.node);
  const pageInfo = data?.doctors?.pageInfo;

  const loadMoreDoctors = () => {
    if (pageInfo?.hasNextPage && fetchMore) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo.endCursor || "",
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.doctors) {
            return previousResult;
          }

          const newEdges = [...(previousResult?.doctors?.edges || []), ...(fetchMoreResult.doctors.edges || [])];

          return {
            ...previousResult,
            doctors: {
              ...previousResult.doctors,
              edges: newEdges,
              pageInfo: fetchMoreResult.doctors.pageInfo,
            },
          };
        },
      });
    }
  };
  const onFilterValuesChange = (data: IDoctorsListFiltersFormValues) => {
    setFilter({ ...convertDoctorsFilterFormValuesToBackEndValues(data), ...staticFilters });
  };
  const refetchDoctors = () => {
    refetch({
      first: 10,
      filter: filter,
    });
  };
  return {
    doctors,
    loading,
    fetchMore: loadMoreDoctors,
    pageInfo,
    onFilterValuesChange,
    refetchDoctors,
  };
};
