import { jsx as _jsx } from "react/jsx-runtime";
import { VisitStatus } from "../../schema/types";
import { CallContent } from "./CallContent";
import { CallError } from "./CallError";
import { useMeetingInfo } from "./useMeetingInfo";
import { useVisitStatus } from "./useVisitStatus";
import { CallVisitStatus } from "./CallVisitStatus";
import { useTranslation } from "@toolkit/i18n";
import { useCallback } from "react";
export const Call = ({ invitationLink, visitId, renderDoctorConsole, onClose, onGoBack }) => {
    var _a;
    const visitInfo = useVisitStatus(visitId);
    const meetingInfo = useMeetingInfo(visitInfo.visit);
    const { t } = useTranslation();
    const isLoading = visitInfo.loading || meetingInfo.loading;
    const hasError = !!visitInfo.error || !!meetingInfo.error;
    const handleRetry = useCallback(() => {
        if (!visitInfo.visit || visitInfo.error) {
            return visitInfo.handleRetry();
        }
        if (!meetingInfo.call || meetingInfo.error) {
            return meetingInfo.handleRetry();
        }
    }, []);
    if (!visitInfo.visit || !meetingInfo.call || isLoading || hasError) {
        return (_jsx(CallError, { isLoading: isLoading, hasError: hasError, onClick: handleRetry, errorMessage: !visitId ? t("Invalid meeting ID.") : "" }));
    }
    if (!meetingInfo.joined && ((_a = visitInfo.visit) === null || _a === void 0 ? void 0 : _a.status) !== VisitStatus.CallInProgress) {
        return _jsx(CallVisitStatus, { visit: visitInfo.visit, call: meetingInfo.call, onClose: onClose });
    }
    return (_jsx(CallContent, { call: meetingInfo.call, visit: visitInfo.visit, invitationLink: invitationLink, onClose: onClose, onGoBack: onGoBack, renderDoctorConsole: renderDoctorConsole }));
};
