import { useMemo } from "react";
import { useZoom } from "./useZoom";
export const useUser = (participant) => {
    const { users } = useZoom();
    return useMemo(() => {
        if (!users || !participant.id || !users[participant.id]) {
            return {};
        }
        return users[participant.id];
    }, [users, participant.id]);
};
