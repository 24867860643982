import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePatientProfileUpdateMutationVariables = Types.Exact<{
  input: Types.CustomerProfileUpdateInput;
}>;


export type UpdatePatientProfileUpdateMutation = { __typename?: 'Mutation', customerProfileUpdate?: { __typename?: 'CustomerProfileUpdate', patient?: { __typename?: 'Patient', user?: { __typename?: 'User', id: string, photo?: string | null, gender?: Types.UserGender | null, fullName?: string | null } | null } | null } | null };


export const UpdatePatientProfileUpdateDocument = gql`
    mutation UpdatePatientProfileUpdate($input: CustomerProfileUpdateInput!) {
  customerProfileUpdate(input: $input) {
    patient {
      user {
        id
        photo
        gender
        fullName
      }
    }
  }
}
    `;
export type UpdatePatientProfileUpdateMutationFn = Apollo.MutationFunction<UpdatePatientProfileUpdateMutation, UpdatePatientProfileUpdateMutationVariables>;

/**
 * __useUpdatePatientProfileUpdateMutation__
 *
 * To run a mutation, you first call `useUpdatePatientProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientProfileUpdateMutation, { data, loading, error }] = useUpdatePatientProfileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientProfileUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientProfileUpdateMutation, UpdatePatientProfileUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientProfileUpdateMutation, UpdatePatientProfileUpdateMutationVariables>(UpdatePatientProfileUpdateDocument, options);
      }
export type UpdatePatientProfileUpdateMutationHookResult = ReturnType<typeof useUpdatePatientProfileUpdateMutation>;
export type UpdatePatientProfileUpdateMutationResult = Apollo.MutationResult<UpdatePatientProfileUpdateMutation>;
export type UpdatePatientProfileUpdateMutationOptions = Apollo.BaseMutationOptions<UpdatePatientProfileUpdateMutation, UpdatePatientProfileUpdateMutationVariables>;