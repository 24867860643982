import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MedicalMessageFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.MedicalMessageSortingInput>;
}>;


export type MedicalMessageListQuery = { __typename?: 'Query', medicalMessages?: { __typename?: 'MedicalMessageCountableConnection', edges: Array<{ __typename?: 'MedicalMessageCountableEdge', node: { __typename?: 'MedicalMessage', id: string, name?: string | null, headerImage?: string | null, medicalMessageType?: Types.MedicalMessageType | null, medicalMessageCategory?: Types.MedicalMessageCategory | null, messages?: Array<{ __typename?: 'MedicalMessageVariant', id: string, presentedTitleArabic?: string | null, presentedTitleEnglish?: string | null, bodyArabic?: string | null, bodyEnglish?: string | null, buttonTextArabic?: string | null, buttonTextEnglish?: string | null, contentURLArabic?: string | null, contentURLEnglish?: string | null, extraData?: string | null } | null> | null } }> } | null };


export const MedicalMessageListDocument = gql`
    query MedicalMessageList($after: String, $before: String, $filter: MedicalMessageFilterInput, $first: Int, $last: Int, $sortBy: MedicalMessageSortingInput) {
  medicalMessages(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        name
        headerImage
        medicalMessageType
        medicalMessageCategory
        messages {
          id
          presentedTitleArabic
          presentedTitleEnglish
          bodyArabic
          bodyEnglish
          buttonTextArabic
          buttonTextEnglish
          contentURLArabic
          contentURLEnglish
          extraData
        }
      }
    }
  }
}
    `;

/**
 * __useMedicalMessageListQuery__
 *
 * To run a query within a React component, call `useMedicalMessageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalMessageListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMedicalMessageListQuery(baseOptions?: Apollo.QueryHookOptions<MedicalMessageListQuery, MedicalMessageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalMessageListQuery, MedicalMessageListQueryVariables>(MedicalMessageListDocument, options);
      }
export function useMedicalMessageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalMessageListQuery, MedicalMessageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalMessageListQuery, MedicalMessageListQueryVariables>(MedicalMessageListDocument, options);
        }
export function useMedicalMessageListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MedicalMessageListQuery, MedicalMessageListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedicalMessageListQuery, MedicalMessageListQueryVariables>(MedicalMessageListDocument, options);
        }
export type MedicalMessageListQueryHookResult = ReturnType<typeof useMedicalMessageListQuery>;
export type MedicalMessageListLazyQueryHookResult = ReturnType<typeof useMedicalMessageListLazyQuery>;
export type MedicalMessageListSuspenseQueryHookResult = ReturnType<typeof useMedicalMessageListSuspenseQuery>;
export type MedicalMessageListQueryResult = Apollo.QueryResult<MedicalMessageListQuery, MedicalMessageListQueryVariables>;