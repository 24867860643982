import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, IconButton, MuiArrowBackIcon, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useCallStyles } from "./Call.styles";
import { useEffect, useRef } from "react";
import { useNetwork } from "@toolkit/core";
import { useCallHook } from "./useCallHook";
export const TimeoutScreen = ({ call, onClose }) => {
    const { t } = useTranslation();
    const { classes, theme } = useCallStyles();
    const { handleJoin, isJoining } = useCallHook({ call });
    const isOnline = useNetwork();
    const joinCallRef = useRef(handleJoin);
    joinCallRef.current = handleJoin;
    useEffect(() => {
        // retry after internet is reachable
        const timeoutId = setTimeout(() => {
            if (isOnline) {
                joinCallRef.current();
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [isOnline]);
    return (_jsx(Box, { className: classes.root, children: _jsxs(Box, { className: classes.container, children: [onClose && (_jsx(Box, { className: classes.header, children: _jsx(IconButton, { color: 'inherit', onClick: onClose, children: _jsx(MuiArrowBackIcon, {}) }) })), _jsx(Box, { className: classes.body, children: _jsxs(Box, { display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, children: [_jsx(Typography, { textAlign: 'center', fontSize: theme.mixins.fonts.fontSize.md, children: t("Internet Connection has been lost") }), _jsx(Typography, { textAlign: 'center', variant: 'body1', color: theme.palette.gray[400], children: t("Trying to reconnect...") }), _jsx(Box, { children: _jsx(Button, { disabled: !call.callId || isJoining, onClick: handleJoin, children: isJoining ? t("Connecting ...") : t("Retry") }) })] }) })] }) }));
};
