import { useCallback, useEffect, useReducer } from "react";
const mediaShape = {
    audio: {
        encode: false,
        decode: false,
    },
    video: {
        encode: false,
        decode: false,
    },
    share: {
        encode: false,
        decode: false,
    },
};
// eslint-disable-next-line default-param-last
const mediaReducer = (s = mediaShape, action) => {
    switch (action.type) {
        case "audio-encode": {
            return Object.assign(Object.assign({}, s), { audio: Object.assign(Object.assign({}, s.audio), { encode: action.payload }) });
        }
        case "audio-decode": {
            return Object.assign(Object.assign({}, s), { audio: Object.assign(Object.assign({}, s.audio), { decode: action.payload }) });
        }
        case "video-encode": {
            return Object.assign(Object.assign({}, s), { video: Object.assign(Object.assign({}, s.video), { encode: action.payload }) });
        }
        case "video-decode": {
            return Object.assign(Object.assign({}, s), { video: Object.assign(Object.assign({}, s.video), { decode: action.payload }) });
        }
        case "share-encode": {
            return Object.assign(Object.assign({}, s), { share: Object.assign(Object.assign({}, s.share), { encode: action.payload }) });
        }
        case "share-decode": {
            return Object.assign(Object.assign({}, s), { share: Object.assign(Object.assign({}, s.share), { decode: action.payload }) });
        }
        case "reset-media": {
            return mediaShape;
        }
        default:
            return s;
    }
};
export const useMediaState = (client) => {
    const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
    const resetMediaState = useCallback(() => {
        dispatch({ type: "reset-media" });
    }, [dispatch]);
    useEffect(() => {
        const onMediaSDKChange = (payload) => {
            const { action, type, result } = payload;
            dispatch({ type: `${type}-${action}`, payload: result === "success" });
        };
        client.on("media-sdk-change", onMediaSDKChange);
        return () => {
            client.off("media-sdk-change", onMediaSDKChange);
        };
    }, [client]);
    return {
        mediaState,
        resetMediaState,
    };
};
