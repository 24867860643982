import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Box, makeStyles } from "@toolkit/ui";
import { ChatDraftView } from "./ChatDraftView";
import { Close as CloseIcon } from "@mui/icons-material";
const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        padding: "0 4px",
        display: "flex",
        flexDirection: "row",
        minWidth: 280,
    },
}));
export const ChatDraft = ({ draft, onClose }) => {
    const { classes, theme } = useStyles();
    const showDraftView = draft && (draft.action || (draft.attachments && draft.attachments.length > 0));
    if (!showDraftView) {
        return null;
    }
    return (_jsxs(Box, { className: classes.root, children: [_jsx(Box, { flex: 1, children: _jsx(ChatDraftView, { draft: draft }) }), _jsx(Box, { paddingRight: 2, children: _jsx(IconButton, { size: "small", onClick: onClose, children: _jsx(CloseIcon, { sx: { fontSize: 20 }, htmlColor: theme.palette.stale[300] }) }) })] }));
};
