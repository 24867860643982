import { routesPaths } from "@/routing/routesPaths";
import { VisitStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Link } from "@toolkit/ui";
import { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { CallPageActions } from "../../components/CallActionPage/CallPageActions";
import { CallPageIcon } from "../../components/CallPageIcon/CallPageIcon";
import { CallPageTitle } from "../../components/CallPageTitle/CallPageTitle";
import { useVisitCallWaitingContainerStyle } from "./VisitCallWaitingContainerStyle";
import { useVisitCallWaitingContainer } from "./useVisitCallWaitingContainer";

export const VisitCallWaitingContainer: FC = () => {
  const { classes } = useVisitCallWaitingContainerStyle();
  const { visit, isLoading } = useVisitCallWaitingContainer();
  const navigate = useNavigate();
  const { t } = useTranslation("consumer");
  const canceledStatuses = [
    VisitStatus.CallTimedOut,
    VisitStatus.Rejected,
    VisitStatus.Canceled,
    VisitStatus.CanceledByConsumer,
    VisitStatus.EndedByOneOfTheParticipants,
    VisitStatus.DoctorAnswerTimedOut,
  ];
  const handleRefundClick = () => {
    navigate(routesPaths.wallet);
  };
  return (
    <Box className={classes.container}>
      <CallPageIcon visit={visit} isLoading={isLoading} />
      <CallPageTitle handleRefundClick={handleRefundClick} visit={visit} isLoading={isLoading} />
      <CallPageActions visit={visit} isLoading={isLoading} />
      {visit?.status && canceledStatuses.includes(visit.status) ? (
        <Link className={classes.allDoctorsLink} component={RouterLink} to={routesPaths.virtualVisit}>
          {t("See all available doctors")}
        </Link>
      ) : null}
    </Box>
  );
};
