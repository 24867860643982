import { setDefaultEnvVariables } from "@toolkit/core";

type ENV_VARIABLE =
  | "SERVER_URL"
  | "BASE_URL"
  | "SUBSCRIPTION_URL"
  | "FILE_SERVICE_API_URL"
  | "BASE_MEDIA_URL"
  | "KEYCLOAK_REALM_LINK"
  | "KEYCLOAK_API_URL"
  | "KEYCLOAK_CLIENT_ID_CONSUMER"
  | "ENABLE_TOLGEE_WIZARD"
  | "TOLGEE_API_URL"
  | "TOLGEE_API_KEY"
  | "TOLGEE_PROJECT_ID"
  | "FLOWISE_BASE_URL"
  | "SADA_BASE_URL"
  | "SADA_API_ENV"
  | "SADA_API_BASE_URL"
  | "CONSUMER_ALLOW_ONLY_FOR_DELETE_ACCOUNT_ROUTES"
  | "LOGIN_API_URL";

const defaultVariables: Record<ENV_VARIABLE, string | undefined> = {
  SERVER_URL: process.env.REACT_APP_SERVER_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  SUBSCRIPTION_URL: process.env.REACT_APP_SUBSCRIPTION_URL,
  FILE_SERVICE_API_URL: process.env.REACT_APP_FILE_SERVICE_API_URL,
  BASE_MEDIA_URL: process.env.REACT_APP_BASE_MEDIA_URL,
  // KEYCLOAK
  KEYCLOAK_REALM_LINK: process.env.REACT_APP_KEYCLOAK_REALM_LINK,
  KEYCLOAK_API_URL: process.env.REACT_APP_KEYCLOAK_API_URL,
  KEYCLOAK_CLIENT_ID_CONSUMER: process.env.REACT_APP_KEYCLOAK_CLIENT_ID_CONSUMER,
  // API LOGIN URL
  LOGIN_API_URL: process.env.REACT_APP_LOGIN_URL,
  // TOLGEE
  TOLGEE_API_KEY: process.env.REACT_APP_TOLGEE_API_KEY,
  TOLGEE_API_URL: process.env.REACT_APP_TOLGEE_API_URL,
  ENABLE_TOLGEE_WIZARD: process.env.REACT_APP_ENABLE_TOLGEE_WIZARD,
  TOLGEE_PROJECT_ID: process.env.REACT_APP_TOLGEE_PROJECT_ID,
  // FLOWISE
  FLOWISE_BASE_URL: process.env.REACT_APP_FLOWISE_BASE_URL,
  // SADA
  SADA_BASE_URL: process.env.REACT_APP_SADA_BASE_URL,
  SADA_API_BASE_URL: process.env.REACT_APP_SADA_API_BASE_URL,
  SADA_API_ENV: process.env.REACT_APP_SADA_API_ENV,

  CONSUMER_ALLOW_ONLY_FOR_DELETE_ACCOUNT_ROUTES: process.env.REACT_APP_CONSUMER_ALLOW_ONLY_FOR_DELETE_ACCOUNT_ROUTES,
};

export const getEnvVariable = setDefaultEnvVariables(defaultVariables);
