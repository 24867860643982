import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";
import { isRTL } from "@toolkit/i18n";

interface ArrowIconProps extends SvgIconProps {
  direction?: "up" | "down" | "left" | "right";
  disableAutoRotate?: boolean;
}

const getRotation = (direction: ArrowIconProps["direction"], isRtl?: boolean) => {
  switch (direction) {
    case "up":
      return "rotate(-90deg)";
    case "down":
      return "rotate(90deg)";
    case "right":
      return !isRtl ? "rotate(0deg)" : "rotate(180deg)";
    case "left":
    default:
      return !isRtl ? "rotate(180deg)" : "rotate(0deg)";
  }
};

export const ArrowIcon: React.FC<ArrowIconProps> = ({ direction = "right", ...props }) => {
  const theme = useTheme();
  const isRtl = isRTL();

  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='Icon'
      width='12.725'
      height='12.725'
      viewBox='0 0 12.725 12.725'
      sx={{ transform: getRotation(direction, isRtl) }}
      {...props}
    >
      <path
        id='Path'
        d='M11.088.915H-.364A.636.636,0,0,1-1,.279.636.636,0,0,1-.364-.357H11.088a.636.636,0,1,1,0,1.272Z'
        transform='translate(1 6.083)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
      <path
        id='Path-2'
        data-name='Path'
        d='M-.364,11.725a.634.634,0,0,1-.45-.186.636.636,0,0,1,0-.9L4.463,5.362-.814.086a.636.636,0,0,1,0-.9.636.636,0,0,1,.9,0L5.812,4.912a.636.636,0,0,1,0,.9L.086,11.538A.634.634,0,0,1-.364,11.725Z'
        transform='translate(6.726 1)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
