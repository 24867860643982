import { useCallback, useContext, useEffect, useRef } from "react";
import { ChatContext } from "./ChatProvider";
export const useChatPlayer = () => {
    const currentRef = useRef();
    const { audioRef } = useContext(ChatContext);
    const pause = useCallback(() => { var _a; return (_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _a === void 0 ? void 0 : _a.pause(); }, [audioRef]);
    const update = useCallback((ref) => {
        var _a;
        if (audioRef && audioRef.current !== ref) {
            (_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.pause();
            audioRef.current = ref;
            currentRef.current = ref;
        }
    }, [audioRef]);
    useEffect(() => {
        return () => {
            if (currentRef.current && (audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === currentRef.current) {
                audioRef.current = undefined;
            }
        };
    }, [audioRef]);
    return { pause, update };
};
