import { DoctorFilterInput, DoctorOrderField } from "@/schema/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { OrderDirection } from "@toolkit/ui";
import { useEffect } from "react";
import { useAvailableDoctorsForVirtualCallQuery } from "../../gql";

export const useVirtualVisitAvailableDoctorsContainer = (filters: DoctorFilterInput) => {
  const {
    data,
    loading: isLoading,
    hasMore,
    handleFetchMore: fetchMoreData,
    refetch,
  } = useRelayStyleConnection({
    useQuery: useAvailableDoctorsForVirtualCallQuery,
    variables: {
      filter: filters,
      sortBy: {
        field: DoctorOrderField.Rating,
        direction: OrderDirection.Desc,
      },
    },
  });

  const handleRefresh = () => {
    refetch();
  };

  useEffect(() => {
    refetch({
      filter: filters,
    });
  }, [JSON.stringify(filters)]);

  return {
    doctors: data,
    isLoading,
    hasMore,
    fetchMoreData,
    handleRefresh,
  };
};
