import { CallIcon, CustomUserAvatar, DoctorCardLayout } from "@/components";
import Dot from "@/components/Dot";
import { DoctorServiceButton } from "@/domains/doctor";
import { VirtualVisitPaymentSummaryContainerData } from "@/domains/VirtualVisit/containers/VirtualVisitPaymentSummaryContainer/types";
import { routes as virtualCallRoutes } from "@/domains/VirtualVisit/routes";
import { useSiteSettingsGetQuery } from "@/gql";
import { useTranslation } from "@/i18n/i18n.config";
import { DoctorAvailabilityStatusEnum } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { Box, ChatIconFilled, LocationNewIcon, Typography } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { ProfileDataType } from "../../type";
import { useStyle } from "./DoctorProfileCardStyle";

interface DoctorProfileCardProps {
  doctorInfo: ProfileDataType;
  hideActions?: boolean;
}

export const DoctorProfileCard: React.FC<DoctorProfileCardProps> = props => {
  const { doctorInfo, hideActions } = props;
  const user = doctorInfo?.user;
  const { t } = useTranslation();
  const { classes } = useStyle();

  const navigate = useNavigate();

  const specialization = doctorInfo?.specializations
    ?.map(specialization => pickLocalizedValue(specialization?.display, specialization?.arabicDisplay))
    .join(", ");
  const yearsOfExperience = doctorInfo?.yearsOfExperience
    ? t("{{years}} years of experience", { years: doctorInfo?.yearsOfExperience })
    : "";
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency;
  const onlineVisitPrice = doctorInfo?.onlineVisitPrice;
  const isDoctorOnline =
    doctorInfo?.canAcceptCall &&
    doctorInfo?.availabilityStatus !== undefined &&
    [DoctorAvailabilityStatusEnum.AvailableLevel_1, DoctorAvailabilityStatusEnum.AvailableLevel_2].includes(doctorInfo.availabilityStatus);
  const handleCallClick = () => {
    navigate(virtualCallRoutes.callPaymentSummary.absRoute, { state: { doctor: doctorInfo } as VirtualVisitPaymentSummaryContainerData });
  };

  return (
    <>
      <DoctorCardLayout
        classes={{
          card: classes.root,
          downAvatarContainer: classes.downAvatarContainer,
        }}
        doctorAvatarNode={
          <Box className={classes.doctorAvatarContainer}>
            <Box width='145px' height='145px'>
              <CustomUserAvatar src={user?.photo} width='100%' height='100%' />
            </Box>
            {isDoctorOnline && (
              <Box className={classes.onlineStatus}>
                <Dot className={classes.dot} />
                <Typography>{t("Online")}</Typography>
              </Box>
            )}
          </Box>
        }
        infoNode={
          <>
            <Typography className={classes.specialty}>{specialization}</Typography>
            <Typography className={classes.experience}>{yearsOfExperience}</Typography>
            <Typography className={classes.vendorName}>
              {pickLocalizedValue(doctorInfo?.vendor?.name, doctorInfo?.vendor?.nameAr)}
            </Typography>
            {onlineVisitPrice && !hideActions && (
              <Typography className={classes.price}>{defaultCurrency + " " + onlineVisitPrice}</Typography>
            )}
            <Box className={classes.languagesContainer}>
              {doctorInfo?.languages?.map(lang => (
                <Box key={pickLocalizedValue(lang?.display, lang?.displayAr)} className={classes.languageContainer}>
                  <Box>{pickLocalizedValue(lang?.display, lang?.displayAr)}</Box>
                </Box>
              ))}
            </Box>
          </>
        }
        downAvatarNode={
          hideActions ? null : (
            <>
              <DoctorServiceButton
                icon={<CallIcon />}
                disabled={!isDoctorOnline}
                onClick={handleCallClick}
                label={""}
                classes={{
                  button: classes.button,
                }}
              />
              <DoctorServiceButton
                icon={<ChatIconFilled />}
                disabled
                label={""}
                classes={{
                  button: classes.button,
                }}
              />
              <DoctorServiceButton
                icon={<LocationNewIcon />}
                disabled
                label={""}
                classes={{
                  button: classes.button,
                }}
              />
            </>
          )
        }
      />
    </>
  );
};
