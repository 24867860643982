import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment";
import { CircularProgress, Box, CustomInfiniteScroll } from "@toolkit/ui";
import { useChatListHooks } from "./ChatList.hooks";
import { ChatListEmpty } from "./ChatListEmpty";
import { ChatListItem } from "./ChatListItem";
import { makeStyles } from "@toolkit/ui";
import { ChatViewDate } from "../ChatView";
import { useChatSender } from "../ChatProvider";
export const useChatListStyles = makeStyles()(() => ({
    root: {
        position: "relative",
        overflow: "auto",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column-reverse",
        minWidth: 280,
        overflowY: "auto",
        minHeight: "inherit",
        boxSizing: "border-box",
        padding: "16px 0",
        ".infinite-scroll-component": {
            gap: 16,
        },
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
export const ChatList = ({ id }) => {
    const { senderId } = useChatSender();
    const { classes } = useChatListStyles();
    const { data, loading, hasMore, hasError, isEmpty, handleFetchMore, handleRetry } = useChatListHooks(id);
    if (isEmpty) {
        return _jsx(ChatListEmpty, { isLoading: loading, hasError: hasError, onRetry: handleRetry });
    }
    return (_jsx(Box, { id: 'chats-component', className: classes.root, children: _jsx(CustomInfiniteScroll, { dataLength: data.length, onFetchMore: handleFetchMore, onRefresh: handleRetry, hasMore: hasMore, scrollableTarget: 'chats-component', inverse: true, loader: _jsx(Box, { py: 4, className: classes.loader, children: _jsx(CircularProgress, {}) }), children: data.map((item, i) => {
                const nextMessage = data[i - 1];
                const prevMessage = data[i + 1];
                const isOutgoing = senderId === item.senderUser.id;
                const hasAvatar = (nextMessage === null || nextMessage === void 0 ? void 0 : nextMessage.senderUser.id) !== item.senderUser.id;
                const hasDate = nextMessage && !moment(item.createdAt).isSame(nextMessage.createdAt, "day");
                return (_jsxs(_Fragment, { children: [hasDate && _jsx(ChatViewDate, { date: nextMessage.createdAt }), _jsx(ChatListItem, { chat: item, hasAvatar: hasAvatar, isOutgoing: isOutgoing }), !prevMessage && _jsx(ChatViewDate, { date: item.createdAt })] }));
            }) }) }));
};
