import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const CallADoctorNowIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='19.288' height='22.881' viewBox='0 0 19.288 22.881' {...props}>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_5916' data-name='Rectangle 5916' width='19.288' height='22.881' fill={fill} />
        </clipPath>
      </defs>
      <g id='Group_10223' data-name='Group 10223' clipPath='url(#clip-path)'>
        <path
          id='Path_12697'
          data-name='Path 12697'
          d='M48.522,73.111a1.579,1.579,0,0,0-1.378-.229c-2.53.667-3.5.316-4.2.06a2.533,2.533,0,0,0-.946-.21,2.264,2.264,0,0,0-2.31,2.341,8.858,8.858,0,0,0,.822,3.713c.043.1.086.2.127.295a6.7,6.7,0,0,1,.51,1.487l.019.1h.972v-.12c0-.882,0-1.871,0-2.705l0-.69a.471.471,0,0,0-.941,0c0,.23,0,.46,0,.689a7.24,7.24,0,0,1-.545-3.092,1.272,1.272,0,0,1,.479-.871.86.86,0,0,1,.688-.21c.181.033.351.068.514.105v1.2a.811.811,0,1,0,.7,0V73.94a6.317,6.317,0,0,0,3.232.049v.844a1.24,1.24,0,0,0-.89,1.184v.223a.343.343,0,0,0,.685,0v-.223a.548.548,0,1,1,1.1,0v.223a.342.342,0,1,0,.685,0v-.223a1.24,1.24,0,0,0-.89-1.184V73.818c.149-.042.3-.087.463-.137a.621.621,0,0,1,.574.087,1.106,1.106,0,0,1,.392.888v.026a8.3,8.3,0,0,1-.107,1.67c-.111.541-.279,1.084-.441,1.609l-.04.13c-.017-.3-.028-.68-.028-.965a.44.44,0,1,0-.881,0v3.54h.9l.016-.1a7.76,7.76,0,0,1,.475-1.708l.109-.265a8.7,8.7,0,0,0,.94-3.822,2.174,2.174,0,0,0-.8-1.659M42.879,75.7a.2.2,0,1,1-.2-.2.2.2,0,0,1,.2.2'
          transform='translate(-31.849 -58.369)'
          fill={fill}
        />
        <path
          id='Path_12698'
          data-name='Path 12698'
          d='M53.629,50.739h0a2.744,2.744,0,0,0,1.959-.814,2.774,2.774,0,0,0,.806-1.953,2.763,2.763,0,1,0-5.526-.009,2.743,2.743,0,0,0,2.756,2.776m-1.823-2.788a1.728,1.728,0,0,1,.484-1.3,1.811,1.811,0,0,1,1.329-.569h.035a1.839,1.839,0,0,1,1.818,1.965,1.872,1.872,0,0,1-1.842,1.778h-.015a1.871,1.871,0,0,1-1.367-.622,1.633,1.633,0,0,1-.443-1.23v-.02Z'
          transform='translate(-40.827 -36.246)'
          fill={fill}
        />
        <path
          id='Path_12699'
          data-name='Path 12699'
          d='M24.508,5.66a5.725,5.725,0,0,1,1.3-.085,1.325,1.325,0,0,1,.655-.171h.1a5.848,5.848,0,0,0-1.024-.093,6.027,6.027,0,0,0-.96.079c-.062.01-.125.022-.192.035a2.46,2.46,0,0,0-.157.293c.094-.022.187-.041.281-.058'
          transform='translate(-19.443 -4.262)'
          fill={fill}
        />
        <path
          id='Path_12700'
          data-name='Path 12700'
          d='M35.4,0H27.226l0,.009H25.584a2.443,2.443,0,0,0-1.306.377c.127-.008.254-.016.379-.016a6.207,6.207,0,0,1,3.036.771h.533a1.066,1.066,0,0,1-.118-.009H35.4a1.313,1.313,0,0,1,1.321,1.321V20.428A1.313,1.313,0,0,1,35.4,21.749H25.584a1.313,1.313,0,0,1-1.321-1.321V12.889c-.234-.067-.489-.145-.757-.239-.127-.044-.251-.092-.376-.14v7.918a2.448,2.448,0,0,0,2.453,2.453H35.4a2.448,2.448,0,0,0,2.453-2.453V9.186h0V2.453A2.448,2.448,0,0,0,35.4,0'
          transform='translate(-18.563)'
          fill={fill}
        />
        <path
          id='Path_12701'
          data-name='Path 12701'
          d='M23.348,58.014c-.073-.037-.145-.075-.216-.113v.344c.059.024.115.05.175.073.14.055.281.108.424.159.185.065.363.122.533.173v-.232a9.741,9.741,0,0,1-.917-.4'
          transform='translate(-18.564 -46.467)'
          fill={fill}
        />
        <path
          id='Path_12702'
          data-name='Path 12702'
          d='M11.06,7.611a5.18,5.18,0,0,0-4.978-3.2,6.191,6.191,0,0,0-.988.081c-.15.024-.3.056-.447.091a6.34,6.34,0,0,0-.8.241l-.094.038-.044.018c-.134.055-.266.115-.4.178A6.023,6.023,0,0,0,.593,7.671a4.872,4.872,0,0,0-.287,4.021,4.768,4.768,0,0,0,.222.493,6.694,6.694,0,0,0,2.791,2.754c.125.07.253.14.385.207.3.152.617.292.944.421.14.055.281.109.425.159a12.808,12.808,0,0,0,1.559.436l.814.172-.632-1.667.142-.039c.069-.019.138-.035.207-.051a2.67,2.67,0,0,0,.353-.1c2.965-1.125,4.556-4.205,3.546-6.867M5.588,14l.356.94-.266-.086c-.178-.057-.349-.118-.517-.179s-.349-.133-.515-.2c-.294-.121-.57-.248-.821-.379-.178-.093-.347-.189-.508-.288a5.152,5.152,0,0,1-1.958-1.989L1.346,11.8l-.015-.034a3.813,3.813,0,0,1-.176-.393,4.459,4.459,0,0,1,2.163-5.28,5.4,5.4,0,0,1,.5-.265c.132-.061.244-.108.354-.15q.233-.088.473-.155c.163-.045.327-.084.493-.114A4.446,4.446,0,0,1,10.21,7.933c.832,2.193-.522,4.748-3.018,5.7a5.523,5.523,0,0,1-1.057.281Z'
          transform='translate(0 -3.54)'
          fill={fill}
        />
        <path
          id='Path_12703'
          data-name='Path 12703'
          d='M13.452,26.519a.831.831,0,1,0,.831.831.832.832,0,0,0-.831-.831'
          transform='translate(-10.129 -21.282)'
          fill={fill}
        />
        <path
          id='Path_12704'
          data-name='Path 12704'
          d='M25.049,26.519a.831.831,0,1,0,.831.831.832.832,0,0,0-.831-.831'
          transform='translate(-19.435 -21.282)'
          fill={fill}
        />
        <path
          id='Path_12705'
          data-name='Path 12705'
          d='M36.644,28.182a.831.831,0,1,0-.831-.831.832.832,0,0,0,.831.831'
          transform='translate(-28.741 -21.282)'
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
