import { NotificationBellIcon } from "@/components";
import { NotificationType } from "@/schema/types";
import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Typography, useTheme } from "@toolkit/ui"; // Replace with the appropriate UI component
import { FC } from "react";
import { getNotificationTextStyle } from "../../utils";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { NotificationDefaultCard } from "../NotificationDefaultCard/NotificationDefaultCard";
import { useAccessRequestHook } from "./useAccessRequestHook";

type AccessRequestNotificationProps = {
  id: string;
  creationDate?: string;
  isSeen?: boolean;
};

export const AccessRequestNotification: FC<AccessRequestNotificationProps> = props => {
  const { id, creationDate, isSeen } = props;
  const theme = useTheme(); // Use theme for web styling
  const { t } = useTranslation("consumer"); // Web translation hook

  const { errorConsent, loadingConsent, patientProfileConsentForm } = useAccessRequestHook(id);

  const { color } = getNotificationTextStyle(isSeen!, theme);
  const requesterUser = patientProfileConsentForm?.requesterUser;
  if (!patientProfileConsentForm?.id || errorConsent) {
    return <NotificationDefaultCard type={NotificationType.PatientProfileAccessRequest} creationDate={creationDate} isSeen={isSeen} />;
  }

  return (
    <NotificationCard
      title={t("Access Request")}
      date={creationDate}
      icon={<NotificationBellIcon />}
      isLoading={loadingConsent}
      isSeen={isSeen}
    >
      <Typography sx={{ color }} variant='body2' noWrap>
        {t("An Access to your profile is requested by {{ user }}", {
          user: formatUserNameFull(requesterUser) || t("Patient"),
        })}
      </Typography>
    </NotificationCard>
  );
};
