import { VisitStatus } from "@/schema/types";
import { FC } from "react";
import { GetVisitQuery } from "../../gql";
import { CanceledActions } from "./CanceledActions";
import { DefaultActionPage } from "./DefaultActionPage";

type CallPageActionsProps = {
  visit: GetVisitQuery["visit"];
  isLoading?: boolean;
};

export const CallPageActions: FC<CallPageActionsProps> = props => {
  const { visit, isLoading } = props;
  const renderActions = () => {
    if (isLoading && !visit?.id) {
      return;
    }
    switch (visit?.status) {
      case VisitStatus.CallTimedOut:
      case VisitStatus.Rejected:
      case VisitStatus.Canceled:
      case VisitStatus.CanceledByConsumer:
      case VisitStatus.EndedByOneOfTheParticipants:
      case VisitStatus.DoctorAnswerTimedOut:
      case VisitStatus.CallRejoinable:
        return (
          <CanceledActions hideRetry={visit.status === VisitStatus.Rejected || visit.status === VisitStatus.CallRejoinable} visit={visit} />
        );
      default:
        return <DefaultActionPage visit={visit} />;
    }
  };

  return renderActions();
};
