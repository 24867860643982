import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { DoctorInfoFragmentFragmentDoc } from './doctorInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DoctorInfoQuery = { __typename?: 'Query', doctor?: { __typename?: 'Doctor', id: string, yearsOfExperience?: number | null, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, gender?: Types.UserGender | null, photo?: string | null } | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, arabicDisplay?: string | null, display?: string | null } | null> | null, vendor: { __typename?: 'Vendor', id: string, logo?: string | null, name: string, nameAr?: string | null, isIntegrated: boolean }, languages?: Array<{ __typename?: 'Language', display: string, displayAr?: string | null, id: string } | null> | null } | null };


export const DoctorInfoDocument = gql`
    query DoctorInfo {
  doctor {
    ...DoctorInfoFragment
  }
}
    ${DoctorInfoFragmentFragmentDoc}`;

/**
 * __useDoctorInfoQuery__
 *
 * To run a query within a React component, call `useDoctorInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDoctorInfoQuery(baseOptions?: Apollo.QueryHookOptions<DoctorInfoQuery, DoctorInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorInfoQuery, DoctorInfoQueryVariables>(DoctorInfoDocument, options);
      }
export function useDoctorInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorInfoQuery, DoctorInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorInfoQuery, DoctorInfoQueryVariables>(DoctorInfoDocument, options);
        }
export function useDoctorInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DoctorInfoQuery, DoctorInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DoctorInfoQuery, DoctorInfoQueryVariables>(DoctorInfoDocument, options);
        }
export type DoctorInfoQueryHookResult = ReturnType<typeof useDoctorInfoQuery>;
export type DoctorInfoLazyQueryHookResult = ReturnType<typeof useDoctorInfoLazyQuery>;
export type DoctorInfoSuspenseQueryHookResult = ReturnType<typeof useDoctorInfoSuspenseQuery>;
export type DoctorInfoQueryResult = Apollo.QueryResult<DoctorInfoQuery, DoctorInfoQueryVariables>;