import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const ReelBackgroundIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 155 162' {...props}>
      <defs>
        <linearGradient id='reel-linear-gradient' x1='0.5' y1='0.338' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopOpacity='0' />
          <stop offset='1' stopColor='#2c2c2c' />
        </linearGradient>
      </defs>
      <path
        id='doctor-on-a-video-call-with-a-patient-2021-09-02-07-23-41-utc_1_'
        data-name='doctor-on-a-video-call-with-a-patient-2021-09-02-07-23-41-utc (1)'
        d='M0,0H155a0,0,0,0,1,0,0V157a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V0A0,0,0,0,1,0,0Z'
        opacity='0.999'
        fill='url(#reel-linear-gradient)'
      />
    </SvgIcon>
  );
};
