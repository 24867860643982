var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
function createHiddenElement(id, tag) {
    let elm = document.getElementById(id);
    if (elm) {
        return elm;
    }
    elm = document.createElement(tag);
    elm.style.display = "none";
    elm.id = id;
    document.body.appendChild(elm);
    return elm;
}
export function initializeZoomClient(client) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const enforceGalleryView = !(window === null || window === void 0 ? void 0 : window.crossOriginIsolated);
            yield client.init("en-US", `CDN`, {
                webEndpoint: "zoom.us",
                enforceMultipleVideos: enforceGalleryView,
                enforceVirtualBackground: enforceGalleryView,
                stayAwake: true,
            });
            return { success: true };
        }
        catch (error) {
            return formatExecutedFailure(error);
        }
    });
}
export function formatExecutedFailure(error) {
    if (error !== null && typeof error === "object" && "type" in error) {
        return error;
    }
    return { type: "ERROR", reason: error };
}
function formatExecutedResult(callback) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield callback;
            return { success: true };
        }
        catch (error) {
            return formatExecutedFailure(error);
        }
    });
}
export function startAudio(stream, options) {
    return __awaiter(this, void 0, void 0, function* () {
        yield stopAudio(stream);
        return formatExecutedResult(stream.startAudio(options));
    });
}
export function stopAudio(stream) {
    return __awaiter(this, void 0, void 0, function* () {
        return formatExecutedResult(stream.stopAudio());
    });
}
export function startVideoStream(stream) {
    return __awaiter(this, void 0, void 0, function* () {
        if (stream.isRenderSelfViewWithVideoElement()) {
            const videoElement = createHiddenElement("__zm-publisher-video", "video");
            // IOS Mobile support
            return formatExecutedResult(stream.startVideo({ videoElement }));
        }
        else {
            return formatExecutedResult(stream.startVideo({
                hd: stream.isSupportHDVideo(),
                ptz: stream.isBrowserSupportPTZ(),
                originalRatio: true,
            }));
        }
    });
}
export function startShareScreen(stream) {
    return __awaiter(this, void 0, void 0, function* () {
        const screenElement = createHiddenElement("__zm-publisher-screen", stream.isStartShareScreenWithVideoElement() ? "video" : "canvas");
        return stream.startShareScreen(screenElement);
    });
}
export function getUserMedia(constraints) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (!("mediaDevices" in navigator)) {
                return { type: "MEDIA_NOT_FOUND" };
            }
            const stream = yield navigator.mediaDevices.getUserMedia(constraints);
            return { success: true, stream };
        }
        catch (error) {
            switch (error === null || error === void 0 ? void 0 : error.name) {
                case "NotFoundError":
                    return { type: "MEDIA_NOT_FOUND" };
                case "PermissionDeniedError":
                case "NotAllowedError":
                case "INSUFFICIENT_PRIVILEGES": {
                    return { type: "MEDIA_NOT_ALLOWED" };
                }
                case "NotReadableError": {
                    return { type: "MEDIA_NOT_READABLE" };
                }
            }
            return formatExecutedFailure(error);
        }
    });
}
