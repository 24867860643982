/* eslint-disable react/jsx-handler-names */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-statements */
import { Box, TextField } from "@toolkit/ui";
import React, { useEffect, useRef, useState } from "react";

interface InputOTPProps {
  numInputs: number;
  onChange: (value: string) => void;
}

export function InputOTP({ numInputs, onChange }: InputOTPProps) {
  const [inputs, setInputs] = useState<string[]>(Array(numInputs).fill(""));
  const inputRef = useRef<HTMLInputElement[]>(Array(numInputs).fill(null));

  useEffect(() => {
    onChange(inputs.join(""));
  }, [inputs, onChange]);

  const handleInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let arr = [...inputs];
    let input = e.target.value;

    if (index === 0 && input.length === numInputs && numInputs > 2) {
      const arr = input.split("");
      setInputs([...arr]);
      inputRef.current[input.length - 1].focus();
      setTimeout(() => {
        inputRef.current[input.length - 1].select();
      }, 0);
      return;
    }
    if (input.length >= 1) input = input[input.length - 1];
    if (!((input >= "0" && input <= "9") || input === "")) return;

    arr[index] = input;
    arr = arr.join("").split("");
    const arr_before_length = arr.length;

    while (arr.length < numInputs) arr.push("");

    if (input !== "") {
      if (arr_before_length < index + 1) {
        inputRef.current[arr_before_length].focus();
        setTimeout(() => {
          inputRef.current[arr_before_length].select();
        }, 0);
      } else {
        const focus_index = index + 1 < numInputs ? index + 1 : index;
        inputRef.current[focus_index].focus();
        setTimeout(() => {
          inputRef.current[focus_index].select();
        }, 0);
      }
    } else {
      inputRef.current[index].focus();
      setTimeout(() => {
        inputRef.current[index].select();
      }, 0);
    }

    setInputs(arr);
  };

  const handelKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 37) {
      const focus_index = index - 1 >= 0 ? index - 1 : index;
      inputRef.current[focus_index].focus();
      setTimeout(() => {
        inputRef.current[focus_index].select();
      }, 0);
    } else if (e.keyCode === 39) {
      const focus_index = index + 1 < numInputs ? index + 1 : index;
      inputRef.current[focus_index].focus();
      inputRef.current[focus_index].select();
      setTimeout(() => {
        inputRef.current[focus_index].select();
      }, 0);
    } else if (e.keyCode === 8) {
      if (inputs[index] === "") {
        const focus_index = index - 1 >= 0 ? index - 1 : index;
        inputRef.current[focus_index].focus();
        setTimeout(() => {
          inputRef.current[focus_index].select();
        }, 0);
      }
    }
  };

  return (
    <Box sx={{ display: "flex", columnGap: 1 }}>
      {inputs.map((input, index) => (
        <TextField
          key={index}
          value={input}
          onChange={handleInputChange(index)}
          onKeyDown={handelKeyDown(index)}
          inputProps={{
            ref: element => (inputRef.current[index] = element),
          }}
          sx={{
            maxWidth: "50px",
          }}
        />
      ))}
    </Box>
  );
}
