var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { CallState } from "./types";
import { useCallStyles } from "./Call.styles";
import { Box, Button, CircularProgress, CustomIcon, IconButton, Rating, RatingIcon, Typography, useAddToast } from "@toolkit/ui";
import { useCallback, useState } from "react";
import useCountDown from "./useCountDown";
import { useRatingScreenStyles } from "./RatingScreen.styles";
import { useUpdateMeetingRateMutation } from "../../gql/mutations";
import { useVisitRejoinCall } from "./useVisitRejoinCall";
export const RatingScreen = ({ call, visit, onClose }) => {
    const { t } = useTranslation();
    const { succeeded } = useAddToast();
    const { classes: callClasses } = useCallStyles();
    const { classes: ratingClasses, theme } = useRatingScreenStyles();
    const { isCallRejoinable, handleRejoinCall, loading, handleVisitCallEnd } = useVisitRejoinCall(call, visit);
    const { callId, state, resetCallState: handleReset } = call;
    const { callState } = state;
    const [rate, setRate] = useState(0);
    const handleClose = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield handleVisitCallEnd();
        handleReset === null || handleReset === void 0 ? void 0 : handleReset();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }), [handleReset, onClose]);
    const { countDown } = useCountDown(30, handleClose);
    const [updateMeetingRate] = useUpdateMeetingRateMutation();
    const onChange = useCallback((event, value) => __awaiter(void 0, void 0, void 0, function* () {
        setRate(value);
        if (!callId || value === null) {
            return;
        }
        try {
            yield updateMeetingRate({ variables: { meetingId: callId, meetingRate: value } });
        }
        catch (error) {
            console.error(error);
        }
        finally {
            succeeded(t("Thank you for your feedback!"));
            handleClose();
        }
    }), [callId, handleClose, succeeded, t]);
    return (_jsx(Box, { className: callClasses.root, children: _jsxs(Box, { className: callClasses.container, children: [_jsxs(Box, { className: callClasses.header, children: [_jsx(Box, { className: ratingClasses.header, children: _jsx(Box, { className: ratingClasses.counter, children: countDown }) }), _jsx(IconButton, { size: 'small', onClick: handleClose, children: _jsx(CustomIcon, { color: theme.palette.common.white, icon: 'xAlert' }) })] }), _jsx(Box, { className: callClasses.body, width: "320px !important", children: _jsxs(Box, { className: ratingClasses.root, children: [_jsx(Box, { mb: 3, children: _jsx(Typography, { fontSize: theme.mixins.fonts.fontSize.xl, children: callState === CallState.Ended ? t("The call is ended") : t("You left the call") }) }), _jsxs(Box, { mb: 3, gap: 1, children: [_jsx(Button, { disabled: !isCallRejoinable, size: 'small', onClick: handleRejoinCall, children: loading ? _jsx(CircularProgress, { size: 24 }) : t("Rejoin") }), _jsx(Button, { variant: 'outlined', size: 'small', onClick: handleClose, children: t("Return to home screen") })] }), _jsxs(Box, { className: ratingClasses.content, children: [_jsx(Typography, { sx: { pb: 2, textAlign: "initial" }, children: t("How was the call quality ?") }), _jsx(Rating, { value: rate, icon: _jsx(RatingIcon, { fill: theme.palette.common.white }), emptyIcon: _jsx(RatingIcon, { fill: theme.palette.gray[800] }), onChange: onChange, className: ratingClasses.input })] })] }) })] }) }));
};
