import moment from "moment";
import { useEffect, useMemo, useState } from "react";
export const useMeetingDuration = ({ startedAt }) => {
    const [value, setValue] = useState(0);
    useEffect(() => {
        if (!startedAt) {
            return;
        }
        const date = new Date(startedAt);
        const handleUpdate = () => {
            const elapsedTime = Date.now() - date.getTime();
            setValue(elapsedTime >= 0 ? elapsedTime / 1000 : 0);
        };
        handleUpdate();
        const intervalId = setInterval(handleUpdate, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [startedAt]);
    const duration = useMemo(() => moment(value * 1000).format(value >= 3600 ? "HH:mm:ss" : "mm:ss"), [value]);
    return { duration };
};
