var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from "@toolkit/i18n";
import { useCallback } from "react";
import { useAddToast } from "@toolkit/ui";
export const useChatListItemMenuHook = ({ chat, onClose }) => {
    const { t } = useTranslation();
    const { failed, succeeded } = useAddToast();
    const handleCopy = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (chat.message) {
                yield navigator.clipboard.writeText(chat.message);
                succeeded(t("Message has been copied to clipboard"));
            }
        }
        catch (reason) {
            failed(t("Failed to copy Message to clipboard. Please try again later."));
        }
        finally {
            onClose();
        }
    }), [chat.message, failed, onClose, succeeded, t]);
    return {
        handleCopy,
        enabled: true,
    };
};
