import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ChatGroupDocument = gql `
    query ChatGroup($chatGroupId: ID!) {
  chatGroup(id: $chatGroupId) {
    createdAt
    id
    name
    visitId
    participants {
      id
      photo
      fullName
    }
  }
}
    `;
/**
 * __useChatGroupQuery__
 *
 * To run a query within a React component, call `useChatGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatGroupQuery({
 *   variables: {
 *      chatGroupId: // value for 'chatGroupId'
 *   },
 * });
 */
export function useChatGroupQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ChatGroupDocument, options);
}
export function useChatGroupLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ChatGroupDocument, options);
}
export function useChatGroupSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ChatGroupDocument, options);
}
