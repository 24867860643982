import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitCallEndMutationVariables = Types.Exact<{
  visitId: Types.Scalars['ID']['input'];
}>;


export type VisitCallEndMutation = { __typename?: 'Mutation', visitCallEnd?: { __typename?: 'VisitCallEnd', success?: boolean | null, visitErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const VisitCallEndDocument = gql`
    mutation VisitCallEnd($visitId: ID!) {
  visitCallEnd(visitId: $visitId) {
    success
    visitErrors {
      message
      field
      code
    }
  }
}
    `;
export type VisitCallEndMutationFn = Apollo.MutationFunction<VisitCallEndMutation, VisitCallEndMutationVariables>;

/**
 * __useVisitCallEndMutation__
 *
 * To run a mutation, you first call `useVisitCallEndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitCallEndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitCallEndMutation, { data, loading, error }] = useVisitCallEndMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitCallEndMutation(baseOptions?: Apollo.MutationHookOptions<VisitCallEndMutation, VisitCallEndMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitCallEndMutation, VisitCallEndMutationVariables>(VisitCallEndDocument, options);
      }
export type VisitCallEndMutationHookResult = ReturnType<typeof useVisitCallEndMutation>;
export type VisitCallEndMutationResult = Apollo.MutationResult<VisitCallEndMutation>;
export type VisitCallEndMutationOptions = Apollo.BaseMutationOptions<VisitCallEndMutation, VisitCallEndMutationVariables>;