import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WalletQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PaymentOperationLogFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.PaymentOperationLogSortingInput>;
}>;


export type WalletQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, patient?: { __typename?: 'Patient', wallet?: { __typename?: 'Wallet', balance?: number | null, id?: string | null } | null, paymentOperationLogs: { __typename?: 'PaymentOperationLogCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'PaymentOperationLogCountableEdge', node: { __typename?: 'PaymentOperationLog', id: string, appointmentId?: string | null, walletId?: string | null, patientId?: string | null, paymentAmount?: number | null, paymentAmountCanBeRefundedFromWallet?: number | null, healthProgramId?: string | null, refundId?: string | null, userId?: string | null, operationType?: Types.OperationType | null, orderCheckoutToken?: string | null, orderId?: string | null, visitId?: string | null, operationStatus?: Types.OperationStatus | null, createdDate?: any | null, visit?: { __typename?: 'Visit', id: string, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } | null, appointment?: { __typename?: 'Appointment', id: string, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } | null, order?: { __typename?: 'Order', id: string, number: string, branch?: { __typename?: 'Branch', id: string, name: string, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } } | null } | null } }> } } | null } | null };


export const WalletDocument = gql`
    query Wallet($filter: PaymentOperationLogFilterInput, $first: Int, $after: String, $sortBy: PaymentOperationLogSortingInput) {
  me {
    id
    patient {
      wallet {
        balance
        id
      }
      paymentOperationLogs(
        first: $first
        filter: $filter
        after: $after
        sortBy: $sortBy
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            appointmentId
            walletId
            patientId
            paymentAmount
            paymentAmountCanBeRefundedFromWallet
            healthProgramId
            refundId
            userId
            operationType
            orderCheckoutToken
            orderId
            visitId
            visit {
              id
              doctor {
                id
                user {
                  id
                  fullName
                }
              }
            }
            appointment {
              id
              doctor {
                id
                user {
                  id
                  fullName
                }
              }
            }
            order {
              id
              number
              branch {
                id
                name
                vendor {
                  id
                  name
                  nameAr
                }
              }
            }
            healthProgramId
            operationStatus
            createdDate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useWalletQuery__
 *
 * To run a query within a React component, call `useWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useWalletQuery(baseOptions?: Apollo.QueryHookOptions<WalletQuery, WalletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalletQuery, WalletQueryVariables>(WalletDocument, options);
      }
export function useWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletQuery, WalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalletQuery, WalletQueryVariables>(WalletDocument, options);
        }
export function useWalletSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WalletQuery, WalletQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WalletQuery, WalletQueryVariables>(WalletDocument, options);
        }
export type WalletQueryHookResult = ReturnType<typeof useWalletQuery>;
export type WalletLazyQueryHookResult = ReturnType<typeof useWalletLazyQuery>;
export type WalletSuspenseQueryHookResult = ReturnType<typeof useWalletSuspenseQuery>;
export type WalletQueryResult = Apollo.QueryResult<WalletQuery, WalletQueryVariables>;