import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, Typography } from "@toolkit/ui";
import { Box } from "@toolkit/ui";
import { useChatDraftView } from "./ChatDraftView.hooks";
import { ChatDraftViewMedia } from "./ChatDraftViewMedia";
export const useStyles = makeStyles()(theme => ({
    root: {
        maxHeight: 200,
        backgroundColor: "white",
        overflow: "auto",
        padding: "0 4px",
        display: "flex",
        borderLeft: `0.125rem solid ${theme.palette.stale[300]}`,
    },
    media: {
        maxWidth: 80,
        maxHeight: 120,
        marginRight: 8,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        gap: 4,
    },
}));
export const ChatDraftView = ({ draft }) => {
    const { classes, theme } = useStyles();
    const { senderName, message, attachments } = useChatDraftView(draft);
    return (_jsxs(Box, { className: classes.root, children: [attachments.length > 0 && (_jsx(Box, { className: classes.media, children: _jsx(ChatDraftViewMedia, { attachments: attachments }) })), _jsxs(Box, { className: classes.content, children: [senderName && _jsx(Typography, { color: theme.palette.secondary.main, children: senderName }), _jsx(Typography, { children: message })] })] }));
};
