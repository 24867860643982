import { isRTL } from "@toolkit/i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { WaveSurferParams } from "wavesurfer.js/types/params";
import { useTheme } from "../../base/mui";

export type WaveSurferAudioProps = Partial<WaveSurferParams> & {
  url?: string;
  isCursorHidden?: boolean;
  isAutoplayEnabled?: boolean;
};

export const useWaveSurferAudio = ({
  url,
  waveColor,
  progressColor,
  cursorColor,
  barWidth = 3,
  barHeight = 3,
  barRadius = 3,
  height = 50,
  isCursorHidden = false,
  isAutoplayEnabled = false,
  ...rest
}: WaveSurferAudioProps) => {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const rtl = isRTL();
  const theme = useTheme();

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handlePause = useCallback(() => {
    setPlaying(false);
    wavesurfer.current?.pause();
  }, []);

  const handlePlayPause = useCallback(() => {
    setPlaying(p => !p);
    wavesurfer.current?.playPause();
  }, []);
  wavesurfer.current?.getCurrentTime();
  useEffect(() => {
    if (!waveformRef.current || !url) return;

    wavesurfer.current = WaveSurfer.create({
      ...rest,
      container: waveformRef.current,
      waveColor: waveColor || theme.palette.gray.dark,
      progressColor: progressColor || theme.palette.primary.main,
      cursorColor: isCursorHidden ? "transparent" : cursorColor || theme.palette.warning.main,
      barWidth,
      barHeight,
      barRadius,
      height,
      hideScrollbar: true,
      responsive: true,
      rtl,
    });

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", () => {
      if (!wavesurfer.current || !waveformRef.current) return;

      setDuration(wavesurfer.current.getDuration() || 0);

      setLoading(false);
      wavesurfer.current.zoom(1);

      if (isAutoplayEnabled) {
        setPlaying(isAutoplayEnabled);
        wavesurfer.current?.play();
      }
    });

    wavesurfer.current.on("finish", () => {
      setPlaying(false);
    });

    wavesurfer.current.on("audioprocess", (time: number) => {
      setCurrentTime(Math.floor(time));
    });

    return () => wavesurfer.current?.destroy();
  }, [url, isAutoplayEnabled]);

  return { waveformRef, duration, currentTime, loading, playing, handlePause, handlePlayPause, formatTime };
};
