import { createContext, useContext, useEffect } from "react";
export const CallContext = createContext({
    select: () => Error("Not implemented!"),
});
export function useActiveCall() {
    const { call, callInfo } = useContext(CallContext);
    const isInCall = (call === null || call === void 0 ? void 0 : call.state.callState) === "in_call";
    if (isInCall) {
        return { call, callInfo };
    }
    return null;
}
export function useSelectCall(meeting) {
    const { callInfo, select, call } = useContext(CallContext);
    useEffect(() => {
        if (meeting) {
            select(meeting);
        }
    }, [meeting, select]);
    if ((meeting === null || meeting === void 0 ? void 0 : meeting.id) !== (callInfo === null || callInfo === void 0 ? void 0 : callInfo.id)) {
        return undefined;
    }
    return call;
}
