var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
export const useCallHook = ({ call, invitationLink, isDoctorConsolEnabled, }) => {
    const { t } = useTranslation();
    const { succeeded, failed } = useAddToast();
    const [isJoining, setJoining] = useState(false);
    const onToggleVideo = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const result = yield ((_a = call.muteVideo) === null || _a === void 0 ? void 0 : _a.call(call, m => !m));
        if (!result || result.success) {
            return;
        }
        switch (result.type) {
            case "CAN_NOT_DETECT_CAMERA": {
                failed(t("We cannot detect your camera. Please check the connection and try again."));
                break;
            }
            case "MEDIA_NOT_FOUND":
            case "CAN_NOT_FIND_CAMERA": {
                failed(t("The camera cannot be found. Please check your connections and try again."));
                break;
            }
            case "MEDIA_NOT_ALLOWED":
            case "VIDEO_USER_FORBIDDEN_CAPTURE": {
                failed(t("Access to your camera has been denied. Please update the permissions in your browser settings and try again."));
                break;
            }
            case "MEDIA_NOT_READABLE":
            case "VIDEO_CAMERA_IS_TAKEN": {
                failed(t("The camera is currently in use. Please close any applications that may be using it and try again."));
                break;
            }
            default:
                failed(t("Starting camera failed. Please try again."));
                break;
        }
    }), [call, failed, t]);
    const hasInvitationLink = isDoctorConsolEnabled && invitationLink && call.callInvitationToken;
    const handleShareInvitation = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!invitationLink || !call.callInvitationToken) {
            return;
        }
        try {
            yield navigator.clipboard.writeText(`${invitationLink}/${call.callInvitationToken}`);
            succeeded(t("Invitation link was copied to clipboard."));
        }
        catch (_a) {
            failed(t("Failed to copy link, Please try again."));
        }
    }), [invitationLink, call.callInvitationToken, t, succeeded, failed]);
    const onToggleAudio = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const result = yield ((_a = call.muteAudio) === null || _a === void 0 ? void 0 : _a.call(call, m => !m));
        if (!result || result.success) {
            return;
        }
        switch (result.type) {
            case "MEDIA_NOT_FOUND": {
                failed(t("The microphone cannot be found. Please check your connections and try again."));
                break;
            }
            case "MEDIA_NOT_ALLOWED": {
                failed(t("Access to your microphone has been denied. Please update the permissions in your browser settings and try again."));
                break;
            }
            case "MEDIA_NOT_READABLE": {
                failed(t("The microphone is currently in use. Please close any applications that may be using it and try again."));
                break;
            }
            default:
                failed(t("Starting microphone failed. Please try again."));
                break;
        }
    }), [call, failed, t]);
    const handleJoin = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            setJoining(true);
            const result = yield call.joinCall();
            if (!(result === null || result === void 0 ? void 0 : result.success)) {
                if (result && result.type === "JOIN_MEETING_FAILED" && ((_a = result.reason) === null || _a === void 0 ? void 0 : _a.includes("The token has expired"))) {
                    failed(t("Failed to join this call, access is expired or no longer active."));
                }
                else {
                    failed(t("Failed to join this call, Please try again."));
                }
            }
        }
        finally {
            setJoining(false);
        }
    }), [call, failed, t]);
    const onToggleShare = useCallback(() => { var _a; return (_a = call.muteScreen) === null || _a === void 0 ? void 0 : _a.call(call, m => !m); }, [call]);
    const onToggleSpeaker = useCallback(() => { var _a; return (_a = call.muteSpeaker) === null || _a === void 0 ? void 0 : _a.call(call, m => !m); }, [call]);
    const onDropCall = useCallback(() => { var _a; return (_a = call.dropCall) === null || _a === void 0 ? void 0 : _a.call(call); }, [call]);
    const isTakePhotoAllowed = isDoctorConsolEnabled && call.participants.filter(p => !p.isCurrentUser && !p.state.videoMuted).length > 0;
    return {
        isJoining,
        isTakePhotoAllowed,
        isInvitationEnabled: !!isDoctorConsolEnabled && !!call.callInvitationToken,
        hasInvitationLink,
        onToggleVideo,
        onToggleAudio,
        onToggleShare,
        onToggleSpeaker,
        onDropCall,
        handleShareInvitation,
        handleJoin,
    };
};
