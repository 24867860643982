import { Appointment, NotificationType } from "@/schema/types";
import { useOnAppointmentStatusUpdate } from "@health/sse";
import { formatUserNameFull } from "@toolkit/core";
import { useTheme } from "@toolkit/ui";
import moment from "moment-timezone";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { useAppointmentQuery } from "../../gql";
import { StatusEnums } from "../../types";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { NotificationDefaultCard } from "../NotificationDefaultCard/NotificationDefaultCard";
import { AppointmentNotificationViewItem } from "./AppointmentNotificationViewItem";
import { getAppointmentDateTime, getNotificationAppointmentStatus, getNotificationAppointmentType } from "./utils";

type AppointmentNotificationProps = {
  id: string;
  status: StatusEnums;
  creationDate: string;
  isSeen: boolean;
};

export const AppointmentNotification: FC<AppointmentNotificationProps> = props => {
  const { id, status, creationDate, isSeen } = props;
  const theme = useTheme();
  const auth = useAuth();
  const {
    data: appointmentInfo,
    loading,
    error,
    refetch,
  } = useAppointmentQuery({
    variables: {
      appointmentId: id,
    },
    skip: !id || !auth?.user?.access_token,
  });
  const notificationAppointmentStatus = getNotificationAppointmentStatus(theme);
  const appointmentType = getNotificationAppointmentType();

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id === id) {
      refetch();
    }
  });

  const data = appointmentInfo?.appointment as Appointment;

  const appointmentDate = getAppointmentDateTime(data);
  const timeframeStart = getAppointmentDateTime(data, "hh:mm A");

  const formNowTzItem = moment(data?.confirmedStartTime).fromNow();
  const doctor = formatUserNameFull(data?.doctor?.user);

  const appointmentStatus = data?.status;

  if (!data?.id || error) {
    return (
      <NotificationDefaultCard
        isLoading={loading}
        type={NotificationType.HealthProgramAppointmentStatusChanged}
        creationDate={creationDate}
        isSeen={isSeen}
      />
    );
  }
  const Icon = notificationAppointmentStatus[status!]?.icon;

  return (
    <NotificationCard
      title={notificationAppointmentStatus[status!]?.label}
      date={creationDate}
      icon={<Icon />}
      iconColor={notificationAppointmentStatus[status!]?.color}
      isLoading={loading}
      isSeen={isSeen}
    >
      <AppointmentNotificationViewItem
        id={data?.id}
        status={status}
        appointmentStatus={appointmentStatus!}
        doctor={doctor}
        appointmentType={data?.type ? appointmentType[data?.type]?.label : ""}
        formNowTzItem={formNowTzItem}
        timeframeStart={timeframeStart!}
        confirmedStartTime={data?.confirmedStartTime}
        confirmedEndTime={data?.confirmedEndTime}
        requestedTimeSlots={data?.requestedTimeSlots as string[]}
        suggestedTimeSlots={data?.suggestedTimeSlots as string[]}
        consumer={data?.consumer || {}}
        appointmentDate={appointmentDate!}
        appointmentSlotTimePeriod={data?.doctor?.appointmentSlotTimePeriod}
        cancellationReason={data?.cancellationReason}
        isSeen={isSeen}
      />
    </NotificationCard>
  );
};
