import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { Box } from "@toolkit/ui";
import { ChatAvatar } from "../ChatAvatar";
import { ChatView } from "../ChatView";
import { ChatListItemMenu } from "./ChatListItemMenu";
import { useChatListItemStyles } from "./ChatListItem.styles";
export const ChatListItem = ({ hasAvatar, isOutgoing, chat }) => {
    const contentRef = useRef(null);
    const { classes } = useChatListItemStyles({ isOutgoing });
    return (_jsxs(Box, { ref: contentRef, className: classes.root, children: [_jsx(Box, { className: classes.avatar, children: hasAvatar && _jsx(ChatAvatar, { size: 32, fontSize: 12, value: chat.senderUser }) }), _jsx(Box, { className: classes.content, children: _jsx(ChatView, { chat: chat, isOutgoing: isOutgoing }) }), _jsx(ChatListItemMenu, { anchorRef: contentRef, chat: chat })] }));
};
