import { useCallback, useState } from "react";
export const useChatViewMediaPhoto = (media) => {
    const { url } = media;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const onLoad = useCallback(() => setLoading(false), []);
    const onError = useCallback(() => {
        setError(true);
        setLoading(false);
    }, []);
    return {
        loading,
        error,
        onLoad,
        onError,
        src: url,
        width: undefined,
        height: undefined,
    };
};
