import { DoctorsListFiltersForm } from "@/domains/doctor/components/DoctorsListFilters/DoctorsListFiltersForm";
import { IDoctorsListFiltersFormValues } from "@/domains/doctor/components/DoctorsListFilters/DoctorsListFiltersFormSchema";
import { convertDoctorsFilterFormValuesToBackEndValues } from "@/domains/doctor/components/DoctorsListFilters/utils";
import { AppointmentTypeEnum, DoctorAvailabilityStatusEnum, DoctorFilterInput } from "@/schema/types";
import { useCommonInfiniteContainerStyles } from "@/styles";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC, useState } from "react";
import { VirtualVisitAvailableDoctorsList } from "../../components/VirtualVisitAvailableDoctorsList/VirtualVisitAvailableDoctorsContainer";

export const VirtualVisitAvailableDoctorsContainer: FC = () => {
  const { classes: commonContainerClasses } = useCommonInfiniteContainerStyles();
  const { t } = useTranslation("consumer");
  const staticFilters: DoctorFilterInput = {
    canAcceptCall: true,
    availabilityStatus: [DoctorAvailabilityStatusEnum.AvailableLevel_1, DoctorAvailabilityStatusEnum.AvailableLevel_2],
    appointmentTypes: [AppointmentTypeEnum.Online],
  };

  const [filter, setFilter] = useState<DoctorFilterInput>(staticFilters);
  const onFilterValuesChange = (data: IDoctorsListFiltersFormValues) => {
    setFilter({ ...convertDoctorsFilterFormValuesToBackEndValues(data), ...staticFilters });
  };
  return (
    <Box className={commonContainerClasses.container}>
      <Typography className={commonContainerClasses.title}>{t("Call a Doctor Now")}</Typography>
      <Box className={commonContainerClasses.filterContainer}>
        <DoctorsListFiltersForm
          excludeFilters={{
            appointmentTypes: true,
          }}
          onSubmit={onFilterValuesChange}
        />
      </Box>
      <VirtualVisitAvailableDoctorsList filters={filter} />
    </Box>
  );
};
