import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientVisitCancelMutationVariables = Types.Exact<{
  patientVisitCancelId: Types.Scalars['ID']['input'];
  input: Types.CancelInput;
}>;


export type PatientVisitCancelMutation = { __typename?: 'Mutation', patientVisitCancel?: { __typename?: 'VisitApprovalAction', visit?: { __typename?: 'Visit', id: string, status?: Types.VisitStatus | null } | null, visitErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const PatientVisitCancelDocument = gql`
    mutation PatientVisitCancel($patientVisitCancelId: ID!, $input: CancelInput!) {
  patientVisitCancel(id: $patientVisitCancelId, input: $input) {
    visit {
      id
      status
    }
    visitErrors {
      field
      message
      code
    }
  }
}
    `;
export type PatientVisitCancelMutationFn = Apollo.MutationFunction<PatientVisitCancelMutation, PatientVisitCancelMutationVariables>;

/**
 * __usePatientVisitCancelMutation__
 *
 * To run a mutation, you first call `usePatientVisitCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientVisitCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientVisitCancelMutation, { data, loading, error }] = usePatientVisitCancelMutation({
 *   variables: {
 *      patientVisitCancelId: // value for 'patientVisitCancelId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientVisitCancelMutation(baseOptions?: Apollo.MutationHookOptions<PatientVisitCancelMutation, PatientVisitCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientVisitCancelMutation, PatientVisitCancelMutationVariables>(PatientVisitCancelDocument, options);
      }
export type PatientVisitCancelMutationHookResult = ReturnType<typeof usePatientVisitCancelMutation>;
export type PatientVisitCancelMutationResult = Apollo.MutationResult<PatientVisitCancelMutation>;
export type PatientVisitCancelMutationOptions = Apollo.BaseMutationOptions<PatientVisitCancelMutation, PatientVisitCancelMutationVariables>;