import { makeStyles } from "@toolkit/ui";
export const useCallMenuHookStyles = makeStyles()((theme, { isDrawerOpen, isDoctorConsoleOpen }) => ({
    root: {
        display: "flex",
        paddingTop: 8,
        paddingBottom: 4,
        width: "100%",
        justifyContent: "center",
        [theme.breakpoints.down(isDrawerOpen ? "xl" : "lg")]: {
            justifyContent: "flex-end",
        },
        [theme.breakpoints.down(isDrawerOpen && isDoctorConsoleOpen ? 1000 : 700)]: {
            justifyContent: "center",
        },
    },
    sideMenu: {
        left: 8,
        position: "absolute",
    },
    menuItem: {
        color: theme.palette.common.white,
        flexDirection: "column",
        fontSize: 11,
        gap: 1,
        height: "unset",
        padding: "unset",
        borderRadius: 0,
        svg: {
            width: 32,
            height: 32,
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: 10,
            margin: "unset",
            svg: {
                width: 30,
                height: 30,
            },
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 8,
            margin: "unset",
            svg: {
                width: 26,
                height: 26,
            },
        },
    },
    mobileMenu: {
        display: "flex",
        flexDirection: "column",
        "& .MuiButton-root": {
            color: theme.palette.common.black,
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            fontSize: theme.mixins.fonts.fontSize.sm,
            padding: "0.5rem",
            svg: {
                width: 24,
                height: 24,
            },
            "&:last-of-type": {
                justifyContent: "center",
            },
        },
    },
}));
