import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useMemo, useRef, useState } from "react";
export const ChatContext = createContext({
    chats: [],
    updateChats: () => { },
    updateDraft: () => { },
});
export const ChatProvider = ({ children, accessToken, senderId }) => {
    const audioRef = useRef();
    const [chats, setChats] = useState([]);
    const [draft, setDraft] = useState();
    const updateDraft = useCallback((value) => setDraft(value), []);
    const updateChats = useCallback((value) => setChats(value), []);
    const value = useMemo(() => ({ audioRef, senderId, accessToken, draft, chats, updateDraft, updateChats }), [senderId, accessToken, chats, draft, updateChats, updateDraft]);
    return _jsx(ChatContext.Provider, { value: value, children: children });
};
