var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeVar } from "@apollo/client";
import { useCallback, useState } from "react";
import { useVisitCallEndMutation, useVisitCallRejoinMutation, useVisitNewCallStartMutation, } from "../../gql/mutations";
import { VisitStatus } from "../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
export const isRejoinRequested = makeVar(false);
export const useVisitRejoinCall = (call, visit) => {
    const { id: visitId, status: visitStatus } = visit !== null && visit !== void 0 ? visit : {};
    const { failed } = useAddToast();
    const { t } = useTranslation();
    const [rejoinCallMutation] = useVisitCallRejoinMutation();
    const [visitNewCallStart] = useVisitNewCallStartMutation();
    const [visitCallEndMutation, { loading: visitCallEndLoading }] = useVisitCallEndMutation();
    const [loading, setLoading] = useState(false);
    const { resetCallState } = call;
    const isCallRejoinable = (visit === null || visit === void 0 ? void 0 : visit.status) && [VisitStatus.CallInProgress, VisitStatus.CallTimedOut, VisitStatus.CallRejoinable].includes(visit.status);
    const rejoinCall = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!visitId || loading) {
            return;
        }
        try {
            setLoading(true);
            const onError = ({ graphQLErrors }) => failed(formatGraphQLError(graphQLErrors));
            const onCompleted = (request) => {
                const visitErrors = request === null || request === void 0 ? void 0 : request.visitErrors;
                if (visitErrors === null || visitErrors === void 0 ? void 0 : visitErrors.length) {
                    failed(t("Failed to rejoin the call. Please retry."));
                }
            };
            switch (visitStatus) {
                case VisitStatus.CallTimedOut: {
                    yield rejoinCallMutation({
                        variables: { visitId },
                        onCompleted: request => onCompleted(request.visitCallRejoin),
                        onError,
                    });
                    break;
                }
                case VisitStatus.CallRejoinable: {
                    yield visitNewCallStart({
                        variables: { visitId },
                        onCompleted: request => onCompleted(request.visitNewCallStart),
                        onError,
                    });
                    break;
                }
                case VisitStatus.CallInProgress: {
                    resetCallState === null || resetCallState === void 0 ? void 0 : resetCallState();
                }
            }
        }
        finally {
            setLoading(false);
        }
    }), [failed, isCallRejoinable, loading, rejoinCallMutation, t, visitId, visitNewCallStart, visitStatus, resetCallState]);
    const handleVisitCallEnd = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (visitId && visitStatus === VisitStatus.CallInProgress) {
            try {
                yield visitCallEndMutation({ variables: { visitId } });
            }
            catch (_a) {
                // ignore
            }
        }
    }), [visitCallEndMutation, visitId, visitStatus]);
    return {
        handleRejoinCall: rejoinCall,
        handleVisitCallEnd,
        isCallRejoinable,
        visitCallEndLoading,
        loading,
    };
};
