import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceHealthPackagesForYouQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  vendorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type MarketplaceHealthPackagesForYouQuery = { __typename?: 'Query', marketplaceHealthPackagesForYou?: { __typename?: 'MarketplaceHealthPackageConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceHealthPackageEdge', node: { __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null, price?: any | null, description?: string | null, descriptionAr?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null, mainImageUrl?: string | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any, percentage?: number | null, startDate: any, type: Types.MarketplaceDiscountType } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } } }> } | null };


export const MarketplaceHealthPackagesForYouDocument = gql`
    query MarketplaceHealthPackagesForYou($after: String, $before: String, $first: Int, $last: Int, $vendorId: ID) {
  marketplaceHealthPackagesForYou(
    after: $after
    before: $before
    first: $first
    last: $last
    vendorId: $vendorId
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        nameAr
        price
        description
        descriptionAr
        activeDiscount {
          amount
          endDate
          percentage
          startDate
          type
        }
        shortDescription
        shortDescriptionAr
        vendor {
          id
          name
          nameAr
        }
        mainImageUrl
      }
    }
  }
}
    `;

/**
 * __useMarketplaceHealthPackagesForYouQuery__
 *
 * To run a query within a React component, call `useMarketplaceHealthPackagesForYouQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceHealthPackagesForYouQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceHealthPackagesForYouQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useMarketplaceHealthPackagesForYouQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceHealthPackagesForYouQuery, MarketplaceHealthPackagesForYouQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceHealthPackagesForYouQuery, MarketplaceHealthPackagesForYouQueryVariables>(MarketplaceHealthPackagesForYouDocument, options);
      }
export function useMarketplaceHealthPackagesForYouLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceHealthPackagesForYouQuery, MarketplaceHealthPackagesForYouQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceHealthPackagesForYouQuery, MarketplaceHealthPackagesForYouQueryVariables>(MarketplaceHealthPackagesForYouDocument, options);
        }
export function useMarketplaceHealthPackagesForYouSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceHealthPackagesForYouQuery, MarketplaceHealthPackagesForYouQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceHealthPackagesForYouQuery, MarketplaceHealthPackagesForYouQueryVariables>(MarketplaceHealthPackagesForYouDocument, options);
        }
export type MarketplaceHealthPackagesForYouQueryHookResult = ReturnType<typeof useMarketplaceHealthPackagesForYouQuery>;
export type MarketplaceHealthPackagesForYouLazyQueryHookResult = ReturnType<typeof useMarketplaceHealthPackagesForYouLazyQuery>;
export type MarketplaceHealthPackagesForYouSuspenseQueryHookResult = ReturnType<typeof useMarketplaceHealthPackagesForYouSuspenseQuery>;
export type MarketplaceHealthPackagesForYouQueryResult = Apollo.QueryResult<MarketplaceHealthPackagesForYouQuery, MarketplaceHealthPackagesForYouQueryVariables>;