import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitCallRejoinMutationVariables = Types.Exact<{
  visitId: Types.Scalars['ID']['input'];
}>;


export type VisitCallRejoinMutation = { __typename?: 'Mutation', visitCallRejoin?: { __typename?: 'VisitAction', visit?: { __typename?: 'Visit', id: string, status?: Types.VisitStatus | null, callId?: any | null, chatId?: any | null, number?: string | null, type?: Types.VisitType | null, cancellationReason?: string | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } | null } | null, visitErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const VisitCallRejoinDocument = gql`
    mutation VisitCallRejoin($visitId: ID!) {
  visitCallRejoin(visitId: $visitId) {
    visit {
      id
      status
      callId
      chatId
      number
      type
      cancellationReason
      doctor {
        id
        user {
          firstName
          lastName
        }
      }
    }
    visitErrors {
      code
      field
      message
    }
  }
}
    `;
export type VisitCallRejoinMutationFn = Apollo.MutationFunction<VisitCallRejoinMutation, VisitCallRejoinMutationVariables>;

/**
 * __useVisitCallRejoinMutation__
 *
 * To run a mutation, you first call `useVisitCallRejoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitCallRejoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitCallRejoinMutation, { data, loading, error }] = useVisitCallRejoinMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitCallRejoinMutation(baseOptions?: Apollo.MutationHookOptions<VisitCallRejoinMutation, VisitCallRejoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitCallRejoinMutation, VisitCallRejoinMutationVariables>(VisitCallRejoinDocument, options);
      }
export type VisitCallRejoinMutationHookResult = ReturnType<typeof useVisitCallRejoinMutation>;
export type VisitCallRejoinMutationResult = Apollo.MutationResult<VisitCallRejoinMutation>;
export type VisitCallRejoinMutationOptions = Apollo.BaseMutationOptions<VisitCallRejoinMutation, VisitCallRejoinMutationVariables>;