import { useCallback, useMemo } from "react";
export const useCallInvitationToken = ({ token }) => {
    const getCallInvitationTokenInfo = useCallback((value) => {
        return {
            topic: "",
            callId: "",
            chatId: "",
            userId: undefined,
            userName: "",
            token: value.token,
        };
    }, []);
    const data = useMemo(() => ({
        topic: "",
        callId: "",
        chatId: "",
        userId: undefined,
        userName: "",
        token,
    }), []);
    return {
        error: undefined,
        loading: false,
        retry: () => getCallInvitationTokenInfo({ token }),
        data,
        getCallInvitationTokenInfo,
    };
};
