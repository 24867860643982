import { getMediaLink } from "@toolkit/core";
function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}
export function getAvatar(value) {
    let name = "#";
    let src = "";
    if (typeof value === "string") {
        name = value;
    }
    else {
        switch (value === null || value === void 0 ? void 0 : value.__typename) {
            case "ChatGroup":
                name = (value.name || "").trim() || "Chat Group";
                break;
            case "AnonymousChatGroup":
                name = (value.title || "").trim() || "Anonymous Chat Group";
                break;
            case "User":
                src = getMediaLink(value === null || value === void 0 ? void 0 : value.photo);
                name = (value.fullName || "").trim();
        }
    }
    return {
        src,
        color: stringToColor(name),
        fullName: name,
        shortName: `${name
            .split(" ")
            .map(v => v[0])
            .slice(0, 2)
            .join("")
            .toUpperCase()}`,
    };
}
