import { RejoinCallButton } from "@/domains/VirtualVisit/components/RejoinCallButton/RejoinCallButton";
import { openMeetingPlatformLink, ReJoinableVisitStatuses } from "@/domains/VirtualVisit/utils";
import { AppointmentStatus, AppointmentType, PaymentStatus, VisitStatus } from "@/schema/types";
import { ExtractNodeType } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, Divider, Typography } from "@toolkit/ui";
import { produce } from "immer";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AppointmentCancelationModal } from "../../components/AppointmentCancelationModal/AppointmentCancelationModal";
import { TimeSelectionContainerData } from "../../containers/TimeSelectionContainer/type";
import { AppointmentsHistoryQuery } from "../../gql";
import { routes } from "../../routes";
import { DoctorInfoType } from "../../types/types";
import { getDoctorInfo } from "../../utils";
import { getConfirmDoctorTimeContainerData } from "../../utils/confirm-doctor-time.utils";
import { useAppointmentHistoryCardActionsSectionStyles } from "./AppointmentHistoryCardActionsSectionStyle";

type AppointmentHistoryCardActionsSectionProps = {
  appointment: ExtractNodeType<AppointmentsHistoryQuery>;
};

export const AppointmentHistoryCardActionsSection: FC<AppointmentHistoryCardActionsSectionProps> = ({ appointment }) => {
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const { open: isOpen, handleOpen, handleClose } = useOpenState();
  const { classes, cx } = useAppointmentHistoryCardActionsSectionStyles();

  const { status: appointmentStatus, paymentStatus, id: appointmentId, doctor } = appointment;
  const canRejoin = ReJoinableVisitStatuses.includes(appointment?.visit?.status as VisitStatus);

  const handleReschedule = () => {
    navigate(routes.reschedule.getRouteWithParams({ appointmentId, doctorId: doctor?.id || "" }));
  };

  const handleConfirmClick = () => {
    const _appointment = produce(appointment, draft => {
      if (draft.doctor && appointment?.vendor) {
        draft.doctor.vendor = appointment.vendor;
      }
    });
    navigate(routes.confirmDoctorTime.getRouteWithParams({ appointmentId, doctorId: doctor?.id || "" }), {
      state: getConfirmDoctorTimeContainerData(_appointment),
    });
  };

  const handleJoin = (visitId?: string | null) => {
    if (visitId) openMeetingPlatformLink(visitId);
  };

  const handlePayNow = () => {
    navigate(routes.paymentSummary.getRouteWithParams({ appointmentId }));
  };

  console.log(getDoctorInfo(appointment?.doctor as DoctorInfoType), "doctorInfo");

  const handleRequestAgainPress = () => {
    navigate(routes.schedule.getRouteWithParams({ visitType: appointment?.type as AppointmentType, doctorId: doctor?.id || "" }), {
      state: { doctorInfo: getDoctorInfo(doctor as DoctorInfoType), visitType: appointment?.type } as TimeSelectionContainerData,
    });
  };
  const noteToPatient = appointment?.visit?.noteToPatient ?? "";
  return (
    <>
      {(appointmentStatus === AppointmentStatus.Approved || appointmentStatus === AppointmentStatus.Confirmed) && (
        <Box className={classes.section}>
          <Box display='flex' flex={3} alignItems='center' justifyContent='start'>
            {
              <Button className={classes.button} onClick={handlePayNow}>
                {t("Pay Now")}
              </Button>
            }
            <Button variant='outlined' className={cx(classes.button, classes.rescheduleButton)} onClick={handleReschedule}>
              {t("Reschedule")}
            </Button>
          </Box>
          <Box display='flex' flex={1} alignItems='center' justifyContent='end'>
            <AppointmentCancelationModal id={appointmentId} />
          </Box>
        </Box>
      )}

      {appointmentStatus === AppointmentStatus.ComingSoon &&
        paymentStatus !== PaymentStatus.PaymentSucceeded &&
        paymentStatus !== PaymentStatus.FullyCovered && (
          <Box className={classes.section}>
            <Button variant='outlined' className={classes.button} onClick={handlePayNow}>
              {t("Pay Now")}
            </Button>
          </Box>
        )}

      {appointmentStatus === AppointmentStatus.RescheduledByDoctor && (
        <Box className={classes.section}>
          <Button className={classes.button} onClick={handleConfirmClick}>
            {t("Confirm")}
          </Button>
          <Button variant='outlined' className={cx(classes.button, classes.rescheduleButton)} onClick={handleReschedule}>
            {t("Reschedule")}
          </Button>
        </Box>
      )}

      {appointmentStatus === AppointmentStatus.CallInProgress && (
        <Box className={classes.section}>
          <Button disabled={!appointment.visit?.id} className={classes.button} onClick={() => handleJoin(appointment.visit?.id)}>
            {t("Join Now")}
          </Button>
        </Box>
      )}
      {(appointmentStatus === AppointmentStatus.RescheduledByConsumer || appointmentStatus === AppointmentStatus.Request) && (
        <Box className={classes.section}>
          <Box display='flex' flex={3} alignItems='center' justifyContent='start'>
            <Button variant='outlined' className={cx(classes.button, classes.rescheduleButton)} onClick={handleReschedule}>
              {t("Reschedule")}
            </Button>
            <AppointmentCancelationModal id={appointmentId} />
          </Box>
        </Box>
      )}

      {appointmentStatus === AppointmentStatus.FinishedSuccessfully && (
        <Box className={classes.section}>
          {canRejoin && <RejoinCallButton isDisabled={canRejoin} className={classes.button} visitId={appointment.visit?.id || ""} />}
          <Button variant='outlined' className={classes.button} onClick={handleRequestAgainPress}>
            {t("Request Again")}
          </Button>
          {appointment?.type === AppointmentType.Online && <Button className={classes.button}>{t("Call Summary")}</Button>}
          {!!noteToPatient && (
            <Button className={classes.button} onClick={handleOpen}>
              {t("Doctor's Note")}
            </Button>
          )}
        </Box>
      )}
      <CustomDialog
        title={t("Doctor's Note")}
        type='base'
        maxWidth='xs'
        open={isOpen}
        DialogTitleProps={{
          onClose: handleClose,
          title: t("Doctor's Note"),
        }}
      >
        <Typography>{noteToPatient}</Typography>
        <Divider className={classes.divider} />
        <Button onClick={handleClose} className={classes.closeButton}>
          {t("Close")}
        </Button>
      </CustomDialog>
    </>
  );
};
