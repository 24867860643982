import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamEnrollMutationVariables = Types.Exact<{
  input: Types.GuidedCarePatientTeamEnrollmentInput;
}>;


export type GuidedCareTeamEnrollMutation = { __typename?: 'Mutation', enrollPatientToTeam?: { __typename?: 'PatientEnrollmentRequest', providerGuidedCareHealthProgramTeamErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTeamEnrollDocument = gql`
    mutation GuidedCareTeamEnroll($input: GuidedCarePatientTeamEnrollmentInput!) {
  enrollPatientToTeam(input: $input) {
    providerGuidedCareHealthProgramTeamErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTeamEnrollMutationFn = Apollo.MutationFunction<GuidedCareTeamEnrollMutation, GuidedCareTeamEnrollMutationVariables>;

/**
 * __useGuidedCareTeamEnrollMutation__
 *
 * To run a mutation, you first call `useGuidedCareTeamEnrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamEnrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTeamEnrollMutation, { data, loading, error }] = useGuidedCareTeamEnrollMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareTeamEnrollMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTeamEnrollMutation, GuidedCareTeamEnrollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTeamEnrollMutation, GuidedCareTeamEnrollMutationVariables>(GuidedCareTeamEnrollDocument, options);
      }
export type GuidedCareTeamEnrollMutationHookResult = ReturnType<typeof useGuidedCareTeamEnrollMutation>;
export type GuidedCareTeamEnrollMutationResult = Apollo.MutationResult<GuidedCareTeamEnrollMutation>;
export type GuidedCareTeamEnrollMutationOptions = Apollo.BaseMutationOptions<GuidedCareTeamEnrollMutation, GuidedCareTeamEnrollMutationVariables>;