import React, { createContext, FC, ReactNode, useContext, useState } from "react";

interface HeaderContextProps {
  icon: React.ReactNode;
  headerTitle: string;
  setIcon: (icon: React.ReactNode) => void;
  setHeaderTitle: (header: string) => void;
  resetHeader: () => void;
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error("useHeaderContext must be used within a HeaderProvider");
  }
  return context;
};

interface HeaderProviderProps {
  children: ReactNode;
}

const initialIcon: React.ReactNode = undefined;
const initialHeader = "";

export const HeaderProvider: FC<HeaderProviderProps> = ({ children }) => {
  const [icon, setIcon] = useState<React.ReactNode>(initialIcon);
  const [headerTitle, setHeaderTitle] = useState<string>(initialHeader);

  const resetHeader = () => {
    setIcon(initialIcon);
    setHeaderTitle(initialHeader);
  };

  return <HeaderContext.Provider value={{ icon, setIcon, headerTitle, setHeaderTitle, resetHeader }}>{children}</HeaderContext.Provider>;
};
