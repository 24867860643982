var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Box, CustomDialog, ModalCloseIcon } from "@toolkit/ui";
import { IconButton, Typography } from "@toolkit/ui";
import { useStyles } from "./ChatViewMediaPhotoZoom.styles";
import { ChatViewMediaPhoto } from "./ChatViewMediaPhoto";
import { CloudDownload as DownloadIcon } from "@mui/icons-material";
import { useFileDownloader } from "@toolkit/core";
export const ChatViewMediaPhotoZoom = ({ media, onClose }) => {
    const { classes, theme } = useStyles();
    const { download } = useFileDownloader();
    const handleDownload = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { url } = media;
        yield download(url);
    }), [download, media]);
    return (_jsxs(CustomDialog, { className: classes.root, type: "base", onClose: onClose, open: true, DialogTitleProps: { hidden: true }, children: [_jsxs(Box, { className: classes.title, children: [_jsx(IconButton, { sx: { color: theme.palette.common.white }, onClick: handleDownload, children: _jsx(DownloadIcon, { htmlColor: 'inherit', fontSize: 'medium' }) }), _jsx(Typography, { textAlign: 'center', color: theme.palette.common.white, flexGrow: 1, children: "1 of 1" }), _jsx(IconButton, { sx: { color: theme.palette.common.white }, onClick: onClose, children: _jsx(ModalCloseIcon, { sx: { color: theme.palette.common.white }, htmlColor: 'inherit', fontSize: 'small' }) })] }), _jsx(Box, { sx: { maxHeight: "60vh", maxWidth: "60vw" }, children: _jsx(ChatViewMediaPhoto, { media: media, isZoomDisabled: true }) })] }));
};
