import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { NotificationFragmentFragmentDoc } from '../../queries/__generated__/notificationItem';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkNotificationAsSeenMutationVariables = Types.Exact<{
  markNotificationAsSeenId: Types.Scalars['ID']['input'];
}>;


export type MarkNotificationAsSeenMutation = { __typename?: 'Mutation', markNotificationAsSeen?: { __typename?: 'UserNotification', id?: string | null, seen?: boolean | null, type?: Types.NotificationType | null, data?: string | null, updateDate?: any | null, creationDate?: any | null, medicalMessage?: { __typename?: 'MedicalMessage', headerImage?: string | null, messages?: Array<{ __typename?: 'MedicalMessageVariant', id: string, presentedTitleArabic?: string | null, presentedTitleEnglish?: string | null, bodyArabic?: string | null, bodyEnglish?: string | null, buttonTextArabic?: string | null, buttonTextEnglish?: string | null, contentURLArabic?: string | null, contentURLEnglish?: string | null, extraData?: string | null } | null> | null } | null, errors?: Array<{ __typename?: 'SubscriptionGraphqlError', code?: Types.SubscriptionErrorCodes | null, field?: string | null, message?: string | null } | null> | null } | null };


export const MarkNotificationAsSeenDocument = gql`
    mutation MarkNotificationAsSeen($markNotificationAsSeenId: ID!) {
  markNotificationAsSeen(id: $markNotificationAsSeenId) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;
export type MarkNotificationAsSeenMutationFn = Apollo.MutationFunction<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>;

/**
 * __useMarkNotificationAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsSeenMutation, { data, loading, error }] = useMarkNotificationAsSeenMutation({
 *   variables: {
 *      markNotificationAsSeenId: // value for 'markNotificationAsSeenId'
 *   },
 * });
 */
export function useMarkNotificationAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>(MarkNotificationAsSeenDocument, options);
      }
export type MarkNotificationAsSeenMutationHookResult = ReturnType<typeof useMarkNotificationAsSeenMutation>;
export type MarkNotificationAsSeenMutationResult = Apollo.MutationResult<MarkNotificationAsSeenMutation>;
export type MarkNotificationAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>;