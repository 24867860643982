import { RefObject, useCallback, useEffect, useRef, useState } from "react";

export const useContextMenu = (anchorRef: RefObject<HTMLElement>) => {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const contextMenuRef = useRef(contextMenu);
  contextMenuRef.current = contextMenu;

  const handleOpen = useCallback(
    (e: { clientX: number; clientY: number }) =>
      setContextMenu({
        mouseX: e.clientX + 2,
        mouseY: e.clientY - 6,
      }),
    [setContextMenu]
  );

  const handleClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  useEffect(() => {
    const rootElement = anchorRef.current;
    if (!rootElement) {
      return;
    }

    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    const onTouchStart = (e: TouchEvent) => {
      timeoutId = setTimeout(() => {
        e.stopPropagation();
        handleOpen(e.touches[0]);
      }, 500);
    };

    const onTouchEnd = (e: TouchEvent) => {
      if (contextMenuRef.current) {
        e.stopPropagation();
      }
      timeoutId && clearTimeout(timeoutId);
    };

    const onContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      handleOpen(e);
    };

    rootElement.addEventListener("touchstart", onTouchStart);
    rootElement.addEventListener("touchend", onTouchEnd);
    rootElement.addEventListener("touchmove", onTouchEnd);
    rootElement.addEventListener("contextmenu", onContextMenu);
    return () => {
      rootElement.removeEventListener("touchstart", onTouchStart);
      rootElement.removeEventListener("touchend", onTouchEnd);
      rootElement.removeEventListener("touchmove", onTouchEnd);
      rootElement.removeEventListener("contextmenu", onContextMenu);
    };
  }, [anchorRef, handleOpen]);

  return { contextMenu, handleClose, handleOpen };
};
