import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
export const useChatDraftView = (draft) => {
    const { t } = useTranslation();
    const { chat } = draft;
    const senderName = useMemo(() => (chat ? chat.senderUser.fullName : t("You")), [chat, t]);
    const attachments = useMemo(() => (draft.chat ? draft.chat.attachments : draft.attachments) || [], [draft]);
    const message = useMemo(() => {
        if (!attachments || attachments.length === 0) {
            return chat === null || chat === void 0 ? void 0 : chat.message;
        }
        if (attachments.length === 1) {
            const [{ type }] = attachments;
            if (/^image\//.test(type)) {
                return t("Photo");
            }
            else if (/^audio\//.test(type)) {
                return t("Voice message");
            }
            else if (/^video\//.test(type)) {
                return t("Video");
            }
            return t("Document");
        }
        return t("Documents");
    }, [attachments, chat === null || chat === void 0 ? void 0 : chat.message, t]);
    return { senderName, message, attachments };
};
