import { DoctorProfileForTabs } from "@/domains/doctor/type";
import { Box, formatTime, getDayString, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useDoctorProfileTabsStyle } from "../DoctorProfileTabsStyles";

type AvailabilityBodyProps = {
  doctorInfo: DoctorProfileForTabs;
};

export const AvailabilityBody: FC<AvailabilityBodyProps> = props => {
  const { doctorInfo } = props;

  const { classes } = useDoctorProfileTabsStyle();
  return (
    <>
      {doctorInfo?.workingHours?.map(workDay => (
        <Box key={workDay?.day} className={classes.dayContainer}>
          <Typography className={classes.dayTitle}>{getDayString(workDay?.day || "")}</Typography>
          <Box className={classes.shiftContainer}>
            {workDay?.openTimeRanges?.map(shift => (
              <Typography key={formatTime(shift?.openTime) + formatTime(shift?.closeTime)} className={classes.shift}>
                <Typography className={classes.shiftTime}>{formatTime(shift?.openTime)}</Typography>
                <Typography mr={1}>-</Typography>
                <Typography className={classes.shiftTime}>{formatTime(shift?.closeTime)}</Typography>
              </Typography>
            ))}
          </Box>
        </Box>
      ))}
    </>
  );
};
