import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Typography, Box } from "@toolkit/ui";
import { useChatAvatar } from "./ChatAvatar.hooks";
import { useChatAvatarStyles } from "./ChatAvatar.styles";
export const ChatAvatar = ({ value, size, fontSize }) => {
    const { classes } = useChatAvatarStyles();
    const avatar = useChatAvatar({ value });
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const onLoad = () => setLoaded(true);
    const onError = () => setError(true);
    useEffect(() => {
        setLoaded(false);
        setError(false);
    }, [avatar.src]);
    return (_jsxs(Box, { className: classes.root, sx: {
            height: size,
            width: size,
            borderRadius: size / 2,
            backgroundColor: avatar.color,
        }, children: [!loaded && (_jsx(Typography, { className: classes.text, sx: { fontSize: fontSize !== null && fontSize !== void 0 ? fontSize : size * 0.3 }, children: avatar.shortName })), !error && avatar.src && (
            // eslint-disable-next-line react/forbid-dom-props
            _jsx("img", { className: classes.image, style: { height: size, width: size }, alt: '', src: avatar.src, onLoad: onLoad, onError: onError }))] }));
};
