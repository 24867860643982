import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const HeartIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20.423' height='18.166' viewBox='0 0 20.423 18.166' {...props}>
      <path
        id='icons8-heart'
        d='M11.712,24.017l-.27-.224c-.513-.429-1.207-.894-2.012-1.432C6.293,20.261,2,17.388,2,12.489a5.488,5.488,0,0,1,9.712-3.5,5.488,5.488,0,0,1,9.712,3.5c0,4.9-4.293,7.772-7.429,9.872-.8.538-1.5,1-2.012,1.432Z'
        transform='translate(-1.5 -6.5)'
        fill={props?.fill || theme.palette.primary.main}
        stroke='#252880'
        strokeWidth='1'
      />
    </SvgIcon>
  );
};
