import { useCommonStyles } from "@/domains/appointment/containers/CommonContainer.styles";
import { NotificationType } from "@/schema/types";
import { Box } from "@toolkit/ui";
import { NotificationsTabs } from "../../components";
import { BaseNotificationsList } from "../../components/BaseNotificationsList/BaseNotificationsList";
import { useNotificationsListContainerStyle } from "../NotificationsListContainer/NotificationsListContainerStyle";

export const HealthEngagementMessagesListContainer: React.FC = () => {
  const { classes, cx } = useCommonStyles();
  const { classes: listClasses } = useNotificationsListContainerStyle();
  return (
    <Box className={classes.pageContainer}>
      <Box className={listClasses.header}>
        <NotificationsTabs />
      </Box>
      <Box className={cx(classes.pageContent)} display='contents'>
        <BaseNotificationsList
          isMedicalMessageNotifications
          isSeparatorHidden
          filter={{
            types: [NotificationType.MedicalMessage],
          }}
        />
      </Box>
    </Box>
  );
};
