import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CallPublisherView } from "./types";
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { MeetingSelfViewDraggable } from "./MeetingSelfViewDraggable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { Box } from "@toolkit/ui";
export const MeetingSelfView = ({ call }) => {
    const selfViewRef = useRef(null);
    const parentRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [selfViewDimensions, setDelfViewDimensions] = useState({ width: 160, height: 160 });
    const { x, y } = useMemo(() => {
        const { width, height } = dimensions;
        if (!width || !height) {
            return { x: 0, y: 0 };
        }
        return { y: height - (selfViewDimensions.height + 15), x: width - selfViewDimensions.width };
    }, [dimensions, selfViewDimensions]);
    const [coordinates, setCoordinates] = useState({ x, y });
    useEffect(() => {
        setCoordinates({ x, y });
    }, [x, y]);
    useEffect(() => {
        var _a, _b, _c;
        const parentElement = (_a = parentRef.current) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (!parentElement) {
            return;
        }
        setDimensions({
            width: (_b = parentElement.clientWidth) !== null && _b !== void 0 ? _b : 0,
            height: (_c = parentElement.clientHeight) !== null && _c !== void 0 ? _c : 0,
        });
        const observer = new ResizeObserver(() => {
            var _a, _b;
            setDimensions({
                width: (_a = parentElement.clientWidth) !== null && _a !== void 0 ? _a : 0,
                height: (_b = parentElement.clientHeight) !== null && _b !== void 0 ? _b : 0,
            });
        });
        observer.observe(parentElement);
        return () => {
            observer.unobserve(parentElement);
        };
    }, []);
    useEffect(() => {
        const selfViewElement = selfViewRef.current;
        if (!selfViewElement) {
            return;
        }
        const observer = new ResizeObserver(() => {
            var _a, _b;
            setDelfViewDimensions({
                width: (_a = selfViewElement.clientWidth) !== null && _a !== void 0 ? _a : 160,
                height: (_b = selfViewElement.clientHeight) !== null && _b !== void 0 ? _b : 160,
            });
        });
        observer.observe(selfViewElement);
        return () => {
            observer.unobserve(selfViewElement);
        };
    }, []);
    const sensors = useSensors(useSensor(MouseSensor, {
        activationConstraint: {
            distance: 10,
        },
    }), useSensor(TouchSensor, {
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
    }));
    const onDragStart = useCallback(() => {
        document.body.style.setProperty("cursor", "grabbing");
    }, []);
    const onDragEnd = useCallback(({ delta }) => {
        document.body.style.setProperty("cursor", "");
        setCoordinates(c => ({ x: c.x + delta.x, y: c.y + delta.y }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Box, { ref: parentRef }), _jsx(DndContext, { onDragStart: onDragStart, onDragEnd: onDragEnd, sensors: sensors, modifiers: [restrictToParentElement], children: _jsx(MeetingSelfViewDraggable, { id: 'draggable-meeting-selfview', top: coordinates.y, left: coordinates.x, children: _jsx(Box, { ref: selfViewRef, sx: { minWidth: 160, maxWidth: 200 }, children: _jsx(CallPublisherView, { call: call }) }) }) })] }));
};
