import { useCallback, useEffect, useState } from "react";
import { useZoom } from "./useZoom";
import { ConnectionState } from "@zoom/videosdk";
export var CallState;
(function (CallState) {
    CallState["NoCall"] = "no_call";
    CallState["Started"] = "call_started";
    CallState["Timeout"] = "call_timeout";
    CallState["Ended"] = "call_ended";
    CallState["Dropped"] = "call_dropped";
    CallState["InCall"] = "in_call";
})(CallState || (CallState = {}));
export function useCallState(meeting) {
    const callId = meeting === null || meeting === void 0 ? void 0 : meeting.id;
    const { resetConnectionState, connectionState, isConnecting, leaveSession } = useZoom();
    const [callState, setCallState] = useState(CallState.NoCall);
    const updateCallStatus = useCallback((value) => {
        setCallState(() => {
            if (value === CallState.NoCall && callId) {
                return CallState.Started;
            }
            return value;
        });
    }, [callId]);
    useEffect(() => {
        if (callId) {
            updateCallStatus(CallState.Started);
        }
    }, [callId, updateCallStatus]);
    useEffect(() => {
        if (callState !== CallState.InCall ||
            isConnecting ||
            !connectionState ||
            connectionState === ConnectionState.Connected ||
            connectionState === ConnectionState.Reconnecting) {
            return;
        }
        const timeoutId = setTimeout(() => updateCallStatus(connectionState === ConnectionState.Fail ? CallState.Timeout : CallState.Dropped), 1000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [callState, isConnecting, connectionState]);
    useEffect(() => {
        if (connectionState !== ConnectionState.Connected || callState === CallState.InCall) {
            return;
        }
        const timeoutId = setTimeout(() => leaveSession === null || leaveSession === void 0 ? void 0 : leaveSession(), 1000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [callState, connectionState, leaveSession]);
    const resetCallState = useCallback(() => {
        resetConnectionState === null || resetConnectionState === void 0 ? void 0 : resetConnectionState();
        updateCallStatus(CallState.NoCall);
    }, [resetConnectionState, updateCallStatus]);
    return { callState, updateCallStatus, resetCallState };
}
