import { useHeaderContext } from "@/providers";
import { Box } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { CareTeamRegistrationContainerDataType } from "../../type";
import { Location, useLocation } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";
import { truncate } from "lodash";
import { useCommonContainerStyles } from "@/styles";
import { MyCareTeamDoctorCard } from "../../components/MyCareTeamDoctorCard/MyCareTeamDoctorCard";
import { useStyle } from "./MyCareTeamContainerStyle";

export const MyCareTeamContainer: FC = () => {
  const { setHeaderTitle } = useHeaderContext();
  const location: Location<CareTeamRegistrationContainerDataType> = useLocation();
  const { team, teamLeader } = location.state || {};
  const { t } = useTranslation("consumer");
  const { classes: commonClasses, cx } = useCommonContainerStyles();
  const { classes } = useStyle();

  const leaderTeam = team?.teamMembers?.filter(member => teamLeader?.id !== member?.id);

  useEffect(() => {
    setHeaderTitle(truncate(team?.name || t("My Care Team"), { length: 30 }));
  }, [setHeaderTitle]);

  return (
    <Box className={commonClasses.pageContainer}>
      <Box className={cx(commonClasses.pageContent, classes.pageContent)}>
        <MyCareTeamDoctorCard member={teamLeader!} teamId={team?.id} teamName={team?.name} isTeamLeader />
        {leaderTeam?.map(member => (
          <MyCareTeamDoctorCard key={member?.id} member={member!} />
        ))}
      </Box>
    </Box>
  );
};
