import { useCallback, useState } from "react";

export const useFileDownloader = () => {
  const [loading, setLoading] = useState(false);

  const download = useCallback(async (url: string) => {
    try {
      setLoading(true);
      if (typeof document === "undefined") {
        return;
      }

      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
      a.remove();
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    download,
  };
};
